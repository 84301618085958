/* Merix Creative Digital Agency HTML-5 Template HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two / Style Three
5. Banner Section / Two / Three
6. Services Section / Two / Three
7. About Section
8. Services Section Two
9. Clients Section
10. CTA Section / Two
11. Counter Section
12. Testimonial Section
13. Team Section
14. Pricing Section
15. News Section
16. Main Footer Section
17. Gallery Section
18. Faq Section
19. Seo Section
20. Mission Section
21. Analysic Section
22. Counter Section
23. Steps Section
24. Skill Section
25. Demo Section
26. Newsletter Section
27. Page Title
28. Team Detail Section
29. Coming Soon Section
30. Privacy Section
31. Terms Section
32. Project Widgets
33. Project Detail Section
34. Our Shops
35. Shop Detail Section
36. Cart Section
37. Checkout Section
38. Account Section
39. Blog Widgets Section
40. Error Section
41. Contact Info Section
42. Map Section
43. Contact Form Section

====================================================================
	Reset
====================================================================

 ***/

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
}

:root {
	scroll-behavior: unset;
}

/*** 

====================================================================
	Global Settings
====================================================================

***/

body {
	font-family: 'Archivo', sans-serif;
	font-size: 14px;
	color: #777777;
	line-height: 1.7em;
	font-weight: 400;
	background: #ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper {
	padding: 0px 50px 0px;
}

a {
	text-decoration: none;
	cursor: pointer;
	color: #eb3867;
}

.App {
	overflow: hidden;
	position: relative;
}

button,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	font-weight: normal;
	margin: 0px;
	background: none;
	line-height: 1.6em;
	font-family: 'Poppins', sans-serif;
}

input,
button,
select,
textarea {}

textarea {
	overflow: hidden;
}

p {
	position: relative;
	line-height: 1.8em;
}

/* Typography */

h1 {
	font-size: 48px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 18px;
}

.auto-container {
	position: static;
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}

.medium-container {
	max-width: 850px;
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
}

ul,
li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

img {
	display: inline-block;
	max-width: 100%;
}

.theme-btn {
	cursor: pointer;
	display: inline-block;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.centered {
	text-align: center;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.back-to-top {
	position: fixed;
	right: 1.5%;
	bottom: -7.5%;
	height: 15px;
	cursor: pointer;
	width: 40px;
	padding: 3px 5px;
	font-size: 10px;
	font-weight: bold;
	color: transparent;
	text-align: center;
	z-index: 3;
	visibility: hidden;
	outline: 0 !important;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	background-color: #434343;
}

.back-to-top::before,
.back-to-top::after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top: 0;
}

.back-to-top::before {
	top: -20px;
	z-index: 4;
	border-bottom: 20px solid #434343;
}

.back-to-top::after {
	bottom: 0;
	z-index: 5;
	border-bottom: 20px solid #ff0700;
}

.back-to-top:hover,
.back-to-top:focus {
	opacity: 1;
	text-decoration: none;
	height: 40px;
	color: #ffffff;
}

.back-to-top.show-back-to-top {
	display: block;
	bottom: 1.25%;
	visibility: visible;
}

/* List Style One */

.list-style-one {
	position: relative;
}

.list-style-one li {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	padding-left: 30px;
	font-weight: 400;
	line-height: 1.6em;
	margin-bottom: 15px;
}

.list-style-one li .icon {
	position: absolute;
	left: 0px;
	top: 5px;
	color: #eb3867;
	font-size: 18px;
	line-height: 1em;
	font-weight: 300;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.list-style-one li a {
	position: relative;
	color: #ffffff;
}

/* List Style Two */

.list-style-two {
	position: relative;
}

.list-style-two li {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	padding-left: 30px;
	font-weight: 400;
	line-height: 1.6em;
	margin-bottom: 12px;
}

.list-style-two li .icon {
	position: absolute;
	left: 0px;
	top: 5px;
	color: #fc8b56;
	font-size: 18px;
	line-height: 1em;
	font-weight: 300;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.list-style-two li a {
	position: relative;
	color: #ffffff;
}

/* List Style Three */

.list-style-three {
	position: relative;
}

.list-style-three li {
	position: relative;
	color: #555555;
	font-size: 16px;
	padding-left: 30px;
	font-weight: 400;
	line-height: 1.6em;
	margin-bottom: 20px;
}

.list-style-three li:before {
	position: absolute;
	content: "\f13f";
	left: 0px;
	top: 5px;
	color: #fc8b56;
	font-size: 18px;
	line-height: 1em;
	font-weight: 300;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: "Flaticon";
}

.list-style-three li:last-child {
	margin-bottom: 0px;
}

/*Btn Style One*/

.btn-style-one {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #222222;
	padding: 10px 30px;
	font-weight: 600;
	overflow: hidden;
	border-radius: 5px;
	overflow: hidden;
	text-transform: capitalize;
	background-color: #ffffff;
	font-family: 'Archivo', sans-serif;
}

.btn-style-one:before {
	position: absolute;
	content: '';
	left: 110%;
	top: 0px;
	width: 120%;
	height: 100%;
	z-index: 1;
	opacity: 1;
	background-color: #ec3a67;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	transform: skewX(-25deg);
}

.btn-style-one .txt {
	position: relative;
	z-index: 1;
}

.btn-style-one:hover::before {
	left: 0%;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-one:hover {
	color: #ffffff;
}

/* Btn Style Two */

.btn-style-two {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 12px 40px;
	font-weight: 600;
	overflow: hidden;
	border-radius: 3px;
	overflow: hidden;
	text-transform: capitalize;
	background-color: #e82a6a;
	font-family: 'Archivo', sans-serif;
}

.btn-style-two:before {
	position: absolute;
	content: '';
	left: 92%;
	top: 0px;
	width: 110%;
	height: 100%;
	z-index: 1;
	opacity: 1;
	background-color: #791eba;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	transform: skewX(-25deg);
}

.btn-style-two .txt {
	position: relative;
	z-index: 1;
}

.btn-style-two:hover::before {
	left: 0%;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-two:hover {
	color: #ffffff;
}

/* Btn Style Three */

.btn-style-three {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 14px 44px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 6px;
	overflow: hidden;
	background: #EA3368;
	text-transform: capitalize;
	background: -webkit-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: -moz-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
}

.btn-style-three:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background: #EA3368;
	background: -webkit-linear-gradient(to left, #EA3368 0%, #FD8B56 100%);
	background: -moz-linear-gradient(to left, #EA3368 0%, #FD8B56 100%);
	background: linear-gradient(to left, #EA3368 0%, #FD8B56 100%);
}

.btn-style-three .txt {
	position: relative;
	z-index: 1;
}

.btn-style-three:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-three:hover {
	color: #ffffff;
}

/* Btn Style Four */

.btn-style-four {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 12px 32px 12px;
	font-weight: 400;
	overflow: hidden;
	background: none;
	border-radius: 5px;
	text-transform: capitalize;
	border: 2px solid #ffffff;
	font-family: 'Archivo', sans-serif;
}

.btn-style-four:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background-color: #ea3468;
}

.btn-style-four .txt {
	position: relative;
	z-index: 1;
}

.btn-style-four:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-four:hover {
	color: #ffffff;
	border-color: #ea3468;
}

/* Btn Style Five */

.btn-style-five {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 14px 44px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 6px;
	overflow: hidden;
	text-transform: capitalize;
	background: #6917B4;
	background: -webkit-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: -moz-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: linear-gradient(to right, #6917B4 0%, #d54be1 100%);
}

.btn-style-five:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background: #6917B4;
	background: -webkit-linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
	background: -moz-linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
	background: linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
}

.btn-style-five .txt {
	position: relative;
	z-index: 1;
}

.btn-style-five:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-five:hover {
	color: #ffffff;
}

/* Btn Style Six */

.btn-style-six {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #6615b3;
	padding: 14px 44px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 6px;
	overflow: hidden;
	text-transform: capitalize;
	background: #ffffff;
}

.btn-style-six:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background: #6917B4;
	background: -webkit-linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
	background: -moz-linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
	background: linear-gradient(to left, #6917B4 0%, #D54BE1 100%);
}

.btn-style-six .txt {
	position: relative;
	z-index: 1;
}

.btn-style-six:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-six:hover {
	color: #ffffff;
}

/* Btn Style Seven */

.btn-style-seven {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 15px 51px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 5px;
	overflow: hidden;
	text-transform: capitalize;
	font-family: 'Archivo', sans-serif;
	background: #0028A4;
	background: -webkit-linear-gradient(to right, #0028A4 0%, #003ffd 100%);
	background: -moz-linear-gradient(to right, #0028A4 0%, #003ffd 100%);
	background: linear-gradient(to right, #0028A4 0%, #003ffd 100%);
}

.btn-style-seven:before {
	position: absolute;
	content: '';
	left: 110%;
	top: 0px;
	width: 120%;
	height: 100%;
	z-index: 1;
	opacity: 1;
	background-color: #ec3a67;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	transform: skewX(-25deg);
}

.btn-style-seven .txt {
	position: relative;
	z-index: 1;
}

.btn-style-seven:hover::before {
	left: 0%;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-seven:hover {
	color: #ffffff;
}

/* Btn Style Eight */

.btn-style-eight {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 14px 44px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 6px;
	overflow: hidden;
	background: #ff0300;
	text-transform: capitalize;
	background: -webkit-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
}

.btn-style-eight:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background: #ff0300;
	background: -webkit-linear-gradient(to left, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to left, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to left, #ff0300 0%, #ff8f00 100%);
}

.btn-style-eight .txt {
	position: relative;
	z-index: 1;
}

.btn-style-eight:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-eight:hover {
	color: #ffffff;
}

/* Btn Style Nine */

.btn-style-nine {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 12px 32px 12px;
	font-weight: 400;
	overflow: hidden;
	background: none;
	border-radius: 5px;
	text-transform: capitalize;
	border: 2px solid #ffffff;
	font-family: 'Archivo', sans-serif;
}

.btn-style-nine:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background-color: #ff0100;
}

.btn-style-nine .txt {
	position: relative;
	z-index: 1;
}

.btn-style-nine:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-nine:hover {
	color: #ffffff;
	border-color: #ff0100;
}

/* Btn Style Ten */

.btn-style-ten {
	position: relative;
	display: inline-block;
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	padding: 15px 40px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 5px;
	overflow: hidden;
	text-transform: capitalize;
	background-color: #ff0500;
	font-family: 'Archivo', sans-serif;
}

.btn-style-ten:before {
	position: absolute;
	content: '';
	left: 110%;
	top: 0px;
	width: 120%;
	height: 100%;
	z-index: 1;
	opacity: 1;
	background-color: #ff8a00;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	transform: skewX(-25deg);
}

.btn-style-ten .txt {
	position: relative;
	z-index: 1;
}

.btn-style-ten:hover::before {
	left: 0%;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-ten:hover {
	color: #ffffff;
}

/* Btn Style Eleven */

.btn-style-eleven {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 30px;
	color: #ffffff;
	padding: 14px 44px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 6px;
	overflow: hidden;
	text-transform: capitalize;
	background: #1519b2;
	background: -webkit-linear-gradient(to right, #1519b2 0%, #4c67e2 100%);
	background: -moz-linear-gradient(to right, #1519b2 0%, #4c67e2 100%);
	background: linear-gradient(to right, #1519b2 0%, #4c67e2 100%);
}

.btn-style-eleven:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
	background: #1519b2;
	background: -webkit-linear-gradient(to left, #1519b2 0%, #4c67e2 100%);
	background: -moz-linear-gradient(to left, #1519b2 0%, #4c67e2 100%);
	background: linear-gradient(to left, #1519b2 0%, #4c67e2 100%);
}

.btn-style-eleven .txt {
	position: relative;
	z-index: 1;
}

.btn-style-eleven:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-eleven:hover {
	color: #ffffff;
}

/* Social Icon One */

.social-icon-one {
	position: relative;
	display: block;
}

.social-icon-one .title {
	position: relative;
	font-size: 20px;
	line-height: 26px;
	color: #ffffff;
	font-weight: 700;
	margin-right: 15px;
}

.social-icon-one li {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	margin-right: 22px;
}

.social-icon-one li:last-child {
	margin-right: 0;
}

.social-icon-one li a {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.social-icon-one li a:hover {
	color: #eb3867;
}

.theme_color {
	color: #eb3867;
}

/* Preloader */

.preloader {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 99999999999;
	text-align: center;
	overflow: hidden;
	background: #295df1;
	background: -webkit-linear-gradient(to right, #295df1 0%, #4487f2 100%);
	background: -moz-linear-gradient(to right, #295df1 0%, #4487f2 100%);
	background: linear-gradient(to right, #295df1 0%, #4487f2 100%);
}

.preloader .box {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: inline-block;
}

.preloader .box:after {
	content: '';
	width: 50px;
	height: 50px;
	background: #fff;
	-moz-animation: preloader 500ms linear infinite;
	-webkit-animation: preloader 500ms linear infinite;
	animation: preloader 500ms linear infinite;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 3px;
}

.preloader .box:before {
	content: '';
	width: 50px;
	height: 5px;
	background: #000;
	-moz-animation: preloader-shadow 500ms linear infinite;
	-webkit-animation: preloader-shadow 500ms linear infinite;
	animation: preloader-shadow 500ms linear infinite;
	opacity: 0.1;
	position: absolute;
	top: 59px;
	left: 0;
	border-radius: 50%;
}

@-moz-keyframes preloader {
	17% {
		border-bottom-right-radius: 3px;
	}

	25% {
		-moz-transform: translateY(9px) rotate(22.5deg);
		transform: translateY(9px) rotate(22.5deg);
	}

	50% {
		-moz-transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		border-bottom-right-radius: 40px;
	}

	75% {
		-moz-transform: translateY(9px) rotate(67.5deg);
		transform: translateY(9px) rotate(67.5deg);
	}

	100% {
		-moz-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
}

@-webkit-keyframes preloader {
	17% {
		border-bottom-right-radius: 3px;
	}

	25% {
		-webkit-transform: translateY(9px) rotate(22.5deg);
		transform: translateY(9px) rotate(22.5deg);
	}

	50% {
		-webkit-transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		border-bottom-right-radius: 40px;
	}

	75% {
		-webkit-transform: translateY(9px) rotate(67.5deg);
		transform: translateY(9px) rotate(67.5deg);
	}

	100% {
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
}

@keyframes preloader {
	17% {
		border-bottom-right-radius: 3px;
	}

	25% {
		-moz-transform: translateY(9px) rotate(22.5deg);
		-ms-transform: translateY(9px) rotate(22.5deg);
		-webkit-transform: translateY(9px) rotate(22.5deg);
		transform: translateY(9px) rotate(22.5deg);
	}

	50% {
		-moz-transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		-ms-transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		-webkit-transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		transform: scale(1, 0.9) translateY(18px) rotate(45deg);
		border-bottom-right-radius: 40px;
	}

	75% {
		-moz-transform: translateY(9px) rotate(67.5deg);
		-ms-transform: translateY(9px) rotate(67.5deg);
		-webkit-transform: translateY(9px) rotate(67.5deg);
		transform: translateY(9px) rotate(67.5deg);
	}

	100% {
		-moz-transform: translateY(0) rotate(90deg);
		-ms-transform: translateY(0) rotate(90deg);
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
}

@-moz-keyframes preloader-shadow {
	50% {
		-moz-transform: scale(1.2, 1);
		transform: scale(1.2, 1);
	}
}

@-webkit-keyframes preloader-shadow {
	50% {
		-webkit-transform: scale(1.2, 1);
		transform: scale(1.2, 1);
	}
}

@keyframes preloader-shadow {
	50% {
		-moz-transform: scale(1.2, 1);
		-ms-transform: scale(1.2, 1);
		-webkit-transform: scale(1.2, 1);
		transform: scale(1.2, 1);
	}
}

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
	position: relative;
	margin-bottom: 55px;
}

.sec-title .title {
	position: relative;
	color: #555555;
	font-size: 18px;
	font-weight: 600;
	padding-right: 60px;
	display: inline-block;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}

.sec-title .title:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 4px;
	width: 43px;
	height: 15px;
	background: url(../images/icons/title-icon.png) no-repeat;
}

.sec-title h2 {
	position: relative;
	color: #222222;
	font-weight: 600;
	line-height: 1.3em;
	margin-top: 10px;
}

.sec-title .text {
	position: relative;
	color: #555555;
	font-weight: 400;
	line-height: 1.7em;
	margin-top: 18px;
	font-size: 18px;
}

.sec-title.light .text,
.sec-title.light .title,
.sec-title.light h2 {
	color: #ffffff;
}

.sec-title.centered {
	text-align: center !important;
}

.sec-title.centered .title {
	padding-left: 60px;
}

.sec-title.centered .title:after {
	position: absolute;
	content: '';
	left: 0px;
	top: 4px;
	width: 43px;
	height: 15px;
	background: url(../images/icons/title-icon.png) no-repeat;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header {
	position: absolute;
	z-index: 99;
	width: 100%;
	padding-top: 60px;
}

/* Header Top */

.main-header .header-top {
	position: relative;
}

.main-header .header-top .inner-container {
	position: relative;
	padding: 14px 0px;
	padding-right: 30px;
	margin-right: 60px;
	background-color: #ffffff;
}

.main-header .header-top .top-left {
	position: relative;
	float: left;
}

.main-header .header-top .info-list {
	position: relative;
}

.main-header .header-top .info-list li {
	position: relative;
	color: #555555;
	font-size: 16px;
	margin-right: 30px;
	padding-left: 25px;
	display: inline-block;
}

.main-header .header-top .info-list li:last-child {
	margin-right: 0px;
}

.main-header .header-top .info-list li .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	color: #fe4c1c;
}

.main-header .header-top .info-list li a {
	position: relative;
	color: #555555;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .header-top .info-list li a:hover {
	color: #eb3867;
}

.main-header .header-top .top-left .text {
	position: relative;
	color: #222222;
	font-size: 14px;
	font-weight: 600;
}

.main-header .main-box {
	position: relative;
	padding: 0px 0px;
	left: 0px;
	top: 0px;
	width: 100%;
	background: none;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .main-box .outer-container {
	position: relative;
	padding: 0px 40px;
}

.main-header .main-box .logo-box {
	position: relative;
	float: left;
	left: 0px;
	z-index: 10;
	padding: 30px 0px;
}

.main-header .main-box .logo-box .logo img {
	display: inline-block;
	max-width: 100%;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-header .header-upper {
	position: relative;
}

.main-header .header-upper .upper-right {
	position: relative;
	padding-top: 22px;
}

.main-header .header-upper .inner-container {
	position: relative;
}

.main-header .nav-outer {
	position: static;
	float: right;
	border-radius: 50px;
	padding: 0px 15px 0px 40px;
	background-color: rgba(255, 255, 255, 0.15);
}

.main-header .header-upper .logo-box {
	position: relative;
	padding: 0px 0px;
	z-index: 1;
}

.main-header .header-upper .logo-box .logo {
	position: relative;
}

.main-header .outer-box {
	position: relative;
	float: right;
	margin-left: 40px;
	padding: 14px 0px 8px;
}

.main-header .cart-box {
	position: relative;
	float: left;
	color: #eb3867;
	font-size: 14px;
	background: none;
	margin-top: 16px;
	display: inline-block;
}

.main-header .cart-box .cart-box-btn {
	background: none;
	color: #ffffff;
	cursor: pointer;
	font-size: 34px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-box-btn:after {
	display: none;
}

.main-header .cart-box .cart-box-btn .total-cart {
	position: absolute;
	right: -8px;
	top: -8px;
	width: 18px;
	height: 18px;
	color: #ffffff;
	font-weight: 700;
	font-size: 10px;
	border-radius: 50%;
	text-align: center;
	line-height: 18px;
	background-color: #222222;
}

.main-header .cart-box .cart-box-btn:hover {}

.main-header .cart-product {
	position: relative;
	margin-bottom: 18px;
	min-height: 90px;
	border-bottom: 1px solid #e4e4e4;
}

.main-header .cart-product .inner {
	position: relative;
	padding-left: 90px;
	min-height: 70px;
}

.main-header .cart-product .inner .cross-icon {
	position: absolute;
	right: 0px;
	top: -2px;
	z-index: 1;
	color: #263120;
	font-size: 16px;
	cursor: pointer;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-header .cart-product .inner .cross-icon:hover {
	color: #eb3867;
}

.main-header .cart-product .inner .image {
	position: absolute;
	width: 70px;
	height: 70px;
	left: 0px;
	top: 0px;
}

.main-header .cart-product .inner h3 {
	position: relative;
	font-weight: 600;
	font-size: 15px;
	margin: 0px;
	letter-spacing: 1px;
	text-transform: capitalize;
}

.main-header .cart-product .inner h3 a {
	color: #222222;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-product .inner h3 a:hover {
	color: #eb3867;
}

.main-header .cart-product .inner .quantity-text {
	position: relative;
	font-weight: 300;
	color: #666666;
	font-size: 13px;
	margin-bottom: 0px;
}

.main-header .cart-product .inner .price {
	position: relative;
	font-weight: 700;
	font-size: 16px;
	margin: 0px;
	color: #222222;
}

.main-header .cart-box .cart-panel {
	left: auto !important;
	right: 0px !important;
	border-radius: 20px;
	padding: 20px 20px;
	min-width: 300px;
	margin-top: 59px;
	z-index: -1;
	box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
	border: 3px solid #eb3867;
	-webkit-transform: translate3d(0px, 0px, 0px) !important;
	transform: translate3d(0px, 0px, 0px) !important;
	display: block;
	opacity: 0;
	visibility: hidden;
	transition: all .5s;
}

.main-header .cart-box .cart-panel.cart-active {
	margin-top: 30px;
	opacity: 1;
	visibility: visible;
}

.main-header .cart-box .cart-panel .cart-total {
	color: #000000;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	text-transform: capitalize;
}

.main-header .cart-box .cart-panel .cart-total span {
	font-weight: 800;
	font-size: 18px;
}

.main-header .cart-box .cart-panel .btns-boxed {
	position: relative;
	text-align: center;
	margin-top: 20px;
}

.main-header .cart-box .cart-panel .btns-boxed li {
	position: relative;
	margin: 0px 5px;
	display: inline-block;
}

.main-header .cart-box .cart-panel .btns-boxed li a {
	position: relative;
	color: #ffffff;
	font-weight: 500;
	font-size: 12px;
	padding: 10px 20px 8px;
	border-radius: 2px;
	display: inline-block;
	background-color: #eb3867;
	border: 1px solid #eb3867;
	text-transform: uppercase;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-panel .btns-boxed li a:hover {
	color: #eb3867;
	background: none;
}

.main-header .cart-box .icon a {
	color: #999999;
}


.fixed-navbar.active .main-header {
	position: fixed;
	z-index: 555;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	border: none;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.fixed-navbar.active .header-style-one {
	background: #222222;
	padding-top: 0;
}

.fixed-navbar.active .header-style-one .nav-outer {
	background: none;
}

.fixed-navbar.active .header-style-two {
	background: #0028a1;
	padding-top: 0;
}

.fixed-navbar.active .header-style-two:before {
	display: none;
}

.fixed-navbar.active .header-style-two .header-top {
	position: absolute;
	top: -100%;
}


.fixed-navbar.active .header-style-three {
	padding-top: 0;
}

.fixed-navbar.active .header-style-three .navigation>li>a {
	color: #111111;
}

.fixed-navbar.active .header-style-three.s6 .navigation>li>a {
	color: #fff;
}

.fixed-navbar.active .main-header.header-style-three .search-box-btn {
	color: #5e5959;
}

.fixed-navbar.active .main-header.header-style-three.s6 .search-box-btn {
	color: #fff;
}

.fixed-navbar.active .header-style-three.s6 {
	background: #222222;
}

@media only screen and (max-width: 1023px) {
	.main-header.header-style-three {
		background: #fff;
	}

	.main-header.header-style-three .navigation>li>a {
		color: #111111;
	}

	.main-header.header-style-three .nav-outer .mobile-nav-toggler {
		color: #111111;
	}
	.main-header.header-style-three.s6 .nav-outer .mobile-nav-toggler {
		color: #fff;
	}

	.main-header.header-style-three .search-box-btn {
		color: #111111;
	}

	.main-header.header-style-three.s6 {
		background: #222222;
	}

	.header-style-three.s6 .navigation>li>a {
		color: #fff;
	}

	.main-header.header-style-three.s6 .search-box-btn {
		color: #fff;
	}
}

/* Search Box Outer */

.main-header .search-box-btn {
	position: relative;
	top: 0px;
	width: 50px;
	height: 50px;
	display: block;
	float: left;
	font-size: 22px;
	color: #ffffff;
	cursor: pointer;
	line-height: 46px;
	text-align: center;
	border-radius: 50px;
	margin-left: 30px;
	background-color: #652e6f;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 2px solid rgba(255, 255, 255, 0.50);
}

.main-header .search-box-btn:after {
	display: none;
}

.main-header .search-box-outer .dropdown-menu {
	top: 49px !important;
	right: 0px;
	padding: 0px;
	width: 280px;
	left: auto !important;
	border-radius: 0px;
	border-top: 3px solid #eb3867;
	-webkit-transform: translate3d(0px, 0px, 0px) !important;
	transform: translate3d(0px, 0px, 0px) !important;
}

.main-header .btn-box {
	position: relative;
	float: left;
}

/* Header Lower */

.main-header .header-lower .search-box {
	float: right;
}

.main-header .header-lower .search-box .form-group {
	position: relative;
	margin: 0px;
	top: -1px;
}

.main-header .header-lower .search-box .form-group input[type="text"],
.main-header .header-lower .search-box .form-group input[type="search"] {
	position: relative;
	line-height: 28px;
	padding: 10px 50px 10px 25px;
	background: none;
	display: block;
	font-size: 14px;
	width: 200px;
	height: 74px;
	color: #222222;
	font-weight: 400;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background-color: #ffffff;
	border-radius: 0px 50px 50px 0px;
}

.main-header .header-lower .search-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 74px;
	width: 45px;
	font-size: 14px;
	color: #000048;
	line-height: 100%;
	background: none;
	display: inline-block;
	font-weight: normal;
	text-align: left;
	cursor: pointer;
}

/* Main Menu */

.main-menu {
	position: static;
	float: left;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}


.main-menu .navbar-collapse {
	padding: 0px;
	float: left;
	display: block !important;
}

.main-menu .navigation {
	position: static;
	margin: 0px;
}

.main-menu .navigation>li {
	position: relative;
	float: left;
	margin-right: 30px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li:last-child {
	margin-right: 0px;
}

.sticky-header .main-menu .navigation>li>a {
	color: #444444;
}

.sticky-header .main-menu .navigation>li:hover>a,
.sticky-header .main-menu .navigation>li.current>a {
	color: #eb3867;
	background-color: inherit !important;
}

.sticky-header .nav-outer .options-box {
	margin-top: 45px;
}

/*Sticky Header*/

.sticky-header {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	left: 0px;
	top: 0px;
	width: 100%;
	padding: 0px 0px;
	background: #ffffff;
	z-index: 0;
	border-bottom: 1px solid #e8f1f7;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.sticky-header .logo {
	padding: 2px 0px 2px;
}

.fixed-header .sticky-header {
	z-index: 999;
	opacity: 1;
	visibility: visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.main-menu .navigation>li>a {
	position: relative;
	display: block;
	color: #ffffff;
	text-align: center;
	line-height: 30px;
	text-transform: capitalize;
	letter-spacing: 0px;
	opacity: 1;
	font-weight: 500;
	padding: 25px 0px;
	font-size: 16px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	font-family: 'Archivo', sans-serif;
}

.sticky-header .main-menu .navigation>li {
	position: relative;
	margin-left: 30px;
	margin-right: 0px;
}

.sticky-header .main-menu .navigation>li:before,
.sticky-header .main-menu .navigation>li:after {}

.main-menu .navigation>li:hover>a,
.main-menu .navigation>li.current>a {
	opacity: 1;
	color: #eb3867;
}

.main-menu .navigation>li>ul {
	position: absolute;
	left: -30px;
	top: 100%;
	width: 230px;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	padding: 20px 15px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.main-menu .navigation>li>ul.from-right {
	left: auto;
	right: 0px;
}

.main-menu .navigation>li>ul>li {
	position: relative;
	width: 100%;
	border-bottom: 1px solid #f9f9f9;
}

.main-menu .navigation>li>ul>li:last-child {
	border-bottom: none;
}

.main-menu .navigation>li>ul>li>a {
	position: relative;
	display: block;
	padding: 10px 18px;
	line-height: 24px;
	font-weight: 500;
	font-size: 16px;
	color: #111111;
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	font-family: 'Archivo', sans-serif;
}

.sticky-header .main-menu .navigation>li>a {
	padding: 19px 0px !important;
}

.main-menu .navigation>li>ul>li>a:before {
	position: absolute;
	content: '//';
	left: 10px;
	top: 10px;
	opacity: 0;
	color: #eb3867;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li:hover>a::before {
	opacity: 1;
}

.sticky-header .main-menu .navigation>li>a:before {
	top: 18px !important;
}

.main-menu .navigation>li>ul>li:hover>a {
	color: #eb3867;
	padding-left: 30px;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
	font-family: 'FontAwesome';
	content: "\f105";
	position: absolute;
	right: 15px;
	top: 13px;
	width: 10px;
	height: 20px;
	display: block;
	color: #253d4a;
	line-height: 20px;
	font-size: 16px;
	font-weight: normal;
	text-align: center;
	z-index: 5;
}

.rtl .main-menu .navigation>li>ul>li.dropdown>a:after {
	transform: rotate(180deg);
}

.main-menu .navigation>li>ul>li.dropdown:hover>a:after {
	color: #222222;
}

.main-menu .navigation>li>ul>li>ul {
	position: absolute;
	left: 120%;
	top: 0%;
	width: 230px;
	z-index: 100;
	display: none;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	padding: 20px 15px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.main-menu .navigation>li>ul>li>ul.from-right {
	left: auto;
	right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
	position: relative;
	width: 100%;
	border-bottom: 1px solid #f9f9f9;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
	border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
	position: relative;
	display: block;
	padding: 10px 18px;
	line-height: 24px;
	font-weight: 500;
	font-size: 16px;
	text-transform: capitalize;
	color: #111111;
	padding-left: 10px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	text-transform: capitalize;
	font-family: 'Archivo', sans-serif;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a {
	color: #eb3867;
}

.main-menu .navigation>li>ul>li>ul>li>a:before {
	position: absolute;
	content: '//';
	left: 10px;
	top: 8px;
	opacity: 0;
	color: #eb3867;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a::before {
	opacity: 1;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a {
	color: #eb3867;
	padding-left: 30px;
}

.main-menu .navigation>li.dropdown:hover>ul {
	visibility: visible;
	opacity: 1;
	left: 0px;
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
	visibility: visible;
	opacity: 1;
	top: 0;
	left: 100%;
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.main-menu .navbar-collapse>ul li.dropdown .dropdown-btn {
	position: absolute;
	right: 10px;
	top: 6px;
	width: 30px;
	height: 30px;
	text-align: center;
	color: #ffffff;
	line-height: 28px;
	border: 1px solid #ffffff;
	background-size: 20px;
	cursor: pointer;
	z-index: 5;
	display: none;
}

.main-header .search-box {
	position: relative;
	float: left;
	width: 160px;
	padding: 33px 0px;
}

.main-header .search-box .form-group {
	position: relative;
	margin: 0px;
}

.main-header .search-box .form-group input[type="text"],
.main-header .search-box .form-group input[type="search"] {
	position: relative;
	line-height: 38px;
	padding: 10px 50px 10px 20px;
	background: none;
	display: block;
	font-size: 14px;
	width: 100%;
	height: 30px;
	color: #ffffff;
	border-radius: 0px;
	font-weight: 600;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border-left: 1px solid #eb3867;
}

.main-header .search-box .form-group textarea::-webkit-input-placeholder,
.main-header .search-box .form-group input::-webkit-input-placeholder {
	color: #ffffff;
}

.main-header .search-box .form-group button {
	position: absolute;
	right: 0px;
	top: 8px;
	display: block;
	font-size: 16px;
	color: #ffffff;
	cursor: pointer;
	line-height: 100%;
	font-weight: normal;
	background: none;
}

.main-header .header-lower .options-box {
	position: relative;
	float: right;
	padding: 10px 15px 10px;
}

.main-header .header-lower .options-box .option-inner:before {
	position: absolute;
	content: '';
	left: -10px;
	top: -10px;
	bottom: -10px;
	width: 110%;
	background-color: #eb3867;
	border-radius: 50px 50px 50px 50px;
}

.main-header .header-lower .options-box .option-inner {
	position: relative;
	padding-left: 70px;
}

.main-header .header-lower .options-box .option-inner .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	color: #ffffff;
	font-size: 50px;
	line-height: 1em;
}

.main-header .header-lower .options-box .option-inner .number {
	position: relative;
}

.main-header .header-lower .options-box .option-inner .number span {
	position: relative;
	display: block;
	color: #ffffff;
	margin-bottom: 3px;
	text-transform: uppercase;
}

.main-header .header-lower .options-box .option-inner .number a {
	position: relative;
	font-weight: 800;
	font-size: 22px;
	color: #ffffff;
}

/************************ Mega Menu ***************************/

.main-menu .navigation>li.has-mega-menu {
	position: static;
}

.main-menu .navigation>li>.mega-menu {
	position: absolute;
	left: 0px;
	right: 0;
	margin: auto;
	width: 100%;
	max-width: 1170px;
	background-color: #ffffff;
	padding: 45px 40px 30px 45px;
	top: 100%;
	z-index: 100;
	opacity: 0;
	border-radius: 8px;
	-webkit-transform: scaleY(0);
	-moz-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-moz-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li:hover>.mega-menu {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
}

.main-menu .navigation>li>.mega-menu .mega-menu-bar {
	position: relative;
}

.main-menu .navigation>li .mega-menu-bar h3 {
	position: relative;
	color: #111111;
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 12px;
	margin-bottom: 10px;
	border-bottom: 1px solid #f2f2f2;
}

.main-menu .navigation>li .mega-menu-bar>ul {
	position: relative;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li {
	position: relative;
	width: 100%;
	padding: 7px 0px;
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li .mega-menu-bar .column {
	position: relative;
	margin-bottom: 12px;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li:last-child {
	border: none;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a {
	position: relative;
	display: block;
	line-height: 20px;
	font-weight: 500;
	font-size: 16px;
	color: #111111;
	text-transform: capitalize;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	font-family: 'Archivo', sans-serif;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:before {
	position: absolute;
	content: '//';
	left: 0px;
	top: 0px;
	opacity: 0;
	color: #eb3867;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:hover {
	color: #eb3867;
	padding-left: 20px;
}

.main-menu .navigation>li .mega-menu-bar .column>ul>li>a:hover::before {
	opacity: 1;
}

.header-style-two {
	position: relative;
	padding-top: 0px;
}

.header-style-two:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 340px;
	height: 148px;
	background: url(../images/background/pattern-6.png);
	background-repeat: no-repeat;
}

.header-style-two .nav-outer {
	position: relative;
	padding-left: 0px;
}

.header-style-two .nav-outer:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 180%;
	background-color: #0028a1;
	border-radius: 0px 8px 8px 0px;
}

.header-style-two .search-box-btn {
	border: none;
	width: auto;
	background: none;
	margin-right: 20px;
}

.header-style-two .outer-box {
	margin-left: 30px;
	padding: 25px 0px 16px;
}

.header-style-two .header-upper .logo-box .logo {
	padding: 10px 0px;
}

.header-style-two .main-menu .navigation>li>a:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 34px;
	width: 20%;
	height: 1px;
	opacity: 0;
	background-color: #fe4c1c;
	transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
}

.header-style-two .main-menu .navigation>li:hover>a::before,
.header-style-two .main-menu .navigation>li.current>a::before {
	width: 100%;
	opacity: 1;
}

.header-style-two .main-menu .navigation>li>a:after {
	position: absolute;
	content: '';
	left: 50%;
	bottom: 18px;
	width: 13px;
	height: 6px;
	opacity: 0;
	margin-left: -6px;
	transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	background: url(../images/icons/menu-icon.png) no-repeat;
}


.header-style-two .main-menu .navigation>li:hover>a::after,
.header-style-two .main-menu .navigation>li.current>a::after {
	width: 100%;
	opacity: 1;
}

.header-style-two .main-menu .navigation>li>a {
	padding: 35px 0px;
}

.header-style-two .sticky-header .outer-box .search-box-btn {
	color: #111111;
}

.header-style-three .sticky-header .outer-box .search-box-btn {
	color: #111111;
	margin-left: 15px;
}

.header-style-two .sticky-header .outer-box .nav-btn,
.header-style-three .sticky-header .outer-box .nav-btn {
	margin-top: 15px;
}

.header-style-two .sticky-header .outer-box .cart-box .cart-panel {
	margin-top: 45px;
}

.header-style-three .nav-outer {
	padding: 0px;
	background: none;
	border-radius: 0px;
}

.header-style-three .search-box-btn {
	background: none;
	border: none;
	margin-left: 0px;
	margin-right: 10px;
}

.header-style-one .main-menu .navigation>li {
	margin-right: 12px;
	padding: 24px 0px;
}

.header-style-one .sticky-header .main-menu .navigation>li {
	padding: 0px;
	margin-left: 0px;
	margin-right: 25px;
}

.header-style-one .sticky-header .main-menu .navigation>li:hover>a,
.header-style-one .sticky-header .main-menu .navigation>li.current>a {
	color: #1519b2;
}

.header-style-one .sticky-header .main-menu .navigation>li>a::before {
	display: none;
}

.header-style-one .main-menu .navigation>li>a {
	padding: 0px 10px;
}

.header-style-one .main-menu .navigation>li>a:before {
	position: absolute;
	content: '\f0d7';
	left: 50%;
	bottom: -15px;
	color: #1519b2;
	font-size: 25px;
	line-height: 1em;
	margin-left: -7px;
	opacity: 0;
	font-family: 'FontAwesome';
	transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
}

.header-style-one .main-menu .navigation>li:hover>a,
.header-style-one .main-menu .navigation>li.current>a {
	background-color: #1519b2;
	border-radius: 50px;
	color: #ffffff;
}

.header-style-one .main-menu .navigation>li:hover>a::before,
.header-style-one .main-menu .navigation>li.current>a::before {
	opacity: 1;
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.hidden-bar {
	position: fixed;
	top: 0;
	width: 305px;
	height: 100%;
	background: #272727;
	z-index: 9999;
	transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
}

.hidden-bar .mCSB_inside>.mCSB_container {
	margin-right: 0px;
}

.hidden-bar.right-align {
	right: -400px;
}

.hidden-bar.right-align.visible-sidebar {
	right: 0px;
}

.hidden-bar.left-align {
	left: 0px;
}

.hidden-bar .hidden-bar-closer {
	position: absolute;
	top: 10px;
	right: 15px;
	background: none;
	color: #ffffff;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	z-index: 999999;
}

.hidden-bar .hidden-bar-closer:hover {
	color: #414857;
}

.hidden-bar .hidden-bar-closer:hover .btn {
	color: #ffffff;
}

.hidden-bar .hidden-bar-closer .btn {
	background-color: transparent;
	border: none;
	color: #ffffff;
	outline: none;
	font-size: 14px;
}

.hidden-bar .social-icons {
	text-align: center;
	margin: 50px 0px 30px;
}

.hidden-bar .social-icons ul {
	font-size: 0;
	margin-left: -5px;
	margin-right: -5px;
}

.hidden-bar .social-icons ul li {
	display: inline-block;
	padding: 0 5px;
}

.hidden-bar .social-icons ul li a {
	display: block;
	width: 36px;
	height: 36px;
	font-size: 16px;
	line-height: 36px;
	text-align: center;
	background: #414857;
	color: #ffffff;
	border-radius: 5px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.hidden-bar .social-icons ul li a:hover {
	background: #ffffff;
	color: #414857;
}

.hidden-bar .logo {
	padding: 40px 30px;
}

.hidden-bar .logo img {
	display: inline-block;
	max-width: 100%;
}

.hidden-bar .side-menu {
	background-color: transparent;
	padding: 0;
	font-size: 13px;
	letter-spacing: 1px;
}

.hidden-bar .side-menu ul li ul a {
	background: transparent;
}

.hidden-bar .side-menu ul li ul li ul li a {
	background: transparent;
}

.hidden-bar .side-menu a.current {
	color: #ffffff;
}

.hidden-bar .side-menu ul li a {
	background: transparent;
	color: #ffffff;
	display: block;
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;
	border-top: 1px solid rgba(255, 255, 255, 0.30);
	border-bottom: 1px solid rgba(255, 255, 255, 0.30);
	padding: 10px 15px 10px 20px;
	position: relative;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.hidden-bar .side-menu ul li a:hover,
.hidden-bar .side-menu ul>li.current>a,
.hidden-bar .side-menu ul>li>ul>li.current>a {
	background: rgba(255, 255, 255, 0.10);
	color: #ffffff;
}

.hidden-bar .side-menu ul li a .btn.expander {
	background: none;
	color: #ffffff;
	border: 1px solid rgba(255, 255, 255, 0.80);
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 0px;
	position: absolute;
	top: 6px;
	right: 20px;
	width: 32px;
	height: 32px;
	outline: none;
}

/*** 

====================================================================
	Login / Register Section
====================================================================

***/

.register-section {
	position: relative;
	z-index: 9;
	padding: 60px 0px 40px;
}

.register-section .form-column {
	margin-bottom: 40px;
}

.register-section .form-column .sec-title {
	position: relative;
	margin-bottom: 30px;
}

.register-section .form-column .sec-title h2 {}

.styled-form {
	position: relative;
}

.styled-form .submit-text {
	font-size: 15px;
	color: #9e9e9e;
	font-weight: 400;
	margin-top: 10px;
}

.styled-form .form-group {
	position: relative;
	margin-bottom: 30px;
	font-size: 14px;
	color: #848484;
}

.checkout-section .styled-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.styled-form .form-group .check-box {
	line-height: 24px;
	font-size: 15px;
	font-weight: normal;
	padding-top: 5px;
	color: #272727;
}

.styled-form .form-group .check-box label {
	position: relative;
	top: -1px;
	font-weight: normal;
	padding: 0px;
	font-size: 15px;
	cursor: pointer;
}

.styled-form .pull-left label,
.styled-form .pull-right label {
	cursor: pointer;
}

.styled-form .form-group .adon-icon {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 10px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 15px;
	color: #999999;
	z-index: 2;
}

.styled-form .form-group .field-label {
	display: block;
	color: #272727;
	text-transform: capitalize;
	font-size: 16px;
	margin-bottom: 7px;
	font-weight: 400;
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
	position: relative;
	display: block;
	width: 100%;
	background: #ffffff;
	line-height: 30px;
	padding: 8px 20px;
	height: 50px;
	border: 1px solid #f4f4f4;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select {
	line-height: 26px;
	height: 44px;
}

.styled-form select {
	-webkit-appearance: none;
	-ms-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	background: #ffffff url(../images/icons/icon-select.png) right center no-repeat;
	cursor: pointer;
}

.styled-form select option {
	text-indent: 20px;
}

.styled-form textarea {
	resize: none;
	height: 110px;
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus {
	border-color: #eb3867;
}

.styled-form .form-group .theme-btn {
	min-width: 150px;
	text-align: center;
	padding: 11px 34px;
	border-radius: 0px;
	cursor: pointer;
}

.styled-form .remember-me {
	position: relative;
	color: #222222;
	font-size: 16px;
	font-weight: 500;
}

.social-links-two a {
	position: relative;
	display: inline-block;
	font-size: 14px;
	width: 42px;
	height: 42px;
	line-height: 42px;
	text-align: center;
	color: #ffffff;
	background: #fc721e;
	margin: 0px 0px 0px 5px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
}

.social-links-two a:hover {
	opacity: 0.80;
}

.social-links-two .facebook {
	background: #3b5998;
}

.social-links-two .twitter {
	background: #33ccfe;
}

.social-links-two .google-plus {
	background: #dd4b39;
}

/*** 

====================================================================
			Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler {
	position: relative;
	float: right;
	font-size: 36px;
	line-height: 50px;
	cursor: pointer;
	color: #ffffff;
	display: none;
}

.mobile-menu {
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right: 30px;
	max-width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo {
	position: relative;
	padding: 20px 20px;
	text-align: left;
}

.mobile-menu .nav-logo img {
	max-width: 200px;
}

.mobile-menu-visible .mobile-menu {
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-backdrop {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.7s ease;
	-moz-transition: all 0.7s ease;
	-ms-transition: all 0.7s ease;
	-o-transition: all 0.7s ease;
	transition: all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .menu-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #ffffff;
	padding: 0px 0px;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	border-radius: 0px;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.7s ease 500ms;
	-moz-transition: all 0.7s ease 500ms;
	-ms-transition: all 0.7s ease 500ms;
	-o-transition: all 0.7s ease 500ms;
	transition: all 0.7s ease 500ms;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .close-btn {
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 30px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 14px;
	background: #e9e9e9;
	color: #202020;
	cursor: pointer;
	z-index: 10;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn {
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}

.mobile-menu .close-btn:hover {
	background: #ff0300;
	color: #fff;
}

.mobile-menu .navigation {
	position: relative;
	display: block;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.10);
	padding-top: 70px;
}

.mobile-menu .navigation li {
	position: relative;
}

.mobile-menu .navigation li>ul>li:last-child {
	border-bottom: none;
}

.mobile-menu .navigation li>ul>li:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation .MuiList-root {
	padding: 0;
}

.mobile-menu .navigation .MuiList-root li a,
.mobile-menu .navigation .MuiList-root li p,
.mobile-menu .navigation .MuiList-root a {
	position: relative;
	display: block;
	line-height: 24px;
	padding: 12px 20px;
	font-size: 15px;
	color: #404040;
	text-transform: capitalize;
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: 0;
}

.mobile-menu .navigation .MuiList-root li a i {}

.mobile-menu .navigation li:hover>a,
.mobile-menu .navigation li.current>a {
	color: #000000;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 44px;
	height: 44px;
	text-align: center;
	font-size: 16px;
	line-height: 44px;
	color: #404040;
	cursor: pointer;
	z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after {
	content: '';
	position: absolute;
	left: 0px;
	top: 10px;
	width: 1px;
	height: 24px;
	border-left: 1px solid rgba(0, 0, 0, 0.10);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
	display: none;
}

.sticky-header .navbar-header {
	display: none;
}

/*** 

====================================================================
	Search Popup
====================================================================

***/


.search-popup {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 99999;
	margin-top: -540px;
	transform: translateY(-100%);
	background-color: rgba(0, 0, 0, 0.90);
	-webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	/* easeInOutQuint */
}

.sidenav-bar-visible .search-popup {
	width: 80%;
}

.search-popup:before {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	height: 560px;
	background-image: url(../images/icons/waves-shape.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-top: 0px;
	content: "";
}

.search-active.search-popup {
	transform: translateY(0%);
	margin-top: 0;
}

.search-popup .close-search {
	position: absolute;
	left: 0;
	right: 0;
	top: 75%;
	margin: 0 auto;
	margin-top: -200px;
	border-radius: 50%;
	text-align: center;
	background-color: #eb3867;
	width: 70px;
	cursor: pointer;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	border-bottom: 3px solid #ffffff;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	opacity: 0;
	visibility: hidden;
}

.search-popup .close-search span {
	position: relative;
	display: block;
	height: 70px;
	width: 70px;
	font-size: 30px;
	line-height: 70px;
	color: #ffffff;
}

.search-active.search-popup .close-search {
	visibility: visible;
	opacity: 1;
	top: 50%;
	-webkit-transition-delay: 1500ms;
	-moz-transition-delay: 1500ms;
	-ms-transition-delay: 1500ms;
	-o-transition-delay: 1500ms;
	transition-delay: 1500ms;
}

.search-popup form {
	position: absolute;
	max-width: 700px;
	top: 50%;
	left: 15px;
	right: 15px;
	margin: -35px auto 0;
	transform: scaleX(0);
	transform-origin: center;
	background-color: #111111;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.search-active.search-popup form {
	transform: scaleX(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.search-popup .form-group {
	position: relative;
	margin: 0px;
	overflow: hidden;
}

.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"] {
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 50px;
	color: #000000;
	height: 70px;
	width: 100%;
	padding: 10px 30px;
	background-color: #ffffff;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	font-weight: 500;
	text-transform: capitalize;
}

.search-popup .form-group input[type="submit"],
.search-popup .form-group button {
	position: absolute;
	right: 30px;
	top: 0px;
	height: 70px;
	line-height: 70px;
	background: transparent;
	text-align: center;
	font-size: 24px;
	color: #000000;
	padding: 0;
	cursor: pointer;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover {
	color: #000000;
}

.search-popup input::placeholder,
.search-popup textarea::placeholder {
	color: #000000;
}

.search-popup .close-search.style-two {
	position: absolute;
	right: 25px;
	left: auto;
	color: #ffffff;
	width: auto;
	height: auto;
	top: 25px;
	margin: 0px;
	border: none;
	background: none !important;
	box-shadow: none !important;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.search-popup .close-search.style-two span {
	font-size: 20px;
	color: #ffffff;
}

.main-header .sticky-header .outer-box {
	padding: 7px 0px 0px;
}

.main-header .sticky-header .outer-box .cart-box {
	color: #000000;
	margin-top: 17px;
}

.main-header .sticky-header .outer-box .cart-box .cart-box-btn,
.main-header .sticky-header .outer-box .cart-box .cart-box-btn:hover {
	color: #000000;
}

.main-header .sticky-header .outer-box .cart-box .cart-box-btn .total-cart {
	background-color: #eb3867;
}

.color-palate .option-box.box-version {
	padding-top: 0px;
	padding-bottom: 0px;
}

.color-palate .option-box>li {
	position: relative;
	color: #ffffff;
	width: 40%;
	margin: 0px 3px;
	cursor: pointer;
	margin-bottom: 9px;
	padding: 6px 12px;
	border-radius: 4px;
	display: inline-block;
	background-color: #eb3867;
	text-transform: capitalize;
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.sidebar-info-contents {
	position: relative;
}

.sidebar-info-contents .content-inner {
	position: relative;
}

.sidebar-info-contents .content-inner .logo {
	padding: 0px 0px 40px;
}

.sidebar-info-contents .content-inner .logo img {
	display: inline-block;
	max-width: 100%;
}

.sidebar-info-contents .content-inner .content-box {
	position: relative;
}

.sidebar-info-contents .content-inner .content-box h2 {
	position: relative;
	font-size: 20px;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .content-box .text {
	position: relative;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.90);
	margin-bottom: 25px;
}

.sidebar-info-contents .content-inner .contact-info {
	position: relative;
	margin-top: 60px;
}

.sidebar-info-contents .content-inner .contact-info h2 {
	position: relative;
	font-size: 20px;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .social-box {
	position: relative;
	margin-top: 20px;
	margin-bottom: 30px;
}

.sidebar-info-contents .content-inner .social-box li {
	position: relative;
	display: inline-block;
	margin-right: 6px;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.sidebar-info-contents .content-inner .social-box li a {
	position: relative;
	width: 36px;
	height: 36px;
	color: #222222;
	z-index: 1;
	font-size: 13px;
	line-height: 36px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	background-color: #ffffff;
}

.close-side-widget {
	cursor: pointer;
}

.close-side-widget,
.close-side-widget:hover {
	color: #ffffff;
	font-size: 24px;
}

.main-header .header-upper .outer-box .nav-btn {
	position: relative;
	float: left;
	font-size: 34px;
	color: #ffffff;
	cursor: pointer;
	margin-top: 15px;
	margin-left: 25px;
}

.sticky-header .outer-box .nav-btn {
	position: relative;
	float: right;
	font-size: 28px;
	color: #000000;
	cursor: pointer;
	margin-top: 9px;
	margin-left: 30px;
}

.sticky-header .outer-box .mobile-nav-toggler {
	position: relative;
	float: right;
	font-size: 28px;
	color: #000000;
	cursor: pointer;
	margin-top: 9px;
	margin-left: 30px;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-toped {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 40px;
	height: 40px;
	color: #ffffff;
	font-size: 13px;
	text-transform: uppercase;
	line-height: 38px;
	text-align: center;
	z-index: 100;
	cursor: pointer;
	background: #222222;
	display: none;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.scroll-to-toped:hover {
	color: #ffffff;
	background: #000000;
}

.waves {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 15vh;
	z-index: 1;
	margin-bottom: -8px;
	min-height: 100px;
	max-height: 150px;
}

.waves .content {
	position: relative;
	height: 20vh;
	text-align: center;
	background-color: white;
}

/*** 

====================================================================
	Banner Section
====================================================================

***/

.banner-section {
	position: relative;
	overflow: hidden;
	z-index: 9;

}

.banner-section .slide {
	position: relative;
	overflow: hidden;
	padding-top: 300px;
	padding-bottom: 210px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 1116px;
	display: flex;
	justify-content: center;
	flex-direction: column;

}

.banner-section .slide .hero-inner {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.banner-section .slide .hero-inner .auto-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

.banner-section .dotted-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-position: center top;
	background-repeat: no-repeat;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.banner-section .patern-layer-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 260px;
	height: 330px;
	z-index: 2;
	opacity: 0;
	transform: translateX(-500px);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .patern-layer-one {
	opacity: 1;
	transform: translateX(0px);
}

.banner-section .patern-layer-two {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 432px;
	height: 390px;
	z-index: 2;
	background-position: right top;
	background-repeat: no-repeat;
	transform: translateX(500px);
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .patern-layer-two {
	transform: translateX(00px);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-section .content-column {
	position: relative;
	z-index: 10;
}

.banner-section .content-column .inner-column {
	position: relative;
	padding-bottom: 60px;
}

.banner-section .content-column .title {
	position: relative;
	color: #ffffff;
	font-size: 24px;
	line-height: 1.8em;
	margin-bottom: 16px;
	font-weight: 700;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .content-column .title {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-section .content-column h1 {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	line-height: 1.3em;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .content-column h1 {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.banner-section .content-column .btns-box {
	margin-top: 35px;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .content-column .btns-box {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.banner-section .content-column .theme-btn {
	margin-right: 15px;
}

.banner-section .image-column {
	position: relative;
	z-index: 10;
	opacity: 0;
	transform: scaleX(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section .slick-slide.slick-active.slick-current .image-column {
	opacity: 1;
	transform: scaleX(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.banner-section .image-column .inner-column {
	position: relative;
	padding-top: 130px;
}

.banner-section .image-column .images-icons {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.banner-section .image-column .images-icons .image-1 {
	position: absolute !important;
	left: -30px !important;
	top: 30px !important;
	width: 66px;
	height: 67px;
	background-repeat: no-repeat;
}

.banner-section .image-column .images-icons .image-2 {
	position: absolute !important;
	left: 170px !important;
	top: 0px !important;
	width: 484px;
	height: 505px;
	background-repeat: no-repeat;
}

.banner-section .image-column .images-icons .image-3 {
	position: absolute !important;
	right: -80px !important;
	top: 60px !important;
	left: auto !important;
	width: 123px;
	height: 211px;
	background-repeat: no-repeat;
}

.banner-section .image-column .images-icons .image-4 {
	position: absolute !important;
	left: -150px !important;
	top: auto !important;
	bottom: 0px !important;
	width: 275px;
	height: 345px;
	background-repeat: no-repeat;
}

.banner-section .image-column .images-icons .image-5 {
	position: absolute !important;
	left: auto !important;
	top: auto !important;
	bottom: 0px !important;
	right: -200px !important;
	width: 190px;
	height: 247px;
	background-repeat: no-repeat;
}

.banner-section .image-column .images-icons .image-6 {
	position: absolute !important;
	left: -100px !important;
	top: 230px !important;
	width: 39px;
	height: 47px;
	background-repeat: no-repeat;
}

.banner-section .owl-dots {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 40px;
	text-align: center;
	display: none;
}

.banner-section .owl-dots .owl-dot {
	position: relative;
	width: 30px;
	height: 6px;
	margin-bottom: 10px;
	border-radius: 0px;
	margin: 0px 5px;
	display: inline-block;
	background-color: #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.banner-section .owl-dots .owl-dot.active,
.banner-section .owl-dots .owl-dot:hover {
	background-color: #141d38;
}

.banner-section .owl-nav {
	position: absolute;
	left: 0px;
	top: 50%;
	z-index: 99999;
	width: 100%;
	opacity: 0;
	margin-top: -10px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.banner-section:hover .owl-nav {
	opacity: 1;
}

.banner-section .owl-nav .owl-prev {
	position: absolute;
	left: 20px;
	width: 60px;
	height: 60px;
	color: #ffffff;
	line-height: 56px;
	font-size: 24px;
	text-align: center;
	border-radius: 50px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 2px solid rgba(255, 255, 255, 0.60);
}

.banner-section .owl-nav .owl-next {
	position: absolute;
	right: 20px;
	color: #ffffff;
	font-size: 24px;
	width: 60px;
	height: 60px;
	color: #ffffff;
	line-height: 56px;
	border-radius: 50px;
	text-align: center;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 2px solid rgba(255, 255, 255, 0.60);
}

.banner-section .owl-nav .owl-prev:hover,
.banner-section .owl-nav .owl-next:hover {
	color: #e82a6a;
	border-color: #ffffff;
	background-color: #ffffff;
}

/*** 

====================================================================
	Services Section
====================================================================

***/

.services-section {
	position: relative;
	padding: 400px 0px 120px;
	overflow: hidden;
}

.services-section.margin-top {
	margin-top: -340px;
}

.services-section:before {
	position: absolute;
	content: '';
	left: -200px;
	top: 0px;
	right: -100px;
	height: 600px;
	background: #FFFFFF;
	transform: rotate(-8deg);
	background: -webkit-linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
	background: -moz-linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
	background: linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
}

.services-section .service-block:nth-child(1) {
	transform: translateY(120px);
}

.services-section .service-block:nth-child(2) {
	transform: translateY(80px);
}

.services-section .service-block:nth-child(3) {
	transform: translateY(40px);
}

.services-section.style-two::before {
	display: none;
}

.services-section.style-two {
	padding-top: 100px;
	padding-bottom: 0px;
}

.services-section.style-two .service-block:nth-child(1),
.services-section.style-two .service-block:nth-child(2),
.services-section.style-two .service-block:nth-child(3) {
	transform: translateY(0px);
}

.service-block {
	position: relative;
	margin-bottom: 30px;
}

.service-block .inner-box {
	position: relative;
	text-align: center;
	border-radius: 8px;
	padding: 70px 20px 60px;
	background-color: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.service-block .inner-box .color-layer {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 8px;
}

.service-block .inner-box .color-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #D34AE0;
	transform: rotateY(90deg);
	border-radius: 8px 0px 0px 8px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background: -webkit-linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
	background: -moz-linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
	background: linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
}

.service-block .inner-box .color-layer:after {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #D34AE0;
	transform: rotateY(90deg);
	border-radius: 0px 8px 8px 0px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	background: -webkit-linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
	background: -moz-linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
	background: linear-gradient(to bottom, #D34AE0 0%, #6615B2 100%);
}

.service-block .inner-box:hover .color-layer:before,
.service-block .inner-box:hover .color-layer:after {
	opacity: 1;
	transform: rotateY(0deg);
}

.service-block .inner-box .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 262px;
	height: 175px;
	border-radius: 8px;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box:hover .pattern-layer-one {
	opacity: 0;
}

.service-block .inner-box .pattern-layer-two {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 108px;
	height: 123px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box:hover .pattern-layer-two {
	opacity: 1;
}

.service-block .inner-box .icon-box {
	position: relative;
	color: #242e6c;
	font-size: 60px;
	line-height: 1em;
	margin-bottom: 20px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box:hover h5 a,
.service-block .inner-box:hover .text,
.service-block .inner-box:hover .icon-box {
	color: #ffffff;
}

.service-block .inner-box h5 {
	position: relative;
	line-height: 1.3em;
	font-weight: 600;
}

.service-block .inner-box h5 a {
	position: relative;
	color: #222222;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box .text {
	position: relative;
	color: #777777;
	font-size: 15px;
	line-height: 1.7em;
	margin-top: 15px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box .arrow-icon {
	position: absolute;
	left: 50%;
	bottom: -25px;
	width: 50px;
	height: 50px;
	color: #ffffff;
	font-size: 18px;
	line-height: 44px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	border: 3px solid #ffffff;
	background: #FE8B57;
	margin-left: -25px;
	opacity: 0;
	transform: translateY(-25px);
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.10);
	background: -webkit-linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	background: -moz-linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	background: linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block .inner-box:hover .arrow-icon {
	transform: translateY(0px);
	opacity: 1;
}

.service-block.style-two .inner-box .color-layer:before {
	background-color: #4c68e2;
	background: -webkit-linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
	background: -moz-linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
	background: linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
}

.service-block.style-two .inner-box .color-layer:after {
	background-color: #4c68e2;
	background: -webkit-linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
	background: -moz-linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
	background: linear-gradient(to bottom, #4c68e2 0%, #161ab2 100%);
}

.service-block.style-two .inner-box .arrow-icon {
	background-color: #ff2800;
	background: -webkit-linear-gradient(to bottom, #ff2800 0%, #ff2800 100%);
	background: -moz-linear-gradient(to bottom, #ff2800 0%, #ff2800 100%);
	background: linear-gradient(to bottom, #ff2800 0%, #ff2800 100%);
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section {
	position: relative;
	padding: 80px 0px 60px;
}

.about-section.style-two {
	padding-bottom: 75px;
	overflow: hidden;
}

.about-section.style-two:before {
	position: absolute;
	content: '';
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 600px;
	background: #FFFFFF;
	background: -webkit-linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
	background: -moz-linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
	background: linear-gradient(to bottom, #FFFFFF 0%, #edefff 100%);
}

.about-section.style-two .content-column .inner-column {
	padding-top: 0px;
	padding-left: 45px;
}

.about-section.style-two .circle-layer {
	right: -280px;
	left: auto;
}

.about-section.style-two .content-column .bold-text,
.about-section.style-two .content-column .text {
	padding-right: 0px;
}

.about-section.style-two .image-column .inner-column {
	margin-left: -150px;
}

.about-section .circle-layer {
	position: absolute;
	left: -180px;
	top: px;
	width: 428px;
	height: 467px;
	background-repeat: no-repeat;
}

.about-section .content-column {
	position: relative;
	margin-bottom: 40px;
}

.about-section .content-column .sec-title {
	margin-bottom: 18px;
}

.about-section .content-column .inner-column {
	position: relative;
	padding-top: 100px;
}

.about-section .content-column .bold-text {
	position: relative;
	color: #555555;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.5em;
	margin-bottom: 20px;
	padding-right: 20px;
}

.about-section .content-column .inner-column .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	padding-right: 60px;
	margin-bottom: 40px;
}

.about-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.about-section .image-column .inner-column {
	position: relative;
}

/*** 

====================================================================
	Services Section Two
====================================================================

***/

.services-section-two {
	position: relative;
	padding: 0px 0px 0px;
}

.services-section-two:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 290px;
	background-color: #f3f4ff;
}

.services-section-two .inner-container {
	position: relative;
	background: #FD8C56;
	border-radius: 10px;
	background: -webkit-linear-gradient(to bottom, #FD8C56 0%, #ea3368 100%);
	background: -moz-linear-gradient(to bottom, #FD8C56 0%, #ea3368 100%);
	background: linear-gradient(to bottom, #FD8C56 0%, #ea3368 100%);
}

.services-section-two .service-block-two {
	padding: 0px;
	float: left;
	margin-bottom: 0px;
}

.services-section-two.style-two {
	padding: 110px 0px 110px;
}

.services-section-two.style-two .inner-container {
	background: none;
}

.services-section-two.style-two::before {
	display: none;
}

.services-section-two.style-two .lower-box {
	position: relative;
	text-align: center;
	margin-top: 50px;
}

.services-section-two.style-two .lower-box .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	padding: 12px 40px;
	border-radius: 2px;
	display: inline-block;
	border: 1px dashed #d7d7d7;
}

.services-section-two.style-two .lower-box .text a {
	position: relative;
	font-weight: 600;
}

/* Service Block Two */

.service-block-two {
	position: relative;
	margin-bottom: 30px;
}

.service-block-two .inner-box {
	position: relative;
	text-align: left;
	border-radius: 10px;
	padding: 50px 45px 60px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box .icon-one {
	position: absolute;
	left: 50%;
	top: 60px;
	width: 19px;
	height: 19px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box .icon-two {
	position: absolute;
	left: 30px;
	bottom: 30px;
	width: 19px;
	height: 19px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box .icon-three {
	position: absolute;
	right: 30px;
	bottom: 30px;
	width: 34px;
	height: 29px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box .icon-four {
	position: absolute;
	right: 30px;
	top: 30px;
	width: 29px;
	height: 39px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box:hover .icon-one,
.service-block-two .inner-box:hover .icon-two,
.service-block-two .inner-box:hover .icon-three,
.service-block-two .inner-box:hover .icon-four {
	opacity: 1;
}

.service-block-two .inner-box:hover {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.service-block-two .inner-box .color-layer {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 10px;
}

.service-block-two .inner-box .color-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #ffffff;
	transform: rotateY(90deg);
	border-radius: 8px 0px 0px 8px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block-two .inner-box .color-layer:after {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #ffffff;
	transform: rotateY(90deg);
	border-radius: 0px 8px 8px 0px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block-two .inner-box:hover .color-layer:before,
.service-block-two .inner-box:hover .color-layer:after {
	opacity: 1;
	transform: rotateY(0deg);
}

.service-block-two .inner-box .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 262px;
	height: 175px;
	border-radius: 8px;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box:hover .pattern-layer-one {
	opacity: 0;
}

.service-block-two .inner-box .pattern-layer-two {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 108px;
	height: 123px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box:hover .pattern-layer-two {
	opacity: 1;
}

.service-block-two .inner-box .icon-box {
	position: relative;
	color: #ffffff;
	z-index: 1;
	font-size: 60px;
	line-height: 1em;
	padding: 10px 10px;
	margin-bottom: 20px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	background: url(../images/icons/icon-5.png);
	background-position: left center;
	background-repeat: no-repeat;
}

.service-block-two .inner-box .icon-five {
	position: absolute;
	left: 0px;
	top: 22px;
	bottom: 0px;
	width: 74px;
	height: 56px;
	z-index: -1;
	opacity: 0;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box:hover .icon-five {
	opacity: 1;
}

.service-block-two .inner-box:hover .icon-box {
	color: #742bb9;
}

.service-block-two .inner-box:hover .icon-box {
	background: none;
}

.service-block-two .inner-box:hover h5 a {
	color: #222222;
}

.service-block-two .inner-box:hover .text {
	color: #777777;
}

.service-block-two .inner-box h5 {
	position: relative;
	line-height: 1.3em;
	font-weight: 600;
}

.service-block-two .inner-box h5 a {
	position: relative;
	color: #ffffff;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two .inner-box .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	line-height: 1.8em;
	margin-top: 10px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-two.style-two .inner-box {
	text-align: center;
}

.service-block-two.style-two .inner-box .icon-box {
	padding: 16px 10px;
	font-size: 50px;
	background: url(../images/icons/icon-18.png) no-repeat;
	background-position: center center;
}

.service-block-two.style-two .inner-box h5 a {
	color: #222222;
}

.service-block-two.style-two .inner-box .text {
	color: #555555;
}

.service-block-two.style-two .inner-box:hover .icon-box {
	color: #ffffff;
}

.service-block-two.style-two .inner-box:hover .text,
.service-block-two.style-two .inner-box:hover h5 a {
	color: #ffffff;
}

.service-block-two.style-two .inner-box .color-layer:before {
	background-color: #ff8c00;
	background: -webkit-linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
	background: -moz-linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
	background: linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
}

.service-block-two.style-two .inner-box .color-layer:after {
	background-color: #ff8c00;
	background: -webkit-linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
	background: -moz-linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
	background: linear-gradient(to bottom, #ff8c00 0%, #ff0200 100%);
}

/*** 

====================================================================
	Clients Section
====================================================================

***/

.clients-section {
	position: relative;
	padding: 30px 0px 90px;
	background-color: #f3f4ff;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav {
	position: relative;
	display: none;
}

.clients-section .sponsors-outer .image-box {
	position: relative;
	text-align: center;
	margin: 0px;
}

.clients-section .sponsors-outer .image-box img {
	max-width: 100%;
	width: auto;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.clients-section .sponsors-outer .image-box img:hover {
	opacity: 0.5;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.clients-section.style-two {
	padding-top: 20px;
	background-color: #ffffff;
}

.clients-section.style-three {
	padding-top: 110px;
}

/*** 

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action-section {
	position: relative;
	padding: 50px 0px 60px;
	background-color: #f3f4ff;
	background-position: center center;
	background-repeat: no-repeat;
}

.call-to-action-section.style-two {
	padding-top: 160px;
	margin-top: -100px;
}

.call-to-action-section .circle-layer {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 304px;
	height: 281px;
	z-index: 1;
	background-repeat: no-repeat;
}

.call-to-action-section .title-column {
	position: relative;
	z-index: 1;
	margin-bottom: 40px;
}

.call-to-action-section .title-column .inner-column {
	position: relative;
	padding-top: 100px;
}

.call-to-action-section .title-column h2 {
	position: relative;
	color: #222222;
	font-size: 44px;
	line-height: 1.3em;
	font-weight: 600;
}

.call-to-action-section .title-column .text {
	position: relative;
	color: #555555;
	font-size: 22px;
	line-height: 1.6em;
	margin-top: 25px;
	margin-bottom: 35px;
}

.call-to-action-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.call-to-action-section .image-column .inner-column {
	position: relative;
}

.call-to-action-section .image-column .image {
	position: relative;
	z-index: 1;
}

/*** 

====================================================================
	Counter Section
====================================================================

***/

.counter-section {
	position: relative;
}

.counter-section.style-two::before {
	display: none;
}

.counter-section.style-two .inner-container {
	z-index: 1;
	background: #151ab2;
	background: -webkit-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: -moz-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
}

.call-to-action-section .title-column .inner-column {
	padding-top: 60px;
}

.call-to-action-section.style-two .circle-layer {
	bottom: -80px;
	top: auto;
}

.counter-section:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	height: 50%;
	background-color: #f3f4ff;
}

.counter-section .inner-container {
	position: relative;
	padding: 55px 40px 20px;
	border-radius: 10px;
	background: #6917B4;
	background: -webkit-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: -moz-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: linear-gradient(to right, #6917B4 0%, #d54be1 100%);
}

/* Fact Counter */

.fact-counter {
	position: relative;
}

.fact-counter .column {
	position: relative;
	margin-bottom: 30px;
}

.fact-counter .column .inner {
	position: relative;
}

.fact-counter .column .inner .content {
	position: relative;
	text-align: center;
}

.fact-counter .column .inner .content:before {
	position: absolute;
	content: ':';
	right: -20px;
	top: 30px;
	color: #ffffff;
	font-size: 60px;
	font-weight: 700;
	border-radius: 50px;
}

.fact-counter .column:last-child .inner .content:before {
	display: none;
}

.fact-counter .column .inner .icon {
	position: absolute;
	left: 0px;
	top: 10px;
	color: #bbbbbb;
	font-size: 60px;
	text-align: center;
	line-height: 1em;
	font-weight: 400;
	margin-bottom: 0px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.fact-counter .count-outer .percentage {
	display: inline-block;
	font-weight: 600;
	color: #ffffff;
	font-size: 54px;
	line-height: 1em;
}

.fact-counter .column .inner .count-outer {
	position: relative;
	font-weight: 600;
	color: #ffffff;
	font-size: 54px;
	line-height: 1em;
	font-family: 'Poppins', sans-serif;
}

.fact-counter .column .inner .counter-title {
	position: relative;
	font-size: 18px;
	font-weight: 600;
	margin-top: 10px;
	color: #ffffff;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section {
	position: relative;
	padding: 100px 0px 100px;
}

.testimonial-section .icon-layer-one {
	position: absolute;
	left: 150px;
	top: 130px;
	width: 34px;
	height: 29px;
	background-repeat: no-repeat;
}

.testimonial-section .icon-layer-two {
	position: absolute;
	left: 100px;
	bottom: 280px;
	width: 51px;
	height: 65px;
	background-repeat: no-repeat;
}

.testimonial-section .icon-layer-three {
	position: absolute;
	right: 100px;
	top: 80px;
	width: 56px;
	height: 53px;
	background-repeat: no-repeat;
}

.testimonial-section .icon-layer-four {
	position: absolute;
	right: 180px;
	top: 50%;
	width: 57px;
	height: 50px;
	background-repeat: no-repeat;
}

.testimonial-section .icon-layer-five {
	position: absolute;
	right: 100px;
	bottom: 80px;
	width: 63px;
	height: 63px;
	background-repeat: no-repeat;
}

.testimonial-section .owl-carousel .owl-stage-outer {
	position: static;
	padding: 20px 0px;
}

.testimonial-section .owl-theme .testimonial-block {
	margin: 0px 30px;
}

.testimonial-section .owl-carousel {
	margin: 0px -30px;
	width: auto;
}

.testimonial-section .slick-slider,
.testimonial-section-two .slick-slider {
	margin: -15px;

}

.testimonial-section .slick-slide,
.testimonial-section-two .slick-slide {
	padding: 15px;
}

.testimonial-section .owl-nav {
	display: none;
}

.slick-dots {
	text-align: center;
}

.slick-dots li {
	width: unset;
	height: unset;
}

.slick-dots li button {
	border: none;
	margin: 0 5px;
	width: 15px;
	height: 6px;
	border-radius: 10px;
	background: #cccccc;
	padding: 0;
}

.slick-dots li button::before {
	display: none;
}

.slick-dots li.slick-active button {
	background: #ba0913;
	width: 25px;
}


.testimonial-section .owl-dots {
	position: relative;
	text-align: center;
	margin-top: 40px;
}

.testimonial-section .owl-dots .owl-dot {
	position: relative;
	width: 15px;
	height: 4px;
	margin: 0px 4px;
	display: inline-block;
	background-color: #cccccc;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.testimonial-section .owl-dots .owl-dot.active,
.testimonial-section .owl-dots .owl-dot:hover {
	width: 25px;
	background-color: #ba0913;
}

.testimonial-block {
	position: relative;
}

.testimonial-block .inner-box {
	position: relative;
	padding-top: 30px;
	padding-left: 45px;
	padding-right: 45px;
	text-align: center;
	padding-bottom: 45px;
}

.testimonial-block .inner-box:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 85px;
	right: 0px;
	bottom: 0px;
	border-radius: 8px;
	background-color: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.testimonial-block .inner-box .pattern-layer {
	position: absolute;
	left: 50%;
	top: 0px;
	width: 139px;
	height: 161px;
	z-index: -1;
	margin-left: -70px;
	background: url(../images/icons/testimonial-pattern.png) no-repeat;
}

.testimonial-block .inner-box .author-image-outer {
	position: relative;
}

.testimonial-block .inner-box .author-image-outer .author-image {
	position: relative;
	width: 112px;
	height: 112px;
	margin: 0 auto;
	border-radius: 50%;
	overflow: hidden;
}

.testimonial-block .inner-box .rating {
	position: relative;
	color: #ff5301;
	margin: 0px 2px;
	margin-top: 30px;
	font-size: 16px;
}

.testimonial-block .inner-box .rating .fa.dark {
	color: #777777;
}

.testimonial-block .inner-box .text {
	position: relative;
	color: #555555;
	margin-top: 15px;
	font-size: 16px;
	line-height: 1.8em;
	max-width: 350px;
	margin: 0 auto;
	margin-top: 15px;
}

.testimonial-block .inner-box .lower-box {
	position: relative;
	padding-top: 15px;
	margin-top: 25px;
	border-top: 2px solid #dddddd;
}

.testimonial-block .inner-box .lower-box .quote-icon {
	position: absolute;
	left: 0px;
	top: -2px;
	width: 40px;
	height: 40px;
	color: #ffffff;
	font-size: 20px;
	line-height: 40px;
	display: inline-block;
	background-color: #222222;
}

.testimonial-block .inner-box .lower-box h6 {
	position: relative;
	color: #222222;
	font-weight: 600;
}

.testimonial-block .inner-box .lower-box .designation {
	position: relative;
	color: #555555;
	font-weight: 400;
	font-size: 16px;
	margin-top: 8px;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section {
	position: relative;
	overflow: hidden;
	padding: 0px 0px 80px;
}

.team-section:before {
	position: absolute;
	content: '';
	left: -200px;
	right: -200px;
	bottom: -150px;
	height: 560px;
	background-color: #f3f4ff;
	transform: rotate(10deg);
}

.team-section .outer-container {
	position: relative;
	overflow: hidden;
	padding-bottom: 15px;
}

.team-section .outer-container .team-block {
	width: 20%;
	float: left;
	padding: 0px 15px;
}

.team-section .outer-container .team-block:nth-child(2) {
	margin-top: 50px;
}

.team-section .outer-container .team-block:nth-child(3) {
	margin-top: 100px;
}

.team-section .outer-container .team-block:nth-child(4) {
	margin-top: 150px;
}

.team-section .outer-container .team-block:nth-child(5) {
	margin-top: 200px;
}

.team-block {
	position: relative;
}

.team-block .inner-box {
	position: relative;
}

.team-block .inner-box .image {
	position: relative;
	overflow: hidden;
	border-radius: 0px 10px 0px 10px;
}

.team-block .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.team-block .inner-box .image .social-box {
	position: absolute;
	top: 10px;
	left: 0px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.team-block .inner-box:hover .image .social-box {
	left: 0px;
}

.team-block .inner-box .image .social-box li {
	position: relative;
	left: -80px;
	margin-bottom: 8px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.team-block .inner-box .image .social-box li a {
	position: relative;
	color: #ffffff;
	width: 55px;
	height: 40px;
	font-size: 18px;
	line-height: 40px;
	text-align: center;
	display: inline-block;
	background-color: #000000;
	border-radius: 0px 50px 50px 0px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.team-block .inner-box .image .social-box li a:hover {
	background-color: #1e00ff;
}

.team-block .inner-box:hover .image .social-box li:nth-child(1) {
	left: 0px;
}

.team-block .inner-box:hover .image .social-box li:nth-child(2) {
	left: 0px;
	-webkit-transition-delay: 200ms;
	-moz-transition-delay: 200ms;
	-ms-transition-delay: 200ms;
	-o-transition-delay: 200ms;
	transition-delay: 200ms;
}

.team-block .inner-box:hover .image .social-box li:nth-child(3) {
	left: 0px;
	-webkit-transition-delay: 400ms;
	-moz-transition-delay: 400ms;
	-ms-transition-delay: 400ms;
	-o-transition-delay: 400ms;
	transition-delay: 400ms;
}

.team-block .inner-box:hover .image .social-box li:nth-child(4) {
	left: 0px;
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.team-block .inner-box:hover .image .social-box li:nth-child(5) {
	left: 0px;
	-webkit-transition-delay: 800ms;
	-moz-transition-delay: 800ms;
	-ms-transition-delay: 800ms;
	-o-transition-delay: 800ms;
	transition-delay: 800ms;
}

.team-block .inner-box .lower-content {
	position: relative;
	padding: 30px 12px;
	text-align: center;
	margin-left: 30px;
	margin-right: 30px;
	border-radius: 10px;
	margin-top: -60px;
	background-color: #ffffff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.team-block .inner-box .lower-content:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 50%;
	opacity: 0;
	border-radius: 10px;
	background: #EA3368;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	background: -webkit-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: -moz-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
}

.team-block .inner-box:hover .lower-content:before {
	width: 100%;
	opacity: 1;
}

.team-block .inner-box .lower-content .content h4 {
	position: relative;
	font-weight: 600;
	line-height: 1.3em;
	font-size: 20px;
}

.team-block .inner-box .lower-content .content h4 a {
	position: relative;
	color: #222222;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.team-block .inner-box .lower-content .content .designation {
	position: relative;
	color: #555555;
	font-size: 16px;
	margin-top: 6px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.team-block .inner-box:hover .lower-content .content .designation,
.team-block .inner-box:hover .lower-content .content h4 a {
	color: #ffffff;
}

.team-block.style-two .inner-box .lower-content .content h4 {
	font-size: 24px;
}

/*** 

====================================================================
	Pricing Section
====================================================================

***/

.pricing-section {
	position: relative;
	padding: 0px 0px 100px;
	background-color: #f3f4ff;
}

.pricing-section.style-two {
	background: none;
	padding: 100px 0px 60px;
}

.pricing-section.style-three {
	padding: 100px 0px 60px;
}

.pricing-section .pattern-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-repeat: no-repeat;
	background-position: center center;
}

.pricing-section .pattern-layer-two {
	position: absolute;
	left: -40px;
	top: -150px;
	width: 342px;
	height: 281px;
	background-repeat: no-repeat;
}

.pricing-section .pricing-tabs .tab-content .price-block {
	transform: rotateY(90deg);
}

.pricing-section .pricing-tabs .tab-content .tab-pane.active .price-block {
	transform: rotateY(0deg);
}

.pricing-section .pricing-tabs .price-block:nth-child(3) .inner-box {
	border-color: #eb3967;
}

.pricing-section .pricing-tabs .price-block:nth-child(3) .inner-box .title {
	background: #151ab2;
	background: -webkit-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: -moz-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
}

.pricing-tabs {
	position: relative;
	z-index: 2;
}

.pricing-tabs .tabs-content .tab {
	position: relative;
	display: none;
}

.pricing-tabs .tabs-content .tab.active-tab {
	display: block;
}

.pricing-tabs .buttons-outer {
	text-align: center;
}

.pricing-tabs .tab-buttons {
	position: relative;
	overflow: hidden;
	text-align: center;
	border-radius: 5px;
	margin-top: 30px;
	display: inline-block;
	background-color: #ffffff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.pricing-tabs .tab-buttons .tab-btn {
	float: left;
}

.pricing-tabs .tab-buttons .tab-btn .nav-link {
	position: relative;
	color: #777777;
	font-size: 18px;
	font-weight: 400;
	cursor: pointer;
	padding: 16px 48px;
	border-radius: 5px;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.pricing-tabs .tab-buttons .tab-btn .nav-link:hover,
.pricing-tabs .tab-buttons .tab-btn .nav-link.active {
	color: #ffffff;
	background: #ff0300;
	background: -webkit-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
}

/* Price Block */

.price-block {
	position: relative;
	margin-bottom: 30px;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.price-block .inner-box {
	position: relative;
	text-align: center;
	padding: 50px 45px 85px;
	border-radius: 9px;
	overflow: hidden;
	border: 1px solid #4660dd;
	background-color: #ffffff;
}

.price-block .inner-box .price-pattern-layer {
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 44px;
	animation: 35s linear 0s infinite normal none running slide;
}

.price-block .inner-box .price-pattern-layer.style-two {
	height: 70px;
}

@keyframes slide {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 3000px 0;
	}
}

.price-block .inner-box .title {
	position: relative;
	font-size: 16px;
	color: #ffffff;
	z-index: 1;
	font-weight: 600;
	border-radius: 50px;
	display: inline-block;
	padding: 10px 30px;
	background: #ff0300;
	text-transform: capitalize;
	font-family: 'Poppins', sans-serif;
	background: -webkit-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
}

.price-block .inner-box .price {
	position: relative;
	font-size: 48px;
	font-weight: 600;
	color: #222222;
	line-height: 1em;
	margin-top: 30px;
	padding-bottom: 20px;
	border-bottom: 1px solid #bbbbbb;
	font-family: 'Poppins', sans-serif;
}

.price-block .inner-box .price-list {
	position: relative;
	margin-top: 35px;
	margin-bottom: 30px;
}

.price-block .inner-box .price-list li {
	position: relative;
	color: #555555;
	font-size: 16px;
	margin-bottom: 12px;
}

.price-block .inner-box .price-list li:last-child {
	margin-bottom: 0px;
}

.price-block.active .inner-box {
	border: none;
	background: #151ab2;
	background: -webkit-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: -moz-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
}

.price-block.active .inner-box .title {
	color: #4f1d99;
	background: -webkit-linear-gradient(to right, #ffffff 0%, #ffffff 100%);
	background: -moz-linear-gradient(to right, #ffffff 0%, #ffffff 100%);
	background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
}

.price-block.active .inner-box .price-list li,
.price-block.active .inner-box .price {
	color: #ffffff;
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section {
	position: relative;
	overflow: hidden;
	padding: 100px 0px 60px;
}

.news-section .sec-title .theme-btn {
	margin-top: 95px;
}

.news-section .pattern-layer {
	position: absolute;
	right: -50px;
	top: 280px;
	width: 304px;
	height: 281px;
	background-repeat: no-repeat;
}

.news-block {
	position: relative;
	margin-bottom: 30px;
}

.news-block .inner-box {
	position: relative;
}

.news-block .inner-box .image {
	position: relative;
}

.news-block .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.news-block .inner-box .image .overlay-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	color: #ffffff;
	text-align: center;
	background-color: rgba(235, 53, 103, 0.80);
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.news-block .inner-box:hover .overlay-box {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.news-block .inner-box .overlay-inner {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
	padding: 10px 0px;
}

.news-block .inner-box .overlay-inner .content {
	position: relative;
	display: table-cell;
	vertical-align: middle;
}

.news-block .inner-box .image .overlay-box .icon {
	position: relative;
	width: 60px;
	height: 60px;
	color: #eb3567;
	line-height: 60px;
	font-size: 18px;
	text-align: center;
	border-radius: 50px;
	font-weight: 700;
	display: inline-block;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .inner-box .image .overlay-box .icon:hover {
	background-color: #6615b2;
	color: #ffffff;
}

.news-block .inner-box .lower-content {
	position: relative;
	padding-top: 25px;
}

.news-block .inner-box .lower-content .post-meta {
	position: relative;
}

.news-block .inner-box .lower-content .post-meta li {
	position: relative;
	margin-right: 15px;
	display: inline-block;
}

.news-block .inner-box .lower-content .post-meta li a {
	position: relative;
	color: #555555;
	font-size: 14px;
	padding-left: 25px;
}

.news-block .inner-box .lower-content .post-meta li:last-child {
	margin-right: 0px;
}

.news-block .inner-box .lower-content .post-meta li a .icon {
	position: absolute;
	left: 0px;
	color: #c945dc;
}

.news-block .inner-box .lower-content h5 {
	position: relative;
	line-height: 1.6em;
	font-weight: 600;
	margin-top: 10px;
}

.news-block .inner-box .lower-content h5 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block .inner-box .lower-content h5 a:hover {
	color: #6615b2;
}

.news-block.style-two .inner-box .lower-content h5 a:hover {
	color: #ff0200;
}

.news-block.style-two .inner-box .lower-content .post-meta li a .icon {
	color: #161cb7;
}

.news-block.style-two .inner-box .image .overlay-box {
	background-color: rgba(255, 5, 0, 0.80);
}

.news-block.style-two .inner-box .image .overlay-box .icon {
	color: #ff0e00;
}

.news-block.style-two .inner-box .image .overlay-box .icon:hover {
	color: #ffffff;
	background-color: #ff8d00;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
	position: relative;
	padding: 200px 0px 0px;
	background: #6917B4;
	background: -webkit-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: -moz-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: linear-gradient(to right, #6917B4 0%, #d54be1 100%);
}

.main-footer.style-two {
	background: #2A327B;
	background: -webkit-linear-gradient(to right, #2A327B 0%, #068acf 100%);
	background: -moz-linear-gradient(to right, #2A327B 0%, #068acf 100%);
	background: linear-gradient(to right, #2A327B 0%, #068acf 100%);
}

.main-footer.style-two .list-style-two li .icon {
	color: #ffffff;
}

.main-footer.style-three {
	background: #151ab2;
	background: -webkit-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: -moz-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
}

.main-footer .pattern-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 106px;
	background-repeat: repeat-x;
}

.main-footer .pattern-layer-two {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 163px;
	height: 155px;
	background-repeat: no-repeat;
}

.main-footer .pattern-layer-three {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 156px;
	height: 131px;
	background-repeat: no-repeat;
}

.main-footer .waves {
	top: 0px;
}

.main-footer .footer-widget {
	position: relative;
	z-index: 1;
	margin-bottom: 40px;
}

.main-footer .widgets-section {
	position: relative;
	padding-bottom: 40px;
}

.main-footer .footer-widget h4 {
	position: relative;
	font-weight: 500;
	color: #ffffff;
	line-height: 1.2em;
	margin-bottom: 25px;
	padding-bottom: 20px;
	text-transform: capitalize;
}

.main-footer .footer-widget h4:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	height: 1px;
	width: 30px;
	background-color: #ffffff;
}

.main-footer .logo-widget {
	position: relative;
}

.main-footer .logo-widget .logo {
	position: relative;
	margin-bottom: 15px;
}

.main-footer .logo-widget .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.8em;
	margin-bottom: 25px;
}

.main-footer .logo-widget .social-box {
	position: relative;
}

.main-footer .logo-widget .social-box li {
	position: relative;
	margin-right: 15px;
	display: inline-block;
}

.main-footer .logo-widget .social-box li a {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .logo-widget .social-box li a:hover {
	color: #00baff;
}

.main-footer.style-three .list-link li a:hover {
	color: #00baff;
}

.main-footer.style-three .list-style-two li .icon {
	color: #ffffff;
}

/* List Link */

.list-link {
	position: relative;
}

.list-link li {
	position: relative;
	margin-bottom: 15px;
}

.list-link li a {
	position: relative;
	color: #ffffff;
	font-size: 14px;
	padding-left: 15px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.list-link li a:hover {
	color: #fe4c1c;
}

.list-link li a:before {
	position: absolute;
	content: "\f101";
	left: 0px;
	top: 0px;
	line-height: 1em;
	font-family: 'FontAwesome';
}

/*Gallery Widget*/

.main-footer .gallery-widget {
	position: relative;
}

.main-footer .gallery-widget .images-outer {
	position: relative;
	margin: 0px -3px;
}

.main-footer .gallery-widget .image-box {
	position: relative;
	float: left;
	width: 33.333%;
	padding: 0px 6px;
	margin-bottom: 12px;
}

.main-footer .gallery-widget .image-box img {
	position: relative;
	display: block;
	width: 100%;
	height: 90px;
	object-fit: cover;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-footer .gallery-widget .image-box img:hover {
	opacity: 0.70;
}

.main-footer .footer-bottom {
	position: relative;
	text-align: center;
	padding: 20px 0px;
	font-size: 15px;
	color: #ffffff;
	border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.main-footer .footer-bottom a {
	color: #ffffff;
}

/*** 

====================================================================
	Banner Section Two
====================================================================

***/

.banner-section-two {
	position: relative;
	overflow: hidden;
}

.banner-section-two .image-column {
	position: relative;
}

.banner-section-two .image-column .inner-column {
	position: relative;
}

.banner-section-two .image-column .image {
	position: relative;
	margin-left: -480px;
}

.banner-section-two .image-column .image img {
	position: relative;
	max-width: none;
}

.banner-section-two .image-column .image .play-box {
	position: absolute;
	right: 120px;
	bottom: 50px;
	width: 271px;
	height: 270px;
	z-index: 10;
	line-height: 250px;
	text-align: center;
	display: inline-block;
	background: url(../images/icons/play.png) center center no-repeat;
}

.banner-section-two .image-column .image .play-box .ripple,
.banner-section-two .image-column .image .play-box .ripple:before,
.banner-section-two .image-column .image .play-box .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 100px;
	border-radius: 50px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.banner-section-two .image-column .image .play-box .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.banner-section-two .image-column .image .play-box .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.banner-section-two .image-column .image .play-box .play-icon {
	position: relative;
	color: #ffffff;
	font-size: 46px;
	z-index: 1;
	padding-left: 40px;
}

.banner-section-two .content-column {
	position: relative;
}

.banner-section-two .content-column .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 155px;
	width: 225px;
	height: 39px;
	background-repeat: no-repeat;
}

.banner-section-two .content-column .pattern-layer-two {
	position: absolute;
	right: 0px;
	top: 155px;
	width: 465px;
	height: 671px;
	background-repeat: no-repeat;
}

.banner-section-two .content-column .pattern-layer-three {
	position: absolute;
	right: 100px;
	top: 155px;
	width: 59px;
	height: 54px;
	background-repeat: no-repeat;
}

.banner-section-two .content-column .pattern-layer-four {
	position: absolute;
	right: 50px;
	bottom: 285px;
	width: 69px;
	height: 69px;
	background-repeat: no-repeat;
}

.banner-section-two .content-column .pattern-layer-five {
	position: absolute;
	right: -100px;
	bottom: 0px;
	width: 320px;
	height: 367px;
	background-repeat: no-repeat;
}

.banner-section-two .content-column .inner-column {
	position: relative;
	padding-top: 260px;
}

.banner-section-two .content-column .title {
	position: relative;
	color: #555555;
	font-size: 24px;
	font-weight: 600;
}

.banner-section-two .content-column h1 {
	position: relative;
	color: #222222;
	font-weight: 700;
	line-height: 1.2em;
	margin-top: 20px;
}

.banner-section-two .content-column .text {
	position: relative;
	color: #555555;
	font-size: 18px;
	line-height: 1.8em;
	margin-top: 30px;
	margin-bottom: 30px;
}

.banner-section-two .content-column .theme-btn {
	float: left;
}

.banner-section-two .content-column .phone-box {
	position: relative;
	float: left;
	color: #fe4c1c;
	font-size: 24px;
	padding-top: 19px;
	margin-left: 30px;
	padding-left: 85px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-two .content-column .phone-box .phone-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	color: #222222;
	font-size: 26px;
	line-height: 58px;
	border-radius: 6px;
	text-align: center;
	display: inline-block;
	border: 1px solid #f64a1b;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-two .content-column .phone-box a {
	position: relative;
	color: #fe4c1c;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-two .content-column .phone-box:hover .phone-icon {
	color: #ffffff;
	background-color: #f64a1b;
}

/*** 

====================================================================
	Services Section Three
====================================================================

***/

.services-section-three {
	position: relative;
	padding: 60px 0px 60px;
}

.services-section-three .pattern-layer-one {
	position: absolute;
	left: 120px;
	top: 75px;
	width: 56px;
	height: 53px;
	background-repeat: no-repeat;
}

.services-section-three .pattern-layer-two {
	position: absolute;
	left: 110px;
	bottom: 75px;
	width: 57px;
	height: 50px;
	background-repeat: no-repeat;
}

.services-section-three .pattern-layer-three {
	position: absolute;
	right: 110px;
	bottom: 75px;
	width: 63px;
	height: 63px;
	background-repeat: no-repeat;
}

.services-section-three .blocks-column {
	position: relative;
	margin-bottom: 40px;
}

.services-section-three .blocks-column .inner-column {
	position: relative;
}

.services-section-three .blocks-column .service-block-three:nth-child(2n + 0) {
	transform: translateY(50px);
}

.service-block-three {
	position: relative;
	margin-bottom: 45px;
}

.service-block-three .inner-box {
	position: relative;
	padding: 35px 40px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: #ffffff;
}

.service-block-three .inner-box:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 0px 0px 30px 0px;
	box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-three .inner-box:hover::before {
	border-radius: 0px;
}

.service-block-three .inner-box .icon-box {
	position: relative;
	color: #ff9d12;
	font-size: 54px;
	line-height: 1em;
	background: url(../images/icons/shape-1.png) left center no-repeat;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block-three:nth-child(2) .inner-box .icon-box {
	color: #d84ce3;
	background: url(../images/icons/shape-2.png) left center no-repeat;
}

.service-block-three:nth-child(3) .inner-box .icon-box {
	color: #0028a3;
	background: url(../images/icons/shape-3.png) left center no-repeat;
}

.service-block-three:nth-child(4) .inner-box .icon-box {
	color: #bb0b15;
	background: url(../images/icons/shape-4.png) left center no-repeat;
}

.service-block-three .inner-box .icon-box .icon {
	position: relative;
}

.service-block-three .inner-box:hover .icon-box {}

.service-block-three .inner-box h5 {
	position: relative;
	font-size: 22px;
	font-weight: 600;
	line-height: 1.3em;
	margin-top: 18px;
	margin-bottom: 15px;
}

.service-block-three .inner-box h5 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-three .inner-box .text {
	position: relative;
	color: #222222;
	font-size: 15px;
	line-height: 1.7em;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-three .inner-box:hover h5 a,
.service-block-three .inner-box:hover .text {
	color: #222222;
}

.services-section-three .content-column {
	position: relative;
	margin-bottom: 40px;
}

.services-section-three .content-column .sec-title {
	margin-bottom: 25px;
}

.services-section-three .content-column .inner-column {
	position: relative;
	padding-top: 110px;
	padding-left: 50px;
}

.services-section-three .content-column .bold-text {
	position: relative;
	color: #555555;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.9em;
	margin-bottom: 20px;
}

.services-section-three .content-column .list-style-three {
	margin-bottom: 40px;
}

/*** 

====================================================================
	Counter Section Two
====================================================================

***/

.counter-section-two {
	position: relative;
}

.counter-section-two .inner-container:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 150%;
	height: 100%;
	background: url(../images/background/pattern-7.png) right top no-repeat;
}

.counter-section-two .inner-container {
	position: relative;
	padding: 110px 40px 80px;
}

/* Fact Counter */

.fact-counter-two {
	position: relative;
}

.fact-counter-two .column {
	position: relative;
	margin-bottom: 30px;
}

.fact-counter-two .column .inner {
	position: relative;
}

.fact-counter-two .column .inner .content {
	position: relative;
	text-align: left;
	padding-left: 80px;
}

.fact-counter-two .column .inner .icon {
	position: absolute;
	left: 0px;
	top: 5px;
	color: #ffb31c;
	font-size: 60px;
	text-align: center;
	line-height: 1em;
	font-weight: 400;
	margin-bottom: 0px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.fact-counter-two .column .inner:hover .icon {
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

.fact-counter-two .column:nth-child(2) .inner .icon {
	color: #3fa82d;
}

.fact-counter-two .column:nth-child(3) .inner .icon {
	color: #ff0000;
}

.fact-counter-two .column:nth-child(4) .inner .icon {
	color: #105ffe;
}

.fact-counter-two .count-outer .percentage {
	display: inline-block;
	font-weight: 600;
	color: #ffffff;
	font-size: 54px;
	line-height: 1em;
}

.fact-counter-two .column .inner .count-outer {
	position: relative;
	font-weight: 700;
	color: #6b17b4;
	font-size: 36px;
	line-height: 1em;
	font-family: 'Poppins', sans-serif;
}

.fact-counter-two .column .inner .counter-title {
	position: relative;
	font-size: 18px;
	font-weight: 600;
	margin-top: 10px;
	color: #222222;
}

/*** 

====================================================================
	Services Section Four
====================================================================

***/

.services-section-four {
	position: relative;
	padding: 110px 0px 80px;
}

.services-section-four .owl-carousel .owl-stage-outer {
	position: static;
	padding: 45px 0px;
}

.services-section-four .owl-theme .service-block-four {
	margin: 0px 15px;
}

.services-section-four .owl-carousel {
	margin: 0px -15px;
	width: auto;
}

.services-section-four .owl-nav {
	display: none;
}

.services-section-four .owl-dots {
	position: relative;
	text-align: center;
	margin-top: 40px;
}

.services-section-four .slick-slider {
	margin: -15px;

}

.services-section-four .slick-slide {
	padding: 15px;
}

.services-section-four .slick-dots {
	text-align: center;
}

.services-section-four .slick-dots li {
	width: unset;
	height: unset;
}

.services-section-four .slick-dots li button {
	border: none;
	margin: 0 5px;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background: #cccccc;
	padding: 0;
}

.services-section-four .slick-dots li button::before {
	display: none;
}

.services-section-four .slick-dots li.slick-active button {
	background: #ba0913;
	width: 25px;
}

.services-section-four .owl-dots .owl-dot {
	position: relative;
	width: 8px;
	height: 8px;
	margin: 0px 4px;
	border-radius: 50px;
	display: inline-block;
	background-color: #fe4c1c;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.services-section-four .owl-dots .owl-dot.active,
.services-section-four .owl-dots .owl-dot:hover {
	width: 30px;
	background-color: #222222;
}

.services-section-four .pattern-layer-one {
	position: absolute;
	right: 110px;
	top: 200px;
	width: 51px;
	height: 65px;
	background-repeat: no-repeat;
}

.services-section-four .pattern-layer-two {
	position: absolute;
	left: 110px;
	top: 300px;
	width: 51px;
	height: 65px;
	background-repeat: no-repeat;
}

.service-block-four {
	position: relative;
	margin-bottom: 30px;
}

.service-block-four .inner-box {
	position: relative;
	text-align: left;
	border-radius: 0px;
	padding: 50px 45px 60px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	background: #D84DE2;
	background: -webkit-linear-gradient(to bottom, #D84DE2 0%, #6815b2 100%);
	background: -moz-linear-gradient(to bottom, #D84DE2 0%, #6815b2 100%);
	background: linear-gradient(to bottom, #D84DE2 0%, #6815b2 100%);
}

.service-block-four .inner-box .arrow-icon {
	position: absolute;
	left: 40px;
	bottom: -25px;
	width: 50px;
	height: 50px;
	color: #ffffff;
	font-size: 18px;
	line-height: 44px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	border: 3px solid #ffffff;
	background: #FE8B57;
	opacity: 0;
	transform: translateY(-25px);
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.10);
	background: -webkit-linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	background: -moz-linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	background: linear-gradient(to bottom, #FE8B57 0%, #ea3368 100%);
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .arrow-icon,
.service-block-four .inner-box:hover .arrow-icon {
	transform: translateY(0px);
	opacity: 1;
}

.service-block-four .inner-box .icon-one {
	position: absolute;
	left: 50%;
	top: 60px;
	width: 19px;
	height: 19px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four .inner-box .icon-two {
	position: absolute;
	left: 30px;
	bottom: 30px;
	width: 19px;
	height: 19px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four .inner-box .icon-three {
	position: absolute;
	right: 30px;
	bottom: 30px;
	width: 34px;
	height: 29px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four .inner-box .icon-four {
	position: absolute;
	right: 30px;
	top: 30px;
	width: 29px;
	height: 39px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four .inner-box:hover .icon-one,
.service-block-four .inner-box:hover .icon-two,
.service-block-four .inner-box:hover .icon-three,
.service-block-four .inner-box:hover .icon-four,
.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-one,
.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-two,
.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-three,
.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-four {
	opacity: 1;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box,
.service-block-four .inner-box:hover {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.service-block-four .inner-box .color-layer {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 0px;
}

.service-block-four .inner-box .color-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #ffffff;
	transform: rotateY(90deg);
	border-radius: 0px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.service-block-four .inner-box .color-layer:after {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 50%;
	height: 100%;
	opacity: 0;
	background: #ffffff;
	transform: rotateY(90deg);
	border-radius: 0px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .color-layer:before,
.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .color-layer:after,
.service-block-four .inner-box:hover .color-layer:before,
.service-block-four .inner-box:hover .color-layer:after {
	opacity: 1;
	transform: rotateY(0deg);
}

.service-block-four .inner-box .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 262px;
	height: 175px;
	border-radius: 8px;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .pattern-layer-one,
.service-block-four .inner-box:hover .pattern-layer-one {
	opacity: 0;
}

.service-block-four .inner-box .pattern-layer-two {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 108px;
	height: 123px;
	opacity: 0;
	background-repeat: no-repeat;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .pattern-layer-two,
.service-block-four .inner-box:hover .pattern-layer-two {
	opacity: 1;
}

.service-block-four .inner-box .icon-box {
	position: relative;
	color: #ffffff;
	z-index: 1;
	font-size: 60px;
	line-height: 1em;
	padding: 10px 10px;
	margin-bottom: 20px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	background: url(../images/icons/icon-5.png);
	background-position: left center;
	background-repeat: no-repeat;
}

.service-block-four .inner-box .icon-five {
	position: absolute;
	left: 0px;
	top: 22px;
	bottom: 0px;
	width: 74px;
	height: 56px;
	z-index: -1;
	opacity: 0;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-five,
.service-block-four .inner-box:hover .icon-five {
	opacity: 1;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-box,
.service-block-four .inner-box:hover .icon-box {
	color: #2346c0;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .icon-box,
.service-block-four .inner-box:hover .icon-box {
	background: none;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box h5 a,
.service-block-four .inner-box:hover h5 a {
	color: #222222;
}

.services-section-four .slick-slide.slick-active.slick-center .service-block-four .inner-box .text,
.service-block-four .inner-box:hover .text {
	color: #777777;
}

.service-block-four .inner-box h5 {
	position: relative;
	line-height: 1.3em;
	font-weight: 600;
}

.service-block-four .inner-box h5 a {
	position: relative;
	color: #ffffff;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four .inner-box .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	line-height: 1.8em;
	margin-top: 10px;
	transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}

.service-block-four.style-two .inner-box {
	background: #4b67e1;
	background: -webkit-linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
	background: -moz-linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
	background: linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
}

.service-block-four.style-two .inner-box .arrow-icon {
	background: #4b67e1;
	background: -webkit-linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
	background: -moz-linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
	background: linear-gradient(to bottom, #4b67e1 0%, #022aa4 100%);
}

.services-section-four.style-two .owl-carousel .owl-stage-outer .owl-item.center .service-block-four.style-two .inner-box .icon-box,
.service-block-four.style-two .inner-box:hover .icon-box {
	color: #2346c0;
}

/*** 

====================================================================
	Call To Action Section Two
====================================================================

***/

.call-to-action-section-two {
	position: relative;
	padding: 160px 0px 240px;
	background-color: #0028a2;
	background-position: center center;
	background-repeat: no-repeat;
}

.call-to-action-section-two .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: -1px;
	right: 0px;
	height: 74px;
	background-position: center center;
	background-repeat: repeat-x;
	background: url('../images/background/pattern-22.png');
}

.call-to-action-section-two.s2 .pattern-layer-one {
	background: url('../images/background/pattern-8.png');
}

.call-to-action-section-two .pattern-layer-two {
	position: absolute;
	left: 0px;
	bottom: -1px;
	right: 0px;
	height: 74px;
	background-position: center center;
	background-repeat: repeat-x;
}

.call-to-action-section-two .title-column {
	position: relative;
	margin-bottom: 40px;
}

.call-to-action-section-two .title-column .inner-column {
	position: relative;
	padding-top: 100px;
}

.call-to-action-section-two .title-column h2 {
	position: relative;
	color: #ffffff;
	font-size: 44px;
	line-height: 1.3em;
	font-weight: 600;
}

.call-to-action-section-two .title-column .text {
	position: relative;
	color: #ffffff;
	font-size: 22px;
	line-height: 1.6em;
	margin-top: 25px;
	margin-bottom: 35px;
}

.call-to-action-section-two .image-column {
	position: relative;
	margin-bottom: 40px;
}

.call-to-action-section-two .image-column .inner-column {
	position: relative;
}

.call-to-action-section-two .image-column .image {
	position: relative;
	z-index: 1;
}

/*** 

====================================================================
	Clients Section Two
====================================================================

***/

.clients-section-two {
	position: relative;
}

.clients-section-two.margin-top {
	margin-top: -180px;
	padding: 0;
	background: none;
}

.clients-section-two .inner-container {
	position: relative;
	padding: 60px 30px;
	border-radius: 10px;
	background-color: #f5f5f5;
}

.clients-section-two .sponsors-outer .owl-dots,
.clients-section-two .sponsors-outer .owl-nav {
	position: relative;
	display: none;
}

.clients-section-two .sponsors-outer .image-box {
	position: relative;
	text-align: center;
	margin: 0px;
}

.clients-section-two .sponsors-outer .image-box img {
	max-width: 100%;
	width: auto;
	opacity: 0.5;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.clients-section-two .sponsors-outer .image-box img:hover {
	opacity: 1;
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}

/*** 

====================================================================
	Gallery Section
====================================================================

 ***/

.gallery-section {
	position: relative;
	overflow: hidden;
	padding: 90px 0px 110px;
}

.gallery-section .pattern-layer {
	position: absolute;
	right: -50px;
	top: 30%;
	width: 304px;
	height: 281px;
	background-repeat: no-repeat;
}

.gallery-section .btn-box {
	margin-top: 40px;
}

.gallery-section .filters {
	margin-top: 50px;
}

.gallery-section .filters .filter-tabs {
	position: relative;
}

.gallery-section .filters li {
	position: relative;
	display: inline-block;
	color: #222222;
	padding: 0px 0px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	margin-left: 3px;
	margin-bottom: 4px;
	padding: 8px 20px;
	border-radius: 2px;
	display: inline-block;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.gallery-section .filters .filter.active,
.gallery-section .filters .filter:hover {
	color: #0028a2;
	background-color: #e6e6e6;
}

/* Gallery Block */

.gallery-block {
	position: relative;
	margin-bottom: 30px;
}

.gallery-block .inner-box {
	position: relative;
}

.gallery-block .inner-box .image-box {
	position: relative;
}

.gallery-block .inner-box .image-box img {
	position: relative;
	width: 100%;
	display: block;
}

.gallery-block .inner-box .image-box .overlay-box {
	position: absolute;
	left: 20px;
	top: 20px;
	right: 20px;
	bottom: 20px;
	color: #ffffff;
	text-align: center;
	background-color: rgba(222, 222, 222, 0.80);
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.gallery-block .inner-box:hover .overlay-box {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.gallery-block .inner-box .overlay-inner {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
	padding: 10px 0px;
}

.gallery-block .inner-box .overlay-inner .content {
	position: relative;
	display: table-cell;
	vertical-align: middle;
}

.gallery-block .inner-box .image-box .overlay-box .icon {
	position: relative;
	width: 40px;
	height: 40px;
	color: #ffffff;
	line-height: 40px;
	font-size: 14px;
	text-align: center;
	border-radius: 0px;
	font-weight: 700;
	display: inline-block;
	background-color: #0028a2;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-block .inner-box .image-box .overlay-box .icon:hover {
	background-color: #fe4c1c;
	color: #ffffff;
}

.gallery-block .inner-box .lower-content {
	position: relative;
	padding-top: 10px;
}

.gallery-block .inner-box .lower-content .title {
	position: relative;
	color: #fe4c1c;
	font-size: 16px;
}

.gallery-block .inner-box .lower-content h5 {
	position: relative;
	font-weight: 600;
	line-height: 1.3em;
	margin-top: 8px;
}

.gallery-block .inner-box .lower-content h5 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.gallery-block .inner-box .lower-content h5 a:hover {
	color: #0028a2;
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

 ***/

.testimonial-section-two {
	position: relative;
	overflow: hidden;
	padding: 120px 0px 130px;
}

.testimonial-section-two .slick-dots {
	bottom: -40px;
}

.testimonial-section-two .pattern-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.testimonial-section-two .owl-nav {
	display: none;
}

.testimonial-section-two .owl-dots {
	position: relative;
	text-align: center;
	margin-top: 50px;
}

.testimonial-section-two .owl-dots .owl-dot {
	position: relative;
	width: 15px;
	height: 4px;
	margin: 0px 4px;
	display: inline-block;
	background-color: #cccccc;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.testimonial-section-two .owl-dots .owl-dot.active,
.testimonial-section-two .owl-dots .owl-dot:hover {
	width: 30px;
	background-color: #ba0913;
}

.testimonial-section-two .owl-carousel .owl-stage-outer {
	padding-bottom: 10px;
}

.testimonial-block-two {
	position: relative;
}

.testimonial-block-two .inner-box {
	position: relative;
	padding: 50px 40px;
	background-color: #f5f5f5;
	border-radius: 16px 16px 0px 0px;
}


.testimonial-block-two .inner-box:before {
	position: absolute;
	content: '';
	left: 35px;
	right: 35px;
	bottom: -8px;
	height: 8px;
	background: #0028A4;
	background: -webkit-linear-gradient(to right, #0028A4 0%, #003ffd 100%);
	background: -moz-linear-gradient(to right, #0028A4 0%, #003ffd 100%);
	background: linear-gradient(to right, #0028A4 0%, #003ffd 100%);
}

.testimonial-block-two .inner-box .author-image-outer {
	position: relative;
	padding-left: 90px;
	padding-top: 5px;
}

.testimonial-block-two .inner-box .author-image-outer .author-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
}

.testimonial-block-two .inner-box .author-image-outer h6 {
	position: relative;
	color: #222222;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.3em;
}

.testimonial-block-two .inner-box .author-image-outer .designation {
	position: relative;
	color: #555555;
	font-size: 16px;
	margin-top: 6px;
}

.testimonial-block-two .inner-box .author-image-outer .quote-icon {
	position: absolute;
	right: 0px;
	top: 5px;
	color: #dbdbdb;
	line-height: 1em;
	font-size: 40px;
}

.testimonial-block-two .inner-box .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	text-align: center;
	line-height: 1.8em;
	margin-top: 35px;
	padding: 0px 25px;
}

.testimonial-block-two .inner-box .rating {
	position: relative;
	text-align: center;
	color: #ff5301;
	font-size: 16px;
	margin-top: 18px;
}

/*** 

====================================================================
	Faq's Section Two
====================================================================

 ***/

.faq-section {
	position: relative;
	padding: 80px 0px 60px;
}

.faq-section .accordian-column {
	position: relative;
	margin-bottom: 40px;
}

.faq-section .accordian-column .inner-column {
	position: relative;
}

.faq-section .accordian-column .sec-title {
	margin-bottom: 35px;
}

.accordion-box {
	position: relative;
}

.accordion-box .block {
	position: relative;
	margin-bottom: 18px;
	border-radius: 8px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.accordion-box .block .MuiAccordionSummary-content {
	display: block;
}

.accordion-box .block .MuiAccordionSummary-content p {
	position: relative;
	font-size: 16px;
	line-height: 35px;
	color: #000000;
	line-height: 1.8em;
	font-weight: 600;
	cursor: pointer;
	padding: 8px 20px;
	padding-right: 45px;
	margin-bottom: 0px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	margin-bottom: 0;
}

.accordion-box .block .acc-btn.active {
	color: #1c17a0;
	margin-bottom: 0px;
}

.accordion-box .block .icon {
	position: absolute;
	right: 10px;
	top: 6px;
	height: 35px;
	width: 35px;
	font-size: 20px;
	line-height: 35px;
	color: #6b18b4;
	text-align: center;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.accordion-box .block .MuiAccordionSummary-content.Mui-expanded .icon {
	color: #dd4ee5 !important;
	transform: rotate(90deg);
}

.accordion-box .block .acc-content {
	position: relative;
	display: none;
}

.accordion-box .block .MuiAccordionDetails-root {
	position: relative;
	padding: 0px 30px 25px;
}

.accordion-box .block .acc-content.current {
	display: block;
}

.accordion-box .block .MuiTypography-root {
	display: block;
	font-size: 16px;
	line-height: 1.8em;
	color: #777777;
}

.faq-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.faq-section .image-column .inner-column {
	position: relative;
}

/*** 

====================================================================
	News Section Two
====================================================================

 ***/

.news-section-two {
	position: relative;
	padding: 90px 0px 80px;
}

.news-section-two.style-two {
	background-color: #e9efff;
}

.news-section-two .pattern-layer-two {
	background: url('../images/background/pattern-24.png');
}

.news-section-two .pattern-layer-two.s2 {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	background: url('../images/background/pattern-11.jpg');
	height: 100%;
	background-position: left top;
	background-repeat: repeat;
	animation: 85s linear 0s infinite normal none running slide;
}

.news-section-two.style-two .pattern-layer-two {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background-position: left bottom;
	background-repeat: no-repeat;
}

.news-block-two {
	position: relative;
	margin-bottom: 30px;
}

.news-block-two .inner-box {
	position: relative;
	text-align: center;
	background-color: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.news-block-two .inner-box .image {
	position: relative;
}

.news-block-two .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.news-block-two .inner-box .image .overlay-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	color: #ffffff;
	text-align: center;
	background-color: rgba(235, 53, 103, 0.80);
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transform: scale(0, 1);
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
}

.news-block-two .inner-box:hover .overlay-box {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	transform: scale(1, 1);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.news-block-two .inner-box .overlay-inner {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
	padding: 10px 0px;
}

.news-block-two .inner-box .overlay-inner .content {
	position: relative;
	display: table-cell;
	vertical-align: middle;
}

.news-block-two .inner-box .image .overlay-box .icon {
	position: relative;
	width: 60px;
	height: 60px;
	color: #eb3567;
	line-height: 60px;
	font-size: 18px;
	text-align: center;
	border-radius: 50px;
	font-weight: 700;
	display: inline-block;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .inner-box .image .overlay-box .icon:hover {
	background-color: #6615b2;
	color: #ffffff;
}

.news-block-two .inner-box .lower-content {
	position: relative;
	padding: 30px 30px;
}

.news-block-two .inner-box .lower-content .post-meta {
	position: relative;
	text-align: left;
}

.news-block-two .inner-box .lower-content .post-meta li {
	position: relative;
	margin-right: 15px;
	display: inline-block;
}

.news-block-two .inner-box .lower-content .post-meta li a {
	position: relative;
	color: #555555;
	font-size: 14px;
	padding-left: 25px;
}

.news-block-two .inner-box .lower-content .post-meta li:last-child {
	margin-right: 0px;
}

.news-block-two .inner-box .lower-content .post-meta li a .icon {
	position: absolute;
	left: 0px;
	color: #c945dc;
}

.news-block-two .inner-box .lower-content h5 {
	position: relative;
	line-height: 1.6em;
	font-weight: 600;
	margin-top: 12px;
	text-align: left;
	margin-bottom: 10px;
}

.news-block-two .inner-box .lower-content h5 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-two .inner-box .lower-content h5 a:hover {
	color: #6615b2;
}

.news-block-two .inner-box .lower-content .text {
	position: relative;
	text-align: left;
	color: #777777;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 25px;
}

.news-block-two .inner-box .social-boxed {
	position: relative;
	display: inline-block;
}

.news-block-two .inner-box .social-boxed:hover .social-inner {
	width: 100%;
}

.news-block-two .inner-box .social-boxed .social-inner {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	border-radius: 7px;
	display: inline-block;
	padding: 10px 32px 12px;
	width: 38%;
	max-width: 100%;
	height: 40px;
	overflow: hidden;
	background: #6917B4;
	background: -webkit-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: -moz-linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	background: linear-gradient(to right, #6917B4 0%, #d54be1 100%);
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.news-block-two .inner-box .social-boxed .social-inner a {
	position: relative;
	color: #ffffff;
	margin: 0px 8px 3px;
	font-size: 16px;
	float: left;
}

.news-block-two .inner-box .social-boxed .social-inner .share-now {
	position: relative;
	margin-right: 8px;
	margin-top: 3px;
	margin-bottom: 10px;
	float: left;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.news-block-two.style-two .inner-box .lower-content .post-meta li a .icon {
	color: #0029a5;
}

.news-block-two.style-two .inner-box .social-boxed .social-inner {
	margin-top: 10px;
	background: #ff0300;
	text-transform: capitalize;
	background: -webkit-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
}

/*** 

====================================================================
	SEO Section
====================================================================

 ***/

.seo-section {
	position: relative;
	padding: 90px 0px 60px;
}

.seo-section .seo-image {
	position: relative;
	text-align: center;
	margin-bottom: 60px;
}

.seo-section .seo-form {
	max-width: 780px;
	margin: 0 auto;
}

.seo-section .pattern-layer-one {
	position: absolute;
	left: 120px;
	top: 75px;
	width: 56px;
	height: 53px;
	background-repeat: no-repeat;
}

.seo-section .pattern-layer-two {
	position: absolute;
	left: 110px;
	bottom: 75px;
	width: 57px;
	height: 50px;
	background-repeat: no-repeat;
}

.seo-section .pattern-layer-three {
	position: absolute;
	right: 110px;
	bottom: 75px;
	width: 63px;
	height: 63px;
	background-repeat: no-repeat;
}

/* SEO Form */

.seo-form {
	position: relative;
}

.seo-form .form-group {
	position: relative;
	margin-right: 80px;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.seo-form .form-group input {
	position: relative;
	height: 80px;
	width: 50%;
	float: left;
	color: #848484;
	font-size: 14px;
	padding: 8px 25px;
	background-color: #ffffff;
}

.seo-form .form-group:before {
	position: absolute;
	content: '';
	left: 50%;
	top: 20px;
	bottom: 20px;
	width: 1px;
	z-index: 1;
	margin-left: -5px;
	background-color: #bbbbbb;
}

.seo-form .theme-btn {
	position: absolute;
	right: 0px;
	top: 10px;
	cursor: pointer;
}

/* SEO Form Two */

.seo-form-two {
	position: relative;
}

.seo-form-two .form-group {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.seo-form-two .form-group input {
	position: relative;
	height: 80px;
	width: 50%;
	float: left;
	color: #848484;
	font-size: 14px;
	padding: 8px 25px;
	background-color: #ffffff;
}

.seo-form-two .form-group:before {
	position: absolute;
	content: '';
	left: 50%;
	top: 20px;
	bottom: 20px;
	width: 1px;
	z-index: 1;
	margin-left: -5px;
	background-color: #bbbbbb;
}

.seo-form-two .theme-btn {
	position: relative;
	cursor: pointer;
	margin-top: 15px;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.footer-style-two {
	position: relative;
	padding: 100px 0px 0px;
	background-position: center top;
	background-repeat: no-repeat;
}

.footer-style-two .footer-widget {
	position: relative;
	z-index: 1;
	margin-bottom: 40px;
}

.footer-style-two .widgets-section {
	position: relative;
	padding-bottom: 40px;
}

.footer-style-two .footer-widget h4 {
	position: relative;
	font-weight: 600;
	color: #222222;
	font-size: 18px;
	line-height: 1.2em;
	margin-bottom: 25px;
	text-transform: capitalize;
}

.footer-style-two .logo-widget {
	position: relative;
}

.footer-style-two .logo-widget .logo {
	position: relative;
	margin-bottom: 15px;
}

.footer-style-two .logo-widget .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.8em;
}

.footer-style-two .app-widget .app-btns a img {
	width: 100px;
	display: inline-block;
}

.footer-style-two .app-widget .social-box {
	position: relative;
	margin-top: 20px;
}

.footer-style-two .app-widget .social-box li {
	position: relative;
	margin-right: 15px;
	display: inline-block;
}

.footer-style-two .app-widget .social-box li.follow {
	display: block;
	color: #222222;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 12px;
}

.footer-style-two .app-widget .social-box li a {
	position: relative;
	color: #ea3468;
	font-size: 16px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.footer-style-two .app-widget .social-box li a:hover {
	color: #222222;
}

/* List Link */

.list-link-two {
	position: relative;
}

.list-link-two li {
	position: relative;
	margin-bottom: 10px;
	margin-right: -10px;
}

.list-link-two li a {
	position: relative;
	color: #555555;
	font-size: 15px;
	padding-left: 15px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.list-link-two li a:hover {
	color: #fe4c1c;
}

.list-link-two li a:before {
	position: absolute;
	content: "\f101";
	left: 0px;
	top: 0px;
	line-height: 1em;
	font-family: 'FontAwesome';
}

.footer-style-two .footer-bottom {
	position: relative;
	padding: 20px 0px;
	font-size: 15px;
	color: #ffffff;
	background-color: #0029a6;
}

.footer-style-two .footer-bottom a {
	color: #ffffff;
}

.footer-style-two .footer-bottom .nav-column {
	text-align: right;
}

.footer-style-two .footer-bottom .nav-column ul li {
	position: relative;
	margin-left: 35px;
	display: inline-block;
}

.footer-style-two .footer-bottom .nav-column ul li a {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.footer-style-two .footer-bottom .nav-column ul li a:hover {
	color: #eb3867;
}

.contact-info-widget ul li {
	position: relative;
	color: #555555;
	font-size: 16px;
	margin-bottom: 10px;
	line-height: 1.7em;
}

.contact-info-widget ul li a {
	position: relative;
	color: #555555;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info-widget ul li a:hover {
	color: #0a15a9;
}

/*** 

====================================================================
	Banner Section Three
====================================================================

***/

.banner-section-three {
	position: relative;
	overflow: hidden;
	background: #2A327B;
	padding-top: 260px;
	padding-bottom: 180px;
	background: -webkit-linear-gradient(to right, #2A327B 0%, #068acf 100%);
	background: -moz-linear-gradient(to right, #2A327B 0%, #068acf 100%);
	background: linear-gradient(to right, #2A327B 0%, #068acf 100%);
}

.banner-section-three .social-box {
	position: absolute;
	left: 3%;
	top: 25%;
	z-index: 10;
}

.banner-section-three .social-box li {
	position: relative;
	margin-bottom: 10px;
}

.banner-section-three .social-box li a {
	position: relative;
	width: 50px;
	height: 50px;
	color: #ffffff;
	font-size: 18px;
	line-height: 50px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-three .social-box li a:hover {
	color: #ffffff;
	background-color: #eb3667;
}

.banner-section-three .icon-layer-one {
	position: absolute;
	left: 140px;
	top: 100px;
	width: 93px;
	height: 71px;
	background-repeat: no-repeat;
}

.banner-section-three .icon-layer-two {
	position: absolute;
	left: 120px;
	bottom: 150px;
	width: 69px;
	height: 66px;
	background-repeat: no-repeat;
}

.banner-section-three .icon-layer-three {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
}

.banner-section-three .image-column {
	position: relative;
}

.banner-section-three .image-column .inner-column {
	position: relative;
}

.banner-section-three .image-column .image {
	position: relative;
	margin-right: -270px;
}

.banner-section-three .image-column .image img {
	position: relative;
}

.banner-section-three .content-column {
	position: relative;
}

.banner-section-three .content-column .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 155px;
	width: 225px;
	height: 39px;
	background-repeat: no-repeat;
}

.banner-section-three .content-column .pattern-layer-two {
	position: absolute;
	right: 0px;
	top: 155px;
	width: 465px;
	height: 671px;
	background-repeat: no-repeat;
}

.banner-section-three .content-column .inner-column {
	position: relative;
	padding-top: 80px;
}

.banner-section-three .content-column h1 {
	position: relative;
	color: #ffffff;
	font-weight: 700;
	line-height: 1.2em;
	margin-top: 20px;
}

.banner-section-three .content-column .text {
	position: relative;
	color: #ffffff;
	font-size: 18px;
	line-height: 1.8em;
	margin-top: 30px;
	margin-bottom: 35px;
}

.banner-section-three .content-column .theme-btn {
	float: left;
	margin-left: 40px;
}

.banner-section-three .content-column .video-box {
	position: relative;
	float: left;
	color: #ffffff;
	font-size: 18px;
	padding-top: 22px;
	padding-left: 100px;
	text-transform: uppercase;
}

.video-box,
.flaticon-play {
	cursor: pointer;
}

.banner-section-three .content-column .video-box .fa-play {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 70px;
	height: 70px;
	color: #ffffff;
	text-align: center;
	line-height: 50px;
	border-radius: 50px;
	border: 10px solid #ffffff;
	background-color: #0000ff;
}

.banner-section-three .content-column .video-box .fa-play .ripple,
.banner-section-three .content-column .video-box .fa-play .ripple:before,
.banner-section-three .content-column .video-box .fa-play .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 60px;
	width: 60px;
	margin-left: .5px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.banner-section-three .content-column .video-box .fa-play .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.banner-section-three .content-column .video-box .fa-play .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

/*** 

====================================================================
	Mission Section
====================================================================

***/

.mission-section {
	position: relative;
	margin-top: -80px;
	padding: 0px 0px 60px;
}

.mission-section.style-two {
	padding-top: 180px;
}

.mission-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.mission-section .image-column .inner-column {
	position: relative;
}

.mission-section .image-column .image {
	position: relative;
}

.mission-section .image-column .image img {
	position: relative;
}

.mission-section .content-column {
	position: relative;
	margin-bottom: 40px;
}

.mission-section .content-column .inner-column {
	position: relative;
}

.mission-section .content-column .bold-text {
	position: relative;
	color: #555555;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.8em;
	margin-bottom: 35px;
}

.mission-section .content-column .sec-title {
	margin-bottom: 20px;
}

.mission-section .content-column .mission-list {
	position: relative;
}

.mission-section .content-column .mission-list li {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.9em;
	padding-left: 90px;
	margin-bottom: 30px;
}

.mission-section .content-column .mission-list li .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
}

.mission-section .content-column .mission-list li:hover .icon {
	-webkit-transform: scale(-1) rotate(180deg);
	-moz-transform: scale(-1) rotate(180deg);
	-ms-transform: scale(-1) rotate(180deg);
	-o-transform: scale(-1) rotate(180deg);
	transform: scale(-1) rotate(180deg);
}

/*** 

====================================================================
	Services Section Five
====================================================================

***/

.services-section-five {
	position: relative;
	padding: 0px 0px 60px;
}

.services-section-five .owl-carousel .owl-stage-outer {
	position: static;
	padding: 45px 0px;
}

.services-section-five .owl-theme .service-block-five {
	margin: 0px 15px;
}

.services-section-five .owl-carousel {
	margin: 0px -15px;
	width: auto;
}

.services-section-five .owl-nav {
	display: none;
}


.services-section-five .slick-slider {
	margin: -15px;
}

.services-section-five .slick-slide {
	padding: 15px;
}

.services-section-five .owl-dots .owl-dot.active,
.services-section-five .owl-dots .owl-dot:hover {
	width: 30px;
	background-color: #222222;
}

.service-block-five {
	position: relative;
	margin-bottom: 30px;
}

.service-block-five .inner-box {
	position: relative;
	text-align: center;
	border-radius: 8px;
	overflow: hidden;
	padding: 60px 40px 60px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.service-block-five .inner-box .side-layer-one {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 290px;
	height: 290px;
	border-radius: 50%;
	background-color: #f5f5f5;
	transform: translateX(320px) translateY(-320px);
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.service-block-five .inner-box .side-layer-two {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 370px;
	height: 370px;
	opacity: 0.5;
	border-radius: 50%;
	background-color: #f5f5f5;
	transform: translateX(400px) translateY(-400px);
	-webkit-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	transition: all 600ms ease;
}

.service-block-five .inner-box:hover .side-layer-one {
	transform: translateX(160px) translateY(-160px);
}

.service-block-five .inner-box:hover .side-layer-two {
	transform: translateX(180px) translateY(-190px);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.service-block-five .inner-box .icon-box {
	position: relative;
	width: 120px;
	height: 120px;
	border-radius: 50px;
	margin: 0 auto;
}

.service-block-five .inner-box .icon-box .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.service-block-five .inner-box .icon-box .border-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 50%;
	border: 1px dashed #0072bc;
}

.service-block-five .inner-box .icon-box .dott-layer {
	position: absolute;
	left: 8px;
	top: 8px;
	right: 8px;
	bottom: 8px;
	border-radius: 50%;
}

.service-block-five .inner-box .icon-box .dott-layer:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 20px;
	height: 20px;
	border-radius: 50px;
	background-color: #ff4200;
}

.service-block-five .inner-box h5 {
	position: relative;
	color: #222222;
	font-weight: 600;
	line-height: 1.3em;
	margin-top: 35px;
}

.service-block-five .inner-box h5 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.service-block-five .inner-box .learn-more:hover,
.service-block-five .inner-box h5 a:hover {
	color: #0000ff;
}

.service-block-five .inner-box .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	margin-top: 20px;
}

.service-block-five .inner-box .learn-more {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.4em;
	margin-top: 20px;
	font-weight: 600;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

/*** 

====================================================================
	Analysis Section
====================================================================

***/

.analysis-section {
	position: relative;
	overflow: hidden;
	padding: 70px 0px 100px;
}

.analysis-section .pattern-layer {
	position: absolute;
	right: -250px;
	top: 180px;
	width: 1187px;
	height: 576px;
	background-repeat: no-repeat;
}

.analysis-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.analysis-section .image-column .inner-column {
	position: relative;
}

.analysis-section .image-column .image {
	position: relative;
}

.analysis-section .image-column .image img {
	position: relative;
}

.analysis-section .title-column {
	position: relative;
	margin-bottom: 40px;
}

.analysis-section .title-column .inner-column {
	position: relative;
	padding-top: 80px;
}

.analysis-section .title-column .sec-title {
	margin-bottom: 20px;
}

.analysis-section .title-column .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 40px;
}

/*** 

====================================================================
	Counter Section Three
====================================================================

***/

.counter-section-three {
	position: relative;
	overflow: hidden;
	padding: 100px 0px 50px;
}

.counter-section-three .image-column {
	position: relative;
	margin-bottom: 40px;
}

.counter-section-three .image-column .inner-column {
	position: relative;
}

.counter-section-three .image-column .image {
	position: relative;
	margin-left: -150px;
}

.counter-section-three .counter-column {
	position: relative;
	margin-bottom: 40px;
}

.counter-section-three .counter-column .inner-column {
	position: relative;
}

.counter-section-three .counter-column .sec-title {
	margin-bottom: 20px;
}

.counter-section-three .counter-column .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 35px;
}

.counter-section-three .counter-column .fact-counter-three .column:nth-child(3) {
	transform: translateX(80px);
}

.counter-section-three .counter-column .fact-counter-three .column:nth-child(4) {
	transform: translateX(80px);
}

/* Fact Counter Three */

.fact-counter-three {
	position: relative;
}

.fact-counter-three .column {
	position: relative;
	margin-bottom: 20px;
}

.fact-counter-three .column .inner {
	position: relative;
}

.fact-counter-three .column .inner .content {
	position: relative;
	text-align: center;
	padding: 25px 40px;
	display: inline-block;
	background: url(../images/background/pattern-13.png) no-repeat;
	background-position: center center;
}

.fact-counter-three .count-outer .percentage {
	display: inline-block;
	font-weight: 600;
	color: #222222;
	font-size: 54px;
	line-height: 1em;
}

.fact-counter-three .column .inner .count-outer {
	position: relative;
	font-weight: 600;
	color: #222222;
	font-size: 36px;
	line-height: 1em;
}

.fact-counter-three .column .inner .counter-title {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	margin-top: 8px;
	color: #222222;
	text-transform: capitalize;
}

/*** 

====================================================================
	Steps Section
====================================================================

***/

.steps-section {
	position: relative;
	padding: 100px 0px 80px;
	background-position: center top;
	background-repeat: repeat-x;
}

.steps-section .step-block:nth-child(1) .inner-box:before {
	position: absolute;
	content: '';
	right: -10%;
	top: 95px;
	width: 30px;
	height: 30px;
	background: url(../images/icons/step-arrow-one.png) no-repeat;
}

.steps-section .step-block:nth-child(2) .inner-box:before {
	position: absolute;
	content: '';
	right: -10%;
	top: 95px;
	width: 30px;
	height: 30px;
	background: url(../images/icons/step-arrow-two.png) no-repeat;
}

.steps-section .step-block:nth-child(3) .inner-box:before {
	position: absolute;
	content: '';
	right: -10%;
	top: 95px;
	width: 30px;
	height: 30px;
	background: url(../images/icons/step-arrow-three.png) no-repeat;
}

.step-block {
	position: relative;
	margin-bottom: 40px;
}

.step-block .inner-box {
	position: relative;
	text-align: center;
}

.step-block .inner-box .icon-box {
	position: relative;
	width: 200px;
	height: 205px;
	text-align: center;
	line-height: 205px;
	margin: 0 auto;
}

.step-block .inner-box .icon-box:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background: url(../images/background/step-1.png) no-repeat;
}

.step-block:nth-child(2) .inner-box .icon-box:before {
	background: url(../images/background/step-2.png) no-repeat;
}

.step-block:nth-child(3) .inner-box .icon-box:before {
	background: url(../images/background/step-3.png) no-repeat;
}

.step-block:nth-child(4) .inner-box .icon-box:before {
	background: url(../images/background/step-4.png) no-repeat;
}

.step-block .inner-box .icon-box .number {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	font-size: 36px;
}

.step-block .inner-box h6 {
	position: relative;
	color: #222222;
	font-size: 18px;
	font-weight: 600;
	margin-top: 35px;
	text-transform: uppercase;
}

/*** 

====================================================================
	Skill Section
====================================================================

***/

.skill-section {
	position: relative;
	overflow: hidden;
	padding: 100px 0px 50px;
}

.skill-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.skill-section .image-column .inner-column {
	position: relative;
}

.skill-section .image-column .image {
	position: relative;
	margin-right: -150px;
}

.skill-section .counter-column {
	position: relative;
	margin-bottom: 40px;
}

.skill-section .counter-column .inner-column {
	position: relative;
}

.skill-section .counter-column .sec-title {
	margin-bottom: 20px;
}

.skill-section .counter-column .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 35px;
}

/* Skills Section */

.skills .skill-item {
	position: relative;
	margin-bottom: 30px;
}

.skills .skill-item:last-child {
	margin-bottom: 0px;
}

.skills .skill-item .skill-bar {
	position: relative;
	width: 100%;
	height: 5px;
}

.skills .skill-item .skill-bar .bar-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 5px;
	background: #cccccc;
}

.skills.style-two .skill-item .skill-bar .bar-inner {
	background-color: #eeeeee;
}

.skills .skill-item .skill-bar .bar-inner .bar {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 5px;
	width: 0px;
	border-bottom: 5px solid #23448c;
	-webkit-transition: all 2000ms ease;
	-ms-transition: all 2000ms ease;
	-o-transition: all 2000ms ease;
	-moz-transition: all 2000ms ease;
	transition: all 2000ms ease;
}

.skills .skill-item .skill-header {
	position: relative;
	margin-bottom: 12px;
}

.skills .skill-item .skill-header .skill-title {
	position: relative;
}

.skills .skill-item .skill-header .skill-title {
	float: left;
	color: #555555;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4em;
	text-transform: capitalize;
}

.skills .skill-item .skill-header .skill-percentage {
	position: relative;
	float: right;
	font-weight: 400;
	color: #555555;
	font-size: 16px;
	text-transform: uppercase;
}

/*** 

====================================================================
	Demo Section
====================================================================

***/

.demo-section {
	position: relative;
	overflow: hidden;
	padding: 0px 0px 0px;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.demo-section .image-column {
	position: relative;
	margin-bottom: 0px;
}

.demo-section .image-column .inner-column {
	position: relative;
	padding-top: 120px;
}

.demo-section .image-column .image {
	position: relative;
	margin-right: -150px;
}

.demo-section .image-column .image img {
	opacity: 0.20;
}

.demo-section .image-column .video-box {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 120px;
	height: 120px;
	color: #ffffff;
	font-size: 32px;
	text-align: center;
	border-radius: 50%;
	line-height: 110px;
	display: inline-block;
	float: left;
	margin-top: -60px;
	margin-left: -60px;
	border: 5px solid #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.demo-section .image-column .video-box .overlay-box span {
	position: absolute;
	width: 120px;
	height: 120px;
	left: 50%;
	top: 50%;
	z-index: 99;
	color: #ffffff;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	border-radius: 50%;
	padding-left: 7px;
	display: inline-block;
	margin-top: -60px;
	margin-left: -60px;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.demo-section .image-column .video-box .ripple,
.demo-section .image-column .video-box .ripple:before,
.demo-section .image-column .video-box .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 120px;
	width: 120px;
	margin-left: .5px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.demo-section .image-column .video-box .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.demo-section .image-column .video-box .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

.demo-section .counter-column {
	position: relative;
	margin-bottom: 40px;
}

.demo-section .counter-column .inner-column {
	position: relative;
	padding-top: 200px;
}

.demo-section .counter-column h2 {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	margin-bottom: 15px;
}

.demo-section .counter-column .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 35px;
	max-width: 500px;
}

/*** 

====================================================================
	Testimonial Section Three
====================================================================

 ***/

.testimonial-section-three {
	position: relative;
	padding: 90px 0px 80px;
}

.testimonial-section-three .pattern-layer {
	display: none;
}

.testimonial-section-three .owl-carousel .owl-stage-outer {
	position: static;
	padding: 20px 0px;
}

.testimonial-section-three .owl-theme .testimonial-block-three {
	margin: 0px 20px;
}

.testimonial-section-three .owl-carousel {
	margin: 0px -20px;
	width: auto;
}

.testimonial-section-three .owl-nav {
	display: none;
}

.testimonial-section-three .owl-dots {
	position: relative;
	text-align: center;
	margin-top: 20px;
}

.testimonial-section-three .owl-dots .owl-dot {
	position: relative;
	width: 8px;
	height: 8px;
	margin: 0px 4px;
	border-radius: 50px;
	display: inline-block;
	background-color: #fe4c1c;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.testimonial-section-three .owl-dots .owl-dot.active,
.testimonial-section-three .owl-dots .owl-dot:hover {
	width: 30px;
	background-color: #222222;
}

.testimonial-block-three {
	position: relative;
}

.testimonial-block-three .inner-box {
	position: relative;
	padding: 50px 40px;
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.testimonial-block-three .inner-box .author-image-outer {
	position: relative;
	padding-left: 130px;
	padding-top: 30px;
	min-height: 105px;
}

.testimonial-block-three .inner-box .author-image-outer .author-image {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 105px;
	height: 105px;
	border-radius: 50%;
	padding: 3px 3px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.testimonial-block-three .inner-box .author-image-outer .author-image img {
	border-radius: 50%;
}

.testimonial-block-three .inner-box .author-image-outer h6 {
	position: relative;
	color: #222222;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.3em;
	text-transform: uppercase;
}

.testimonial-block-three .inner-box .author-image-outer .designation {
	position: relative;
	color: #555555;
	font-size: 14px;
	margin-top: 8px;
}

.testimonial-block-three .inner-box .author-image-outer .quote-icon {
	position: absolute;
	right: 0px;
	top: 5px;
	color: #dbdbdb;
	line-height: 1em;
	font-size: 40px;
}

.testimonial-block-three .inner-box .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
	margin-top: 30px;
	text-align: left;
}

.testimonial-block-three .inner-box .rating {
	position: relative;
	color: #ffaa01;
	font-size: 16px;
	margin-top: 15px;
	text-align: left;
	padding-left: 25px;
}

.testimonial-block-three .inner-box:before {
	display: none;
}

/*** 

====================================================================
	Newsletter Section
====================================================================

***/

.newsletter-section {
	position: relative;
	padding: 30px 0px 60px;
}

.newsletter-section .form-column {
	position: relative;
	margin-bottom: 30px;
}

.newsletter-section .form-column .inner-column {
	position: relative;
	padding-top: 60px;
	padding-right: 30px;
}

.newsletter-section .form-column .sec-title {
	margin-bottom: 20px;
}

.newsletter-section .form-column .text {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.9em;
	margin-bottom: 35px;
}

.newsletter-section.style-two {
	padding-top: 0px;
}

/* Newslettter Form */

.newsletter-form {
	margin-bottom: 35px;
}

.newsletter-form .form-group {
	position: relative;
	display: block;
	margin-bottom: 10px;
}

.newsletter-form .form-group input[type="text"],
.newsletter-form .form-group input[type="email"] {
	position: relative;
	display: block;
	width: 100%;
	line-height: 26px;
	height: 60px;
	font-size: 15px;
	color: #000000;
	border-radius: 6px;
	background: #ffffff;
	border: 2px solid #dddddd;
	padding: 10px 85px 10px 20px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.newsletter-form .form-group button {
	position: relative;
	cursor: pointer;
	margin-top: 30px;
}

.newsletter-section .image-column {
	position: relative;
	margin-bottom: 30px;
}

.newsletter-section .image-column .inner-column {
	position: relative;
}

/*** 

====================================================================
	Page Title
====================================================================

***/

.page-title {
	position: relative;
	text-align: center;
	overflow: hidden;
	padding: 240px 0px 260px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
}

.page-title .pattern-layer-one {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 260px;
	height: 330px;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.page-title .pattern-layer-two {
	position: absolute;
	right: 0px;
	bottom: 0px;
	top: 0px;
	width: 100%;
	height: 672px;
	background-repeat: repeat-x;
}

.page-title .pattern-layer-three {
	position: absolute;
	left: 8%;
	bottom: 28%;
	width: 97px;
	height: 97px;
	background-repeat: repeat-x;
}

.page-title .pattern-layer-four {
	position: absolute;
	right: 8%;
	bottom: 28%;
	width: 46px;
	height: 46px;
	background-repeat: repeat-x;
}

.page-title .auto-container {
	position: relative;
}

.page-title h2 {
	position: relative;
	color: #ffffff;
	font-weight: 700;
	line-height: 1.2em;
	margin-bottom: 0px;
	text-transform: capitalize;
	max-width: 800px;
	margin: 0 auto;
}

.page-breadcrumb {
	position: relative;
	margin-top: 20px;
}

.page-breadcrumb li {
	position: relative;
	display: inline-block;
	margin-right: 10px;
	padding-right: 15px;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	text-transform: capitalize;
}

.page-breadcrumb li:after {
	position: absolute;
	content: "/";
	right: -2px;
	top: -1px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	font-family: 'FontAwesome';
}

.page-breadcrumb li:last-child::after {
	display: none;
}

.page-breadcrumb li:last-child {
	padding-right: 0px;
	margin-right: 0px;
}

.page-breadcrumb li a {
	color: #ffffff;
	font-weight: 500;
	text-transform: capitalize;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.page-breadcrumb li a:hover {
	color: #fde20b;
}

/*** 

====================================================================
	Faq Page Section
====================================================================

***/

.faq-page-section {
	position: relative;
	padding: 100px 0px 80px;
}

.faq-form-section {
	position: relative;
	padding: 30px 0px 40px;
}

.faq-form-section .form-column {
	position: relative;
	margin-bottom: 40px;
}

.faq-form-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.faq-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.faq-form .form-group:last-child {
	margin-bottom: 0px;
}

.faq-form .form-group input[type="text"],
.faq-form .form-group input[type="email"],
.faq-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 30px;
	color: #777777;
	height: 60px;
	font-size: 14px;
	background: none;
	font-weight: 400;
	border-radius: 6px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.faq-form .form-group input::-webkit-input-placeholder,
.faq-form .form-group textarea::-webkit-input-placeholder {
	color: #777777;
}

.faq-form .form-group input[type="text"]:focus,
.faq-form .form-group input[type="email"]:focus,
.faq-form .form-group textarea:focus {}

.faq-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 15px 25px;
	color: #777777;
	height: 180px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
	resize: none;
	font-size: 14px;
	font-weight: 400;
	border-radius: 6px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.faq-form .form-group .theme-btn {
	cursor: pointer;
}

.faq-form.contact-form .form-group {
	margin-bottom: 20px;
}

/*** 

====================================================================
	Team Page Section
====================================================================

***/

.team-page-section {
	position: relative;
	padding: 110px 0px 70px;
}

.team-page-section .team-block {
	margin-bottom: 40px;
}

/*** 

====================================================================
	Team Single Section
====================================================================

***/

.team-single-section {
	position: relative;
	padding-top: 100px;
	padding-bottom: 60px;
}

.team-single-section .image-column {
	position: relative;
	margin-bottom: 40px;
}

.team-single-section .image-column .inner-column {
	position: relative;
}

.team-single-section .image-column .image {
	position: relative;
	border-radius: 5px;
	overflow: hidden;
}

.team-single-section .image-column .image img {
	position: relative;
	width: 100%;
	display: block;
}

.team-single-section .content-column {
	position: relative;
	margin-bottom: 40px;
}

.team-single-section .content-column .inner-column {
	position: relative;
	padding-top: 15px;
	padding-left: 20px;
}

.team-single-section .content-column h2 {
	position: relative;
	color: #222222;
	font-size: 30px;
	font-weight: 700;
	line-height: 1.3em;
}

.team-single-section .content-column h2 span {
	position: relative;
	top: -3px;
	color: #ffffff;
	font-size: 15px;
	padding: 6px 28px;
	border-radius: 4px;
	margin-left: 15px;
	background-color: #eb3867;
}

.team-single-section .content-column .post-meta {
	position: relative;
	margin-top: 15px;
	margin-bottom: 10px;
}

.team-single-section .content-column .post-meta li {
	position: relative;
	margin-right: 50px;
	color: #677077;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
	padding-left: 30px;
	display: inline-block;
}

.team-single-section .content-column .post-meta li .icon {
	position: absolute;
	left: 0px;
	color: #eb3867;
	top: 1px;
	margin-right: 12px;
	font-size: 20px;
}

.team-single-section .content-column .post-meta li a {
	position: relative;
	color: #677077;
}

.team-single-section .content-column .post-meta li:last-child {
	margin-right: 0px;
}

.team-single-section .content-column .text {
	position: relative;
	margin-bottom: 20px;
}

.team-single-section .content-column .text p {
	position: relative;
	color: #222222;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.9em;
	margin-bottom: 10px;
}

.team-single-section .content-column .text p:last-child {
	margin-bottom: 0px;
}

.team-single-section .content-column .social-icon-one {
	margin-top: 18px;
}

/*** 

====================================================================
	Testimonial Page Section
====================================================================

***/

.testimonial-page-section {
	position: relative;
	padding: 100px 0px 40px;
}

.testimonial-page-section .testimonial-block {
	margin-bottom: 40px;
}

/*** 

====================================================================
	Coming Soon
====================================================================

***/

.coming-soon {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	overflow-y: auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	text-align: center;
}

.coming-soon .content {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: table;
	vertical-align: middle;
}

.coming-soon .content:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #000000;
	content: "";
	opacity: .30;
}

.coming-soon .content .content-inner {
	position: relative;
	padding: 90px 15px;
	display: table-cell;
	vertical-align: middle;
}

.coming-soon .content .content-inner .logo {
	position: relative;
	margin-bottom: 200px;
}

.coming-soon .content .content-inner .sec-title {
	display: none;
	margin-bottom: 50px;
}

.coming-soon .content .content-inner h2 {
	position: relative;
	font-size: 48px;
	font-weight: 700;
	color: #ffffff;
	line-height: 1.2em;
	padding-bottom: 0;
	font-variant: small-caps;
}

.coming-soon .content .content-inner h2:after {
	display: none;
}

.time-counter {
	position: relative;
}

.time-counter .time-countdown {
	position: relative;
	margin-bottom: 180px;
}

.time-counter .time-countdown .time-section {
	position: relative;
	display: inline-block;
	width: 180px;
	height: 180px;
	font-size: 20px;
	line-height: 1em;
	color: #ffffff;
	text-transform: uppercase;
	font-weight: 600;
	text-align: center;
	z-index: 7;
	border-radius: 20px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
	background-color: rgba(54, 54, 54, .40);
	margin: 0 15px 20px;
}

.time-counter .time-countdown .time-section .time {
	position: relative;
	display: block;
	font-size: 72px;
	line-height: 30px;
	padding: 60px 0px 30px;
	color: #ffffff;
	font-weight: 600;
}

.coming-soon .emailed-form {
	position: relative;
	margin-bottom: 40px;
}

.coming-soon .emailed-form h3 {
	line-height: 1.2em;
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 20px;
}

.coming-soon .emailed-form .text {
	position: relative;
	display: block;
	font-size: 18px;
	font-weight: 400;
	color: #ffffff;
	line-height: 1.8em;
	margin-bottom: 40px;
}

.coming-soon .emailed-form .form-group {
	position: relative;
	display: block;
	max-width: 540px;
	width: 100%;
	margin: 0 auto;
}

.coming-soon .emailed-form .form-group input[type="text"],
.coming-soon .emailed-form .form-group input[type="tel"],
.coming-soon .emailed-form .form-group input[type="email"],
.coming-soon .emailed-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	height: 60px;
	font-size: 16px;
	color: #000000;
	overflow: hidden;
	padding: 15px 50px 15px 20px;
	background: #ffffff;
	border: 1px solid #f1f1f1;
	box-shadow: 0 0 13px rgba(0, 0, 0, 0.10);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input:focus,
.coming-soon .emailed-form .form-group select:focus,
.coming-soon .emailed-form .form-group textarea:focus {}

.coming-soon .emailed-form .form-group input[type="submit"],
.coming-soon .emailed-form button {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	line-height: 40px;
	font-size: 18px;
	color: #eb3867;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.coming-soon .emailed-form .form-group input[type="submit"]:hover,
.coming-soon .emailed-form button:hover {
	color: #353535;
}

.coming-soon .copyright-text {
	position: relative;
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
	font-weight: 400;
}

.coming-soon .copyright-text a {
	color: #ff9600;
}

.coming-soon .copyright-text a:hover {
	text-decoration: underline;
}

.clock-wrapper {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	width: 250px;
	height: 250px;
	margin: auto;
	margin-top: -145px;
	border-radius: 50%;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
	background-color: rgba(0, 0, 0, .40);
	transform: scale(2);
}

.clock-base {
	width: 250px;
	height: 250px;
	border-radius: 50%;

}

.click-indicator {
	position: absolute;
	z-index: 1;
	top: 10px;
	left: 10px;
	width: 230px;
	height: 230px;
}

.click-indicator div {
	position: absolute;
	width: 2px;
	height: 4px;
	margin: 113px 114px;
}

.click-indicator div>span {
	height: 5px;
	width: 5px;
	background-color: #ffffff;
	display: inline-block;
	border-radius: 50%;
	margin-left: -1px;
	margin-top: -1px;
}

.click-indicator div:nth-child(1) {
	transform: rotate(30deg) translateY(-120px);
}

.click-indicator div:nth-child(2) {
	transform: rotate(60deg) translateY(-120px);
}

.click-indicator div:nth-child(3) {
	transform: rotate(90deg) translateY(-120px);
	background-color: #eb3867;
}

.click-indicator div:nth-child(4) {
	transform: rotate(120deg) translateY(-120px);
}

.click-indicator div:nth-child(5) {
	transform: rotate(150deg) translateY(-120px);
}

.click-indicator div:nth-child(6) {
	transform: rotate(180deg) translateY(-120px);
	background-color: #eb3867;
}

.click-indicator div:nth-child(7) {
	transform: rotate(210deg) translateY(-120px);
}

.click-indicator div:nth-child(8) {
	transform: rotate(240deg) translateY(-120px);
}

.click-indicator div:nth-child(9) {
	transform: rotate(270deg) translateY(-120px);
	background-color: #eb3867;
}

.click-indicator div:nth-child(10) {
	transform: rotate(300deg) translateY(-120px);
}

.click-indicator div:nth-child(11) {
	transform: rotate(330deg) translateY(-120px);
}

.click-indicator div:nth-child(12) {
	transform: rotate(360deg) translateY(-120px);
	background-color: #eb3867;
}

.clock-hour {
	position: absolute;
	z-index: 2;
	top: 75px;
	left: 123px;
	width: 4px;
	height: 65px;
	background-color: #eb3867;
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 50px;
	transition: .5s;
	-webkit-animation: rotate-hour 43200s linear infinite;
	-moz-animation: rotate-hour 43200s linear infinite;
}

.clock-minute {
	position: absolute;
	z-index: 3;
	top: 55px;
	left: 123px;
	width: 4px;
	height: 85px;
	background-color: #eb3867;
	border-radius: 2px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 2px 70px;
	transition: .5s;
	-webkit-animation: rotate-minute 3600s linear infinite;
	-moz-animation: rotate-minute 3600s linear infinite;
}

.clock-second {
	position: absolute;
	z-index: 4;
	top: 15px;
	left: 124px;
	width: 2px;
	height: 130px;
	background-color: #ffffff;
	opacity: .9;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);
	transform-origin: 1px 110px;
	transition: .5s;
	-webkit-animation: rotate-second 60s linear infinite;
	-moz-animation: rotate-second 60s linear infinite;
}

.clock-second:after {
	content: "";
	display: block;
	position: absolute;
	left: -3px;
	bottom: 16px;
	width: 8px;
	height: 8px;
	background-color: #222222;
	border: solid 2px #222222;
	border-radius: 50%;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.clock-center {
	position: absolute;
	z-index: 1;
	width: 150px;
	height: 150px;
	top: 50px;
	left: 50px;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
	border-radius: 50%;
}

.clock-center:after {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	margin: 65px;
	background-color: #ddd;
	border-radius: 50%;
}

/*** 

====================================================================
	Privacy Section
====================================================================

***/

.privacy-section {
	position: relative;
	padding: 110px 0px 80px;
}

.privacy-section .pattern-layer {
	position: absolute;
	top: 50px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: right top;
}

.privacy-section .privacy-content {
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 25px;
	border-bottom: 1px solid #e2e2e2;
}

.privacy-section .privacy-content:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border: none;
}

.privacy-section h2 {
	position: relative;
	color: #000000;
	line-height: 1.2em;
	margin-bottom: 22px;
	font-weight: 800;
}

.privacy-section p {
	position: relative;
	color: #4a4a4a;
	font-size: 16px;
	line-height: 1.7em;
	margin-bottom: 15px;
	font-weight: 400;
}

.privacy-section .date {
	position: relative;
	color: #eb3867;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
}

.privacy-section h4 {
	position: relative;
	color: #000000;
	line-height: 1.5em;
	margin-bottom: 22px;
	font-weight: 700;
}

.privacy-section .privacy-list {
	position: relative;
	text-align: left;
	margin-left: 22px;
}

.privacy-section .privacy-list li {
	position: relative;
	margin-bottom: 12px;
	color: #626262;
	font-size: 16px;
	font-weight: 400;
	padding-left: 26px;
	line-height: 1.7em;
}

.privacy-section .privacy-list li:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 10px;
	width: 7px;
	height: 7px;
	border-radius: 50px;
	background-color: #eb3867;
}

/*** 

====================================================================
	Services Page Section
====================================================================

***/

.services-page-section {
	position: relative;
	padding: 100px 0px 80px;
}

.services-page-section .service-block-four {
	margin-bottom: 50px;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
	position: relative;
	padding: 90px 0px 80px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
	margin-bottom: 0px;
}

.sidebar-widget {
	margin-bottom: 40px;
}

.sticky-top {
	z-index: 1;
	top: 90px;
}

/* Service List */

.sidebar .service-list {
	position: relative;
}

.sidebar .service-list li {
	position: relative;
	line-height: 24px;
	margin-bottom: 12px;
}

.sidebar .service-list li:last-child {
	margin-bottom: 0px;
}

.sidebar .service-list li a {
	position: relative;
	display: block;
	color: #222222;
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;
	padding: 17px 25px;
	border-radius: 4px;
	border: 1px solid #d7d7d7;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.sidebar .service-list li a .color-layer {
	position: absolute;
	content: '';
	left: -1px;
	top: -1px;
	right: -1px;
	height: 0%;
	z-index: -1;
	border-radius: 4px;
	background: #DB4DE5;
	background: -webkit-linear-gradient(to bottom, #DB4DE5 0%, #781eb9 100%);
	background: -moz-linear-gradient(to bottom, #DB4DE5 0%, #781eb9 100%);
	background: linear-gradient(to bottom, #DB4DE5 0%, #781eb9 100%);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}


.sidebar .service-list li.current a .color-layer,
.sidebar .service-list li a:hover .color-layer {
	height: 101%;
}

.sidebar .service-list li a:before {
	font-family: 'FontAwesome';
	content: "\f101";
	position: absolute;
	right: 20px;
	top: 16px;
	display: block;
	line-height: 26px;
	font-size: 20px;
	font-weight: normal;
	text-align: center;
	z-index: 5;
}

.sidebar .service-list li a:hover,
.sidebar .service-list li.current a {
	color: #ffffff;

}

/* Broucher Widget */

.broucher-widget .widget-content {
	position: relative;
	padding: 50px 30px;
	text-align: center;
	border-radius: 8px;
	border: 1px solid #d7d7d7;
}

.broucher-widget .widget-content h3 {
	position: relative;
	color: #111111;
	font-weight: 600;
	line-height: 1.3em;
	margin-bottom: 30px;
}

.broucher-widget .widget-content .icon {
	position: relative;
	line-height: 1em;
	font-size: 60px;
	color: #d44ae0;
	margin-bottom: 30px;
}

.broucher-widget .widget-content .text {
	position: relative;
	color: #777777;
	font-size: 16px;
	line-height: 1.9em;
	margin-bottom: 20px;
}

.broucher-widget .widget-content .download {
	position: relative;
	color: #222222;
	font-size: 16px;
	font-weight: 600;
	display: inline-block;
	border-bottom: 1px solid #222222;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.broucher-widget .widget-content .download:hover {
	color: #8424bf;
	border-color: #8424bf;
}

/* Help Widget */

.help-widget {
	position: relative;
	margin-top: 40px;
}

.help-widget .widget-content {
	position: relative;
	padding: 25px 32px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
}

.help-widget .widget-content h4 {
	position: relative;
	color: #222222;
	font-weight: 600;
	margin-bottom: 15px;
}

.help-widget .widget-content .text {
	position: relative;
	color: #5a5a5a;
	font-size: 16px;
	line-height: 1.8em;
	margin-bottom: 15px;
}

.help-widget .widget-content .help-list {
	position: relative;
}

.help-widget .widget-content .help-list li {
	position: relative;
	color: #666666;
	font-size: 16px;
	margin-bottom: 10px;
}

.help-widget .widget-content .help-list li .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	color: #8b27c2;
	line-height: 1em;
}

.help-widget .widget-content .help-list li:last-child {
	margin-bottom: 0px;
}

.help-widget .widget-content .help-list li a {
	position: relative;
	color: #666666;
	padding-left: 30px;
}

/* Service Detail */

.service-detail {
	position: relative;
}

.service-detail .inner-box {
	position: relative;
}

.service-detail .inner-box .image {
	position: relative;
}

.service-detail .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.service-detail .inner-box .lower-content {
	position: relative;
	padding-top: 30px;
}

.service-detail .inner-box .lower-content .two-columns {
	position: relative;
	margin-top: 25px;
}

.service-detail .inner-box .lower-content h3 {
	position: relative;
	font-weight: 600;
	color: #7b1fba;
	line-height: 1.3em;
	margin-bottom: 20px;
}

.service-detail .inner-box .lower-content p {
	position: relative;
	color: #777777;
	font-size: 16px;
	line-height: 1.8em;
}

.service-detail .inner-box .lower-content h4 {
	position: relative;
	font-weight: 600;
	color: #222222;
	line-height: 1.3em;
	margin-bottom: 15px;
}

.service-detail .inner-box .lower-content .image {
	margin-bottom: 30px;
}

/*** 

====================================================================
	Gallery Detail Section
====================================================================

***/

.gallery-detail-section {
	position: relative;
	padding: 90px 0px 110px;
}

.gallery-detail-section .inner-container {
	position: relative;
	padding: 0px 100px 80px;
	border-bottom: 1px solid #cbcbcb;
}

.gallery-detail-section .upper-box {
	position: relative;
}

.gallery-detail-section .upper-box .list {
	position: relative;
	margin-bottom: 40px;
}

.gallery-detail-section .upper-box .list li {
	position: relative;
	color: #555555;
	font-size: 16px;
}

.gallery-detail-section .upper-box .list li strong {
	position: relative;
	color: #222222;
	font-weight: 600;
	display: block;
	margin-bottom: 6px;
}

.gallery-detail-section .upper-box .social-box {
	position: relative;
}

.gallery-detail-section .upper-box .social-box li {
	position: relative;
	margin-left: 5px;
	display: inline-block;
}

.gallery-detail-section .upper-box .social-box li a {
	position: relative;
	width: 40px;
	height: 40px;
	color: #ffffff;
	line-height: 40px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
}

.gallery-detail-section .upper-box .social-box li.facebook a {
	background-color: #4661c5;
}

.gallery-detail-section .upper-box .social-box li.twitter a {
	background-color: #44b1e4;
}

.gallery-detail-section .upper-box .social-box li.linkedin a {
	background-color: #0029c2;
}

.gallery-detail-section .upper-box .social-box li.google a {
	background-color: #e84231;
}

.gallery-detail-section .inner-box {
	position: relative;
}

.gallery-detail-section .inner-box .image {
	position: relative;
	margin-bottom: 35px;
}

.gallery-detail-section .inner-box .image img {
	position: relative;
	width: 100%;
	max-height: 550px;
	object-fit: cover;
}

.gallery-detail-section .inner-box h3 {
	position: relative;
	color: #222222;
	font-size: 30px;
	font-weight: 600;
	line-height: 1.3em;
	margin-bottom: 18px;
}

.gallery-detail-section .inner-box p {
	position: relative;
	color: #555555;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.9em;
	margin-bottom: 18px;
}

.gallery-detail-section .inner-box p span {
	font-weight: 600;
	color: #222222;
}

.gallery-detail-section .inner-box blockquote {
	position: relative;
	color: #222222;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.6em;
	padding-left: 55px;
	margin-top: 25px;
	border-left: 4px solid #e84231;
}

.gallery-detail-section .inner-box blockquote .author-name {
	position: relative;
	color: #0096dc;
	font-weight: 600;
	display: block;
	font-size: 18px;
	margin-top: 10px;
	text-transform: uppercase;
}

.gallery-detail-section .inner-box .gallery-column {
	position: relative;
	margin-top: 35px;
}

.gallery-detail-section .inner-box .gallery-column .image {
	position: relative;
}

.gallery-detail-section .inner-box .gallery-column .image img {
	position: relative;
	width: 100%;
	display: block;
}

.gallery-detail-section .inner-box h4 {
	position: relative;
	color: #222222;
	font-size: 30px;
	font-weight: 600;
	line-height: 1.3em;
	margin-bottom: 18px;
}

.gallery-detail-section .inner-box h4 span {
	position: relative;
	font-weight: 400;
}

/*** 

====================================================================
	Related Project Section
====================================================================

***/

.related-projects-section {
	position: relative;
	padding: 0px 0px 90px;
}

.related-projects-section .slick-slider {
	margin: -15px;
}

.related-projects-section .slick-slide {
	padding: 15px;
}

/*Search Box Widget*/

.sidebar .search-box .form-group {
	position: relative;
	margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
	position: relative;
	line-height: 43px;
	padding: 10px 50px 10px 25px;
	border: 1px solid #dddddd;
	background: #ffffff;
	display: block;
	font-size: 14px;
	width: 100%;
	height: 60px;
	color: #000000;
	border-radius: 5px;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.sidebar .search-box .form-group button {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 60px;
	width: 60px;
	display: block;
	font-size: 16px;
	color: #ffffff;
	border-radius: 0px 5px 5px 0px;
	line-height: 100%;
	font-weight: normal;
	background: #771db9;
}

/* Sidebar Title */

.sidebar-title {
	position: relative;
	margin-bottom: 28px;
}

.sidebar-title h4 {
	position: relative;
	color: #222222;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3em;
	padding-right: 55px;
	display: inline-block;
	text-transform: capitalize;
}

.sidebar-title h4:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 10px;
	height: 15px;
	width: 43px;
	background: url(../images/icons/title-icon.png) no-repeat;
}

.blog-cat {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	border: 1px solid #d7d7d7;
}

.blog-cat li {
	position: relative;
}

.blog-cat li a {
	position: relative;
	color: #666666;
	font-size: 16px;
	padding: 18px 30px;
	display: block;
	font-weight: 600;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border-bottom: 1px solid #d7d7d7;
}

.blog-cat li a:hover {
	color: #771db9;
	background-color: #f5f5f5;
}

.blog-cat li:last-child a {
	border-bottom: 0px;
}

.blog-cat li a span {
	position: absolute;
	right: 30px;
}

/* Post Widget */

.sidebar .popular-posts .post {
	position: relative;
	font-size: 14px;
	color: #666666;
	padding: 0px 0px;
	padding-left: 130px;
	min-height: 100px;
	margin-bottom: 25px;
}

.sidebar .popular-posts .post:last-child {
	margin-bottom: 0px;
	border-bottom: none;
}

.sidebar .popular-posts .post .post-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100px;
	border-radius: 8px;
	overflow: hidden;
}

.sidebar .popular-posts .post .post-thumb .overlay-box {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: block;
	color: #ffffff;
	font-size: 18px;
	opacity: 0;
	text-align: center;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	background-color: rgba(220, 78, 228, 0.70);
}

.sidebar .popular-posts .post .post-thumb .overlay-box .fa {
	top: 50%;
	position: relative;
	margin-top: -12px;
	display: block;
}

.sidebar .popular-posts .post:hover .post-thumb .overlay-box {
	opacity: 1;
}

.sidebar .popular-posts .post .post-thumb img {
	display: block;
	width: 100%;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post .text {
	position: relative;
	top: 0px;
	font-size: 18px;
	margin: 0px 0px 0px;
	font-weight: 600;
	color: #222222;
	line-height: 1.5em;
	text-transform: capitalize;
}

.sidebar .popular-posts .post .text a {
	color: #222222;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post a:hover {
	color: #d44ae1;
}

.sidebar .popular-posts .post-info {
	position: relative;
	font-size: 16px;
	color: #777777;
	font-weight: 400;
	margin-top: 4px;
}

/* Author Widget */

.author-widget {
	position: relative;
}

.author-widget .widget-content {
	position: relative;
	text-align: center;
	padding: 45px 40px 40px;
	border-radius: 10px;
	background-size: cover;
}

.author-widget .widget-content .image {
	position: relative;
	width: 138px;
	height: 138px;
	border-radius: 50%;
	overflow: hidden;
	margin: 0 auto;
	border: 2px solid #ffffff;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.40);
}

.author-widget .widget-content .name {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	font-size: 24px;
	margin-top: 50px;
}

.author-widget .widget-content .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	line-height: 1.8em;
	margin-top: 20px;
	margin-bottom: 25px;
}

.author-widget .widget-content .social-box {
	position: relative;
}

.author-widget .widget-content .social-box li {
	position: relative;
	margin: 0px 2px;
	display: inline-block;
}

.author-widget .widget-content .social-box li a {
	position: relative;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	display: block;
	color: #ffffff;
	border-radius: 50px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.author-widget .widget-content .social-box li a:hover {
	color: #731bb8;
	background-color: #ffffff;
}

.instagram-widget .post-thumb {
	position: relative;
	width: 29%;
	float: left;
	border-radius: 8px;
	overflow: hidden;
	margin: 0px 8px 18px;
}

.instagram-widget .post-thumb .overlay-box {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: block;
	color: #ffffff;
	font-size: 18px;
	opacity: 0;
	text-align: center;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	background-color: rgba(217, 77, 226, 0.70);
}

.instagram-widget .post-thumb .overlay-box .fa {
	top: 50%;
	position: relative;
	margin-top: -12px;
	display: block;
}

.instagram-widget .post-thumb:hover .overlay-box {
	opacity: 1;
}

.instagram-widget .post-thumb img {
	display: block;
	width: 100%;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

/* Popular Tags */

.sidebar .popular-tags a {
	position: relative;
	display: inline-block;
	line-height: 24px;
	padding: 10px 26px 10px;
	margin: 0px 6px 10px 0px;
	color: #777777;
	text-align: center;
	font-size: 16px;
	background: none;
	font-weight: 400;
	border-radius: 5px;
	border: 1px solid #d7d7d7;
	text-transform: capitalize;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.sidebar .popular-tags a:hover {
	border-color: #d74ce2;
	background-color: #d74ce2;
	color: #ffffff;
}

/* News Block Three */

.news-block-three {
	position: relative;
	margin-bottom: 40px;
}

.news-block-three .inner-box {
	position: relative;
}

.news-block-three .inner-box .image {
	position: relative;
}

.news-block-three .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}



.news-block-three.format-standard-image .inner-box .image .overlay-box {
	display: none;
}

.news-block-three .inner-box .image .overlay-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
	background-color: rgba(235, 53, 103, 0.60);
}

.news-block-three .inner-box .image .overlay-box .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 85px;
	height: 85px;
	color: #ffffff;
	margin-left: -50px;
	text-align: center;
	border-radius: 50%;
	line-height: 85px;
	display: inline-block;
	padding-left: 0px;
	font-size: 76px;
	padding-left: 1px;
	margin-top: -42px;
}

.news-block-three .inner-box .image .overlay-box .ripple,
.news-block-three .inner-box .image .overlay-box .ripple:before,
.news-block-three .inner-box .image .overlay-box .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	margin-left: .5px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.news-block-three .inner-box .image .overlay-box .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.news-block-three .inner-box .image .overlay-box .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

.news-block-three .inner-box .image .owl-dots {
	display: none;
}

.news-block-three .inner-box .owl-nav {
	position: absolute;
	left: 0px;
	top: 50%;
	width: 100%;
	margin-top: -38px;
}

.news-block-three .inner-box .owl-nav .owl-prev {
	position: absolute;
	left: 20px;
	width: 45px;
	height: 55px;
	line-height: 55px;
	text-align: center;
	font-size: 20px;
	color: #ffffff;
	font-weight: 600;
	border-radius: 5px;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.50);
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.news-block-three .inner-box .owl-nav .owl-next {
	position: absolute;
	right: 20px;
	width: 45px;
	height: 55px;
	line-height: 55px;
	text-align: center;
	font-size: 20px;
	color: #ffffff;
	font-weight: 600;
	border-radius: 5px;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.50);
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.news-block-three .inner-box .owl-nav .owl-prev:hover,
.news-block-three .inner-box .owl-nav .owl-next:hover {
	background-color: #6615b2;
	color: #ffffff;
}

.news-block-three .inner-box .lower-content {
	position: relative;
	padding-top: 25px;
}

.news-block-three .inner-box .lower-content .post-meta {
	position: relative;
}

.news-block-three .inner-box .lower-content .post-meta li {
	position: relative;
	margin-right: 15px;
	display: inline-block;
}

.news-block-three .inner-box .lower-content .post-meta li a {
	position: relative;
	color: #555555;
	font-size: 14px;
	padding-left: 25px;
}

.news-block-three .inner-box .lower-content .post-meta li:last-child {
	margin-right: 0px;
}

.news-block-three .inner-box .lower-content .post-meta li a .icon {
	position: absolute;
	left: 0px;
	color: #c945dc;
}

.news-block-three .inner-box .lower-content h4 {
	position: relative;
	line-height: 1.6em;
	font-weight: 600;
	margin-top: 10px;
}

.news-block-three .inner-box .lower-content h4 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-block-three .inner-box .lower-content h4 a:hover {
	color: #6615b2;
}

.news-block-three .inner-box .lower-content .text {
	position: relative;
	line-height: 1.9em;
	margin-top: 15px;
	color: #555555;
	font-size: 16px;
	margin-bottom: 25px;
}

.news-block-three .inner-box .lower-content .btn-box {
	position: relative;
}

.news-block-three .inner-box .lower-content .btn-box .btn-box-inner {
	position: relative;
	padding-right: 10px;
	display: inline-block;
	background-color: #ffffff;
}

.news-block-three .inner-box .lower-content .btn-box:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 10px;
	right: 0px;
	height: 1px;
	background-color: #c8c8c8;
}

.news-block-three .inner-box .lower-content .btn-box .theme-btn {
	position: relative;
}

/* News Block Four */

.news-block-four {
	position: relative;
	margin-bottom: 40px;
}

.news-block-four .inner-box {
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	padding: 50px 50px;
	background-size: cover;
}

.news-block-four .inner-box:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(1, 41, 63, 0.86);
}

.news-block-four .inner-box .post-date {
	position: relative;
	width: 70px;
	height: 80px;
	color: #ffffff;
	font-size: 36px;
	padding-top: 17px;
	font-weight: 600;
	text-align: center;
	display: inline-block;
	background-color: #fb8358;
}

.news-block-four .inner-box .post-date span {
	position: relative;
	font-weight: 400;
	font-size: 16px;
}

.news-block-four .inner-box .post-info {
	position: relative;
	margin-top: 40px;
}

.news-block-four .inner-box .post-info li {
	position: relative;
	color: #ffffff;
	font-size: 14px;
	line-height: 1.1em;
	margin-right: 10px;
	padding-right: 10px;
	display: inline-block;
	border-right: 1px solid #ffffff;
}

.news-block-four .inner-box .post-info li:first-child {
	padding-left: 55px;
}

.news-block-four .inner-box .post-info li .author-image {
	position: absolute;
	left: 0px;
	top: -12px;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 50px;
}

.news-block-four .inner-box .post-info li:last-child {
	padding-right: 0px;
	margin-right: 0px;
	border: none;
}

.news-block-four .inner-box .post-info li span {
	color: #fb8358;
}

.news-block-four .inner-box h3 {
	position: relative;
	line-height: 1.3em;
	font-weight: 600;
	margin-top: 30px;
}

.news-block-four .inner-box h3 a {
	position: relative;
	color: #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.news-block-four .inner-box h3 a:hover {
	color: #fb8358;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
	position: relative;
}

.styled-pagination li {
	position: relative;
	margin: 0px 5px 10px;
	display: inline-block;
}

.styled-pagination li a {
	position: relative;
	width: 50px;
	height: 50px;
	color: #222222;
	font-size: 15px;
	font-weight: 400;
	line-height: 50px;
	text-align: center;
	border-radius: 5px;
	display: inline-block;
	background-color: #e1e1e1;
	border: 1px solid #ebebeb;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.styled-pagination li.active a,
.styled-pagination li:hover a {
	color: #ffffff;
	background: -webkit-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: -moz-linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
	background: linear-gradient(to right, #EA3368 0%, #fd8b56 100%);
}

.styled-pagination li.prev a {
	color: #ffffff;
	background-color: #6615b2;
}

.styled-pagination li.next a {
	color: #ffffff;
	background-color: #d84ce2;
}

.our-blogs .styled-pagination {
	margin-top: 50px;
}

.news-detail {
	position: relative;
	margin-bottom: 30px;
}

.news-detail .inner-box {
	position: relative;
}

.news-detail .inner-box .image {
	position: relative;
}

.news-detail .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.news-detail .inner-box .lower-content {
	position: relative;
	padding-top: 25px;
}

.news-detail .inner-box .lower-content .post-meta {
	position: relative;
}

.news-detail .inner-box .lower-content .post-meta li {
	position: relative;
	color: #555555;
	font-size: 14px;
	padding-left: 25px;
	margin-right: 15px;
	display: inline-block;
}

.news-detail .inner-box .lower-content .post-meta li:last-child {
	margin-right: 0px;
}

.news-detail .inner-box .lower-content .post-meta li .icon {
	position: absolute;
	left: 0px;
	color: #c945dc;
}

.news-detail .inner-box .lower-content h2 {
	position: relative;
	line-height: 1.6em;
	font-weight: 600;
	margin-top: 12px;
	font-size: 24px;
	color: #222222;
	margin-bottom: 12px;
}

.news-detail .inner-box .lower-content p {
	position: relative;
	color: #555555;
	font-size: 16px;
	line-height: 1.8em;
}

.news-detail .inner-box .lower-content blockquote {
	position: relative;
	color: #222222;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.6em;
	padding-left: 55px;
	margin-top: 50px;
	margin-bottom: 60px;
	border-left: 4px solid #e84231;
}

.news-detail .inner-box .lower-content blockquote .author-name {
	position: relative;
	color: #0096dc;
	font-weight: 600;
	display: block;
	font-size: 16px;
	margin-top: 10px;
	text-align: right;
	line-height: 1.2em;
	text-transform: capitalize;
}

.news-detail .inner-box .lower-content .image {
	margin-bottom: 30px;
}

.news-detail .inner-box .lower-content h3 {
	position: relative;
	line-height: 1.6em;
	font-weight: 600;
	font-size: 24px;
	color: #222222;
	margin-bottom: 12px;
}

/* post share options */

.news-detail .post-share-options {
	position: relative;
	margin-top: 40px;
	padding: 22px 0px 25px;
	border-top: 1px solid #dddddd;
}

.news-detail .post-share-options .post-title {
	position: relative;
	color: #222222;
	font-size: 16px;
	font-weight: 500;
	float: left;
	margin-right: 10px;
}

.news-detail .post-share-options .tags {
	position: relative;
	float: left;
}

.news-detail .post-share-options .tags li {
	position: relative;
	margin-right: 4px;
	display: inline-block;
}

.news-detail .post-share-options .tags li a {
	position: relative;
	color: #888888;
	font-size: 16px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.news-detail .post-share-options .tags li a:hover {
	color: #1c17a0;
}

.news-detail .social-box {
	position: relative;
}

.news-detail .post-share-options .social-box li {
	position: relative;
	margin-left: 5px;
	display: inline-block;
}

.news-detail .post-share-options .social-box li a {
	position: relative;
	width: 40px;
	height: 40px;
	display: block;
	color: #ffffff;
	line-height: 40px;
	text-align: center;
	border-radius: 50px;
	background-color: #fe4c1c;
}

.news-detail .post-share-options .social-box li.facebook a {
	background-color: #4661c5;
}

.news-detail .post-share-options .social-box li.twitter a {
	background-color: #44b1e4;
}

.news-detail .post-share-options .social-box li.linkedin a {
	background-color: #ff2e2e;
}

.news-detail .post-share-options .social-box li.pinterest a {
	background-color: #0073b0;
}

/* Author Box */

.authors-box {
	position: relative;
	padding: 30px 30px;
	margin-top: 20px;
	overflow: hidden;
	margin-bottom: 55px;
	border-radius: 10px;
	border: 1px solid #e1e1e1;
}

.authors-box .author-inner {
	position: static;
	padding-top: 8px;
	padding-left: 275px;
}

.authors-box .author-inner .author {
	position: relative;
	color: #771db9;
	font-size: 16px;
	margin-bottom: 10px;
}

.authors-box .thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 250px;
	width: 275px;
	overflow: hidden;
}

.authors-box .thumb img {
	display: block;
	width: 100%;
	height: auto;
}

.authors-box .name {
	position: relative;
	display: block;
	font-size: 22px;
	line-height: 30px;
	color: #010101;
	font-weight: 600;
	margin-bottom: 5px;
	display: inline-block;
	margin-right: 25px;
}

.authors-box .text {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 30px;
	color: #777777;
	font-weight: 400;
	margin-bottom: 18px;
	line-height: 1.8em;
	margin-top: 6px;
}

.authors-box .social-icon {
	position: relative;
}

.authors-box .social-icon li {
	position: relative;
	display: inline-block;
	margin-right: 15px;
}

.authors-box .social-icon li:last-child {
	margin-right: 0;
}

.authors-box .social-icon li a {
	position: relative;
	display: block;
	font-size: 17px;
	font-weight: 400;
	color: #777777;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.authors-box .social-icon li a:hover {
	color: #1c17a0;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area {
	position: relative;
	margin-top: 0px;
	margin-bottom: 50px;
}

.sidebar-page-container .group-title {
	position: relative;
	margin-bottom: 40px;
}

.sidebar-page-container .group-title h4 {
	position: relative;
	color: #222222;
	font-weight: 600;
	padding-bottom: 12px;
	text-transform: capitalize;
}

.sidebar-page-container .group-title h4:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 50px;
	height: 2px;
	background-color: #771db9;
}

.sidebar-page-container .comments-area .comment-box {
	position: relative;
	margin-bottom: 30px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box:nth-child(1) {
	padding-top: 0px;
}

.sidebar-page-container .comments-area .comment-box:hover {
	border-color: #eb5310;
}

.sidebar-page-container .comments-area .comment {
	position: relative;
	font-size: 14px;
	padding: 0px 0px 0px 90px;
}

.sidebar-page-container .comments-area .comment .comment-inner {
	position: relative;
	padding: 15px 20px;
	border: 1px solid #f4f4f4;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating {
	position: absolute;
	right: 15px;
	top: 15px;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating .fa {
	display: inline-block;
	color: #fa9928;
}

.sidebar-page-container .comments-area .comment-box .author-thumb {
	position: absolute;
	left: 0px;
	top: 0px;
	margin-bottom: 20px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img {
	width: 60px;
	display: block;
}

.sidebar-page-container .comments-area .comment-info {
	position: relative;
	color: #222222;
	line-height: 24px;
	font-size: 16px;
	font-weight: 600;
}

.sidebar-page-container .comments-area .comment-box .text {
	color: #848484;
	font-size: 16px;
	margin-top: 8px;
	font-weight: 300;
	line-height: 1.6em;
	margin-bottom: 0px;
}

/*Comment Form*/

.comment-form {
	position: relative;
}

.comment-form .group-title {
	margin-bottom: 20px;
}

.comment-form .rating-box {
	position: relative;
	margin-bottom: 20px;
}

.comment-form .rating-box .text {
	position: relative;
	font-size: 14px;
	color: #222222;
	margin-bottom: 10px;
}

.comment-form .rating-box .rating {
	position: relative;
	display: inline-block;
	margin-right: 15px;
	padding-right: 15px;
	border-right: 1px solid #ebebeb;
}

.comment-form .rating-box .rating:last-child {
	border: 0px;
}

.comment-form .rating-box .rating .fa {
	position: relative;
	margin-right: 2px;
	display: inline-block;
}

.comment-form .rating-box .rating a {
	position: relative;
	color: #d6d6d6;
	font-size: 14px;
	display: inline-block;
}

.comment-form .rating-box .rating a:hover {
	color: #fa9928;
}

.comment-form .form-group {
	position: relative;
	margin-bottom: 15px;
}

.comment-form .form-group label {
	position: relative;
	color: #848484;
	font-size: 16px;
	font-weight: 300;
}

.comment-form .form-group:last-child {
	margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 15px;
	border: 1px solid #efefef;
	height: 50px;
	color: #848484;
	font-weight: 300;
	background: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
	border-color: #771db9;
}

.comment-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 26px;
	padding: 10px 15px;
	color: #848484;
	border: 1px solid #efefef;
	height: 105px;
	font-weight: 300;
	background: #ffffff;
	resize: none;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.comment-form button {
	position: relative;
	margin-top: 10px;
}

.comment-form input:focus,
.comment-form select:focus,
.comment-form textarea:focus {
	border-color: #f06529;
}

/*Error Section*/

.error-section {
	position: relative;
	padding: 100px 0px 80px;
}

.error-section .content {
	position: relative;
	text-align: center;
}

.error-section .error-image {
	position: relative;
	margin-bottom: 70px;
}

.error-section h2 {
	position: relative;
	color: #222222;
	font-weight: 700;
	line-height: 1.2em;
	margin-bottom: 10px;
}

.error-section .text {
	position: relative;
	color: #222222;
	font-size: 16px;
	font-weight: 300;
	margin-top: 20px;
	line-height: 1.5em;
	margin-bottom: 30px;
}

/*** 

====================================================================
	Contact Info Section
====================================================================

***/

.contact-info-section {
	position: relative;
	padding: 100px 0px 80px;
}

.contact-info-section .inner-container {
	position: relative;
	max-width: 770px;
	margin: 0 auto;
}

.contact-info-section .info-box {
	position: relative;
	margin-bottom: 40px;
}

.contact-info-section .info-box .box-inner {
	position: relative;
	color: #111111;
	font-size: 16px;
	padding-top: 20px;
	line-height: 1.8em;
	padding-left: 120px;
	min-height: 90px;
}

.contact-info-section .info-box .box-inner a {
	position: relative;
	color: #111111;
	font-size: 16px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.contact-info-section .info-box .box-inner a:hover {
	color: #761db9;
}

.contact-info-section .info-box .box-inner .icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 90px;
	height: 90px;
	color: #f35c60;
	line-height: 90px;
	font-size: 40px;
	border-radius: 50px;
	text-align: center;
	display: inline-block;
	background: #ffffff;
	background: -webkit-linear-gradient(to bottom, #ffffff 0%, #ECEBEC 100%);
	background: -moz-linear-gradient(to bottom, #ffffff 0%, #ECEBEC 100%);
	background: linear-gradient(to bottom, #ffffff 0%, #ECEBEC 100%);
}

.contact-info-section .social-box {
	position: relative;
}

.contact-info-section .social-box span {
	position: relative;
	color: #002222;
	font-size: 20px;
	font-weight: 700;
}

.contact-info-section .social-box span i {
	position: relative;
	color: #002222;
	font-size: 14px;
	font-weight: 400;
	display: block;
	font-style: normal;
	margin-top: 6px;
	margin-bottom: 15px;
}

.contact-info-section .social-box li {
	position: relative;
	margin-right: 8px;
	display: inline-block;
}

.contact-info-section .social-box li a {
	position: relative;
	width: 40px;
	height: 40px;
	display: block;
	color: #ffffff;
	line-height: 40px;
	text-align: center;
	border-radius: 0px;
	background-color: #fe4c1c;
}

.contact-info-section .social-box li.facebook a {
	background-color: #4661c5;
}

.contact-info-section .social-box li.twitter a {
	background-color: #44b1e4;
}

.contact-info-section .social-box li.linkedin a {
	background-color: #ff2e2e;
}

.contact-info-section .social-box li.pinterest a {
	background-color: #0073b0;
}

.contact-info-section .map-boxed {
	position: relative;
	margin-top: 60px;
}

.contact-form-section {
	position: relative;
	padding-bottom: 90px;
}

.contact-form-section .inner-container {
	position: relative;
	max-width: 970px;
	margin: 0 auto;
}

/* Faq Form Two */

.contact-form {
	position: relative;
}

.contact-form .form-group {
	position: relative;
	margin-bottom: 25px;
}

.contact-form .form-group .icon {
	position: absolute;
	right: 35px;
	top: 20px;
	color: #d048df;
	font-size: 16px;
	line-height: 1em;
	z-index: 1;
	font-weight: 600;
}

.contact-form .form-group:last-child {
	margin-bottom: 0px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 20px;
	color: #222222;
	height: 55px;
	font-size: 14px;
	background: #ffffff;
	font-weight: 400;
	border-radius: 3px;
	border: 1px solid transparent;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.10);
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group textarea:focus {
	border-color: #ea3268;
}

.contact-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 22px;
	padding: 15px 20px;
	color: #222222;
	height: 160px;
	background: #ffffff;
	resize: none;
	font-size: 14px;
	font-weight: 400;
	border-radius: 5px;
	border: 1px solid transparent;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.10);
}

.contact-form button {
	margin-top: 5px;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
	border-color: #ff0000 !important;
}

.contact-form label.error {
	display: block;
	line-height: 24px;
	padding: 5px 0px 0px;
	margin: 0px;
	text-transform: uppercase;
	font-size: 12px;
	color: #ff0000;
	font-weight: 500;
}

.shop-section .items-sorting {
	position: relative;
	margin-bottom: 10px;
}

.shop-section .items-sorting .results-column,
.shop-section .items-sorting .select-column {
	margin-bottom: 20px;
}

.shop-section .items-sorting .results-column h4 {
	font-size: 16px;
	font-weight: 300;
	color: #848484;
	line-height: 24px;
	padding: 13px 0px;
}

.shop-section .items-sorting .form-group {
	margin-bottom: 0px;
}

.shop-section .items-sorting select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 9px 15px 9px;
	height: 50px;
	font-size: 16px;
	border: 1px solid #eeeeee;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	background: url(../images/icons/arrow-right.jpg) right center no-repeat;
	color: #999999;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.shop-section .items-sorting select option {
	text-indent: 15px;
}

/* Shop Item */

.shop-item {
	position: relative;
	margin-bottom: 35px;
}

.shop-item .inner-box {
	position: relative;
	outline: 15px solid #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.shop-item .inner-box:before {
	content: '';
	position: absolute;
	left: -15px;
	right: -15px;
	bottom: -15px;
	top: -15px;
	opacity: 0;
	z-index: 10;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
	-moz-box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
	box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
}

.shop-item .inner-box:hover::before {
	opacity: 1;
}

.shop-item .inner-box:hover .image .overlay-box {
	opacity: 1;
}

.shop-item .inner-box .image {
	position: relative;
	z-index: 11;
	text-align: center;
	background-color: #f8f8f8;
}

.shop-item .inner-box .image .overlay-box {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	text-align: center;
	background-color: rgba(19, 29, 51, 0.80);
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.shop-item .inner-box .image .overlay-box .cart-option {
	position: relative;
	top: 50%;
	margin-top: -25px;
}

.shop-item .inner-box .image .overlay-box .cart-option li {
	position: relative;
	margin: 0px 3px;
	display: inline-block;
}

.shop-item .inner-box .image .overlay-box .cart-option li .tooltip-data {
	position: absolute;
	left: 20px;
	bottom: 100%;
	width: 130px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 300;
	text-align: center;
	opacity: 0;
	padding: 4px 15px;
	visibility: hidden;
	margin-left: -60px;
	margin-bottom: 0px;
	background-color: #e82a6a;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.shop-item .inner-box .image .overlay-box .cart-option li .tooltip-data:after {
	position: absolute;
	content: '';
	top: 100%;
	left: 50%;
	margin-left: -8px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 8px solid #e82a6a;
}

.shop-item .inner-box .image .overlay-box .cart-option li:hover .tooltip-data {
	opacity: 1;
	visibility: visible;
	margin-bottom: 20px;
}

.shop-item .inner-box .image .overlay-box .cart-option li button {
	position: relative;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: #e82a6a;
	font-size: 18px;
	border-radius: 50%;
	display: inline-block;
	background-color: #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.shop-item .inner-box .image .overlay-box .cart-option li button:hover {
	background-color: #e82a6a;
	color: #ffffff;
}

.shop-item .inner-box .image img {
	max-width: 100%;
	padding: 20px 0px;
	display: inline-block;
}

.shop-item .inner-box .lower-content {
	position: relative;
	z-index: 11;
	padding: 20px 0px 0px;
}

.shop-item .inner-box .lower-content h3 {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 4px;
}

.shop-item .inner-box .lower-content h3 a {
	color: #222222;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.shop-item .inner-box .lower-content h3 a:hover {
	color: #e82a6a;
}

.shop-item .inner-box .lower-content .price {
	position: relative;
	color: #e82a6a;
	font-size: 16px;
	font-weight: 400;
}

.shop-item .inner-box .lower-content .rating .fa {
	position: relative;
	display: inline-block;
	font-size: 12px;
	color: #e82a6a;
}

/*** 

====================================================================
	Cart Section style
====================================================================

***/

.cart-section {
	position: relative;
	padding: 80px 0px;
}

.cart-section .cart-outer {
	position: relative;
}

.cart-section .table-outer {
	position: relative;
	width: 100%;
	overflow-x: auto;
}

.cart-section .cart-table {
	width: 100%;
	min-width: 900px;
}

.cart-table .cart-header {
	position: relative;
	width: 100%;
	text-transform: uppercase;
	font-size: 16px;
	border-radius: 0px;
	background: #f7f7f7;
	color: #1f1f1f;
}

.cart-table thead tr th {
	line-height: 24px;
	padding: 20px 15px;
	min-width: 120px;
	font-weight: 400;
	color: #222222;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
}

.cart-table thead tr th.prod-column {
	text-align: left;
	padding-left: 40px;
}

.cart-table tbody tr td {
	line-height: 24px;
	padding: 30px 10px 20px;
	min-width: 100px;
}

.cart-table tbody tr .qty {
	width: 90px;
	padding-right: 40px;
}

.cart-table tbody tr .qty .quantity-spinner {
	background: #f5f5f5;
}

.cart-table tbody tr .prod-column .column-box {
	position: relative;
	min-height: 90px;
	padding-left: 90px;
	padding-top: 20px;
	text-align: left;
}


.column.default-column .cart-table tbody tr .qty {
	width: 110px;
	padding-right: 20px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
	position: absolute;
	width: 70px;
	border: 1px solid #f0f0f0;
	left: 0px;
	top: 0px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
	display: block;
	max-width: 100%;
}

.cart-table tbody tr .prod-column .column-box h3 {
	font-size: 16px;
	color: #222222;
	font-weight: 400;
	margin-bottom: 5px;
}

.cart-table tbody tr .sub-total {
	font-weight: 300;
	color: #e82a6a;
	font-size: 20px;
}

.cart-table tbody tr .cart-plus-minus {
	position: relative;
}

.cart-table tbody tr .cart-plus-minus .qtybutton {
	position: absolute;
	right: -8px;
	top: 0;
	background: #f4f4f4;
	width: 40px;
	min-width: 40px;
	height: 22px;
}

.cart-table tbody tr .cart-plus-minus .qtybutton.dec {
	top: auto;
	bottom: 0;
}

.cart-table tbody tr .remove-btn {
	position: relative;
	font-size: 18px;
	color: #ececec;
	width: 34px;
	height: 34px;
	text-align: center;
	line-height: 30px;
	display: inline-block;
	border: 2px solid #ececec;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	transition: all 500ms ease;
	background: none;
}

.cart-table tbody tr .remove-btn .fa {
	position: relative;
	top: 2px;
	font-size: 10px;
	line-height: 14px;
	width: 14px;
	height: 14px;
	margin-right: 8px;
	background: #f0f0f0;
	color: #ffffff;
	text-align: center;
	overflow: hidden;
	text-indent: 100px;
}

.cart-table tbody tr .remove {
	text-align: center;
}

.cart-table tbody tr .remove-btn:hover {
	color: #e82a6a;
	border-color: #e82a6a;
}

.cart-table tbody tr .remove-btn:hover .fa {
	text-indent: 0px;
	background: #e82a6a;
}

.cart-table tbody tr {
	border-bottom: 1px solid #f0f0f0;
}

.cart-table tbody tr td {
	vertical-align: middle;
}

.cart-table tbody tr td.price {
	font-size: 20px;
	font-weight: 300;
	color: #848484;
}

.cart-table tbody tr td .quantity-spinner {
	padding: 5px 0px 5px 20px;
	line-height: 24px;
	height: 34px;
	display: block;
	width: 100%;
	position: relative;
}

.cart-table tbody .available-info {
	position: relative;
	padding-left: 50px;
	color: #848484;
	font-size: 16px;
}

.cart-table tbody .available-info .icon {
	position: absolute;
	left: 0px;
	top: 5px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 18px;
	color: #ffffff;
	background: #e82a6a;
	border-radius: 50%;
}

.cart-section .coupon-outer {
	position: relative;
	margin: 50px 0px 70px;
}

.cart-section .coupon-outer .cart-btn {
	color: #222222;
	font-size: 14px;
	padding: 11px 33px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	transition: all 500ms ease;
}

.cart-section .coupon-outer .cart-btn:hover {
	color: #ffffff;
	background-color: #e82a6a;
}

.cart-section .apply-coupon {
	position: relative;
}

.cart-section .apply-coupon .form-group {
	position: relative;
	float: left;
	margin-right: 15px;
}

.cart-section .apply-coupon .form-group input[type="text"] {
	display: block;
	line-height: 26px;
	padding: 12px 20px;
	border: 2px solid #f7f7f7;
	height: 50px;
	width: 230px;
	background: none;
}

.cart-section .estimate-form {
	position: relative;
}

.cart-section .sec-title {
	margin-bottom: 15px;
}

.cart-section .sec-title h2 {
	font-size: 24px;
	color: #222222;
}

.cart-section .estimate-form .row {
	margin-left: -7px;
	margin-right: -7px;
}

.cart-section .estimate-form .row .form-group {
	padding: 0px 7px !important;
	margin-bottom: 30px;
}

.cart-section .totals-table {
	position: relative;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
	margin-bottom: 30px;
}

.cart-section .totals-table .col {
	position: relative;
	display: block;
	float: left;
	padding: 15px 15px;
	line-height: 24px;
	width: 50%;
	font-weight: 300;
	font-size: 16px;
	color: #848484;
}

.cart-section .totals-table .col-title {
	font-size: 16px;
	color: #222222;
	border-right: 1px solid #f0f0f0;
}

.cart-section .totals-table li {
	position: relative;
	border-bottom: 1px solid #f0f0f0;
}

.cart-section .totals-table li:last-child {
	border-bottom: none;
}

.cart-section .cart-note {
	position: relative;
	margin-bottom: 50px;
	padding: 16px 20px;
	text-align: center;
	border: 1px dashed #b2dd4c;
	background: #f4f4f4;
	line-height: 1.7em;
}

.product-details .basic-details .item-quantity input[type="text"],
.cart-table tbody tr .qty input[type="text"] {
	position: relative;
	line-height: 46px;
	height: 46px;
	background: #f4f4f4;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	height: 24px;
	background: #e9e9e9;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	left: 6px;
	top: 6px;
}

/*Shipping Form*/

.shipping-form .form-group {
	position: relative;
	margin-bottom: 30px;
	padding: 0px 15px;
}

.shipping-form .form-group .ui-selectmenu-button.ui-button {
	background-color: #ffffff;
	height: 45px;
	width: 100%;
	line-height: 30px;
	z-index: 10;
	border-radius: 0px;
	background-position: right top;
	background-repeat: no-repeat;
	background-image: url(../images/icons/arrow-right.jpg);
}

.shipping-form .form-group .ui-button .ui-icon {
	opacity: 0;
}

.shipping-form .form-group input,
.shipping-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 33px;
	padding: 10px 20px;
	height: 45px;
	font-size: 14px;
	font-weight: 300;
	background: #ffffff;
	border: 1px solid #f4f4f4;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	transition: all 500ms ease;
}

.shipping-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 30px;
	padding: 10px 20px;
	height: 120px;
	resize: none;
	font-size: 16px;
	font-weight: 300;
	background: #f7f7f7;
	border: 1px solid #f4f4f4;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	transition: all 500ms ease;
}

.shipping-form .form-group input:focus,
.shipping-form .form-group textarea:focus {
	border-color: #eb3867;
}

/*** 

====================================================================
	CheckOut Sectioon
====================================================================

***/

.checkout-page {
	position: relative;
	padding: 75px 0px 70px;
}

.checkout-page .default-links {
	position: relative;
	margin-bottom: 50px;
}

.checkout-page .default-links li {
	margin-bottom: 15px;
	line-height: 24px;
	padding: 18px 30px;
	background: #f7f7f7;
	color: #222222;
	font-size: 16px;
	border-left: 2px solid #e82a6a;
}

.checkout-page .default-links li a {
	color: #e82a6a;
}

.checkout-form {
	position: relative;
}

.checkout-form .checkout-title {
	position: relative;
}

.checkout-form .checkout-title h2 {
	position: relative;
	color: #222222;
	font-size: 24px;
	margin-bottom: 30px;
	padding-bottom: 12px;
}

.checkout-form .checkout-title h2:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 40px;
	height: 2px;
	background-color: #e82a6a;
}

.checkout-form .form-group {
	position: relative;
	margin-bottom: 25px;
}

.checkout-form .form-group .field-label {
	display: block;
	line-height: 24px;
	margin-bottom: 6px;
	text-transform: capitalize;
	color: #222222;
	font-size: 16px;
	font-weight: 400;
}

.checkout-form .form-group .field-label sup {
	top: -1px;
	/*color:#ff6600;*/
	font-size: 16px;
}

.checkout-form input[type="text"],
.checkout-form input[type="email"],
.checkout-form input[type="password"],
.checkout-form input[type="tel"],
.checkout-form input[type="number"],
.checkout-form input[type="url"],
.checkout-form select,
.checkout-form textarea {
	position: relative;
	display: block;
	width: 100%;
	background: #ffffff;
	line-height: 22px;
	padding: 12px 15px;
	height: 48px;
	border: 2px solid #f4f4f4;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.checkout-form textarea {
	resize: none;
	height: 120px !important;
}

.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus {
	border-color: #e82a6a;
}

.checkout-page .check-box {
	line-height: 24px;
	font-size: 14px;
	font-weight: normal;
	padding-top: 5px;
}

.checkout-page .check-box label {
	position: relative;
	top: -1px;
	font-weight: normal;
	padding: 0px;
	font-size: 16px;
	cursor: pointer;
	color: #e82a6a;
}

.checkout-page .lower-content {
	margin-top: 30px;
}

.checkout-page .lower-content .order-column {
	margin-bottom: 40px;
}

.checkout-page .lower-content .column h2 {
	position: relative;
	color: #222222;
	font-size: 24px;
	margin-bottom: 30px;
	padding-bottom: 12px;
}

.checkout-page .lower-content .column h2:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 40px;
	height: 2px;
	background-color: #e82a6a;
}

/*cart outer*/

.cart-outer {
	position: relative;
}

.cart-outer .table-outer {
	position: relative;
	width: 100%;
	overflow-x: auto;
}

.checkout-page .lower-content .order-column .cart-outer {
	overflow-x: auto;
}

.checkout-page .lower-content .order-column .cart-table {
	width: 100%;
}

.cart-table .cart-header {
	position: relative;
	width: 100%;
	text-transform: capitalize;
	font-size: 18px;
	background: #f7f7f7;
	color: #222222;
}

.cart-table thead tr th {
	line-height: 24px;
	padding: 13px 30px;
	font-weight: 400;
}

.cart-table thead tr th .fa {
	font-size: 18px;
}

.cart-table thead tr th.prod-column {
	text-align: left;
}

.cart-table tbody tr {
	border-bottom: 1px solid #eeeeee;
}

.cart-table tbody tr td {
	line-height: 24px;
	padding: 30px 0px 30px 10px;
}

.cart-table tbody tr .qty input {
	width: 45px;
	height: 50px;
	color: #222222;
	font-size: 18px;
	line-height: 50px;
	background: #f7f7f7;
	text-align: center;
}

.cart-table tbody tr .prod-column .column-box {
	position: relative;
	min-height: 70px;
	padding-left: 100px;
	padding-top: 20px;
	text-align: left;
	min-width: 230px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
	position: absolute;
	width: 80px;
	left: 0px;
	top: 0px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
	display: block;
	width: 100%;
}

.cart-table tbody tr .prod-column .column-box h4 {
	font-size: 16px;
	color: #222222;
	font-weight: 300;
	margin-bottom: 5px;
	text-transform: capitalize;
}

.cart-table tbody tr .sub-total {
	font-weight: 300;
	font-size: 20px;
	color: #e82a6a;
}

.cart-table tbody tr .price {
	font-weight: 700;
	font-size: 14px;
	color: #777777;
}

/*Total Table*/

.checkout-page .lower-content .totals-table {
	position: relative;
	margin-bottom: 40px;
	border: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li .col {
	padding: 12px 20px;
}

.checkout-page .lower-content .totals-table li {
	line-height: 24px;
	border-bottom: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li .col.title {
	font-weight: 500;
	color: #222222;
	font-size: 16px;
	border-right: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li:last-child {
	border-bottom: none;
	margin-bottom: 0px;
}

.checkout-page .lower-content .totals-table .col {
	position: relative;
	display: block;
	float: left;
	line-height: 24px;
	width: 50%;
	color: #848484;
	font-weight: 300;
	font-size: 16px;
}

/*Payment Option*/

.checkout-page .payment-options {
	position: relative;
	padding: 30px 20px;
	background-color: #f7f7f7;
}

.checkout-page .payment-options li {
	position: relative;
	margin-bottom: 15px;
}

.checkout-page .payment-options li .radio-option {
	position: relative;
}

.checkout-page .payment-options li .radio-option label {
	position: relative;
	display: inline-block;
	padding-left: 30px;
	font-weight: 500 !important;
	text-transform: capitalize;
	letter-spacing: 1px;
	color: #333333;
	cursor: pointer;
}

.checkout-page .payment-options li .radio-option label strong {
	font-weight: 400;
	color: #222222;
	font-size: 14px;
}

.checkout-page .payment-options li .radio-option label strong a {
	font-size: 16px;
	margin-left: 30px;
	text-decoration: underline;
}

.checkout-page .payment-options li .radio-option input[type="radio"] {
	position: absolute;
	left: 0px;
	top: 3px;
}

.checkout-page .payment-options li .radio-option label .small-text {
	display: block;
	padding-top: 5px;
	letter-spacing: 0px;
	text-transform: none;
	font-size: 16px;
	color: #848484;
	font-weight: 300;
	line-height: 1.7em;
}

.checkout-page .payment-options li .radio-option label img {
	position: relative;
	top: -3px;
	display: inline-block;
	max-width: 100%;
	padding-left: 30px;
}

.checkout-page .place-order {
	padding: 11px 39px;
	margin-left: 30px;
	text-transform: capitalize;
}

.product-details .basic-details {
	position: relative;
	margin-bottom: 20px;
}

.product-details .image-column,
.product-details .info-column {
	margin-bottom: 20px;
}

.product-details .image-column .image-box img {
	position: relative;
	display: block;
	width: 100%;
	background-color: #f7f7f7;
}

.product-details .basic-details .details-header {
	position: relative;
	margin-bottom: 16px;
}

.product-details .basic-details .details-header h4 {
	font-size: 24px;
	font-weight: 400;
	margin: -5px 0px 5px;
	line-height: 1.4em;
	color: #222222;
}

.product-details .basic-details .details-header h4 a {
	color: #333333;
}

.product-details .basic-details .details-header .rating {
	font-size: 14px;
	color: #fa9928;
	margin-bottom: 15px;
}

.product-details .basic-details .details-header .rating .fa {
	display: inline-block;
	margin-right: 2px;
}

.product-details .basic-details .details-header .rating .txt {
	font-size: 14px;
	padding-left: 10px;
	color: #777777;
}

.product-details .basic-details .details-header .item-price {
	font-size: 22px;
	font-weight: 400;
	color: #e82a6a;
	line-height: 24px;
	letter-spacing: 1px;
}

.product-details .basic-details .prod-info {
	margin-bottom: 15px;
	line-height: 1.6em;
	font-size: 13px;
}

.product-details .basic-details .prod-info strong {
	font-weight: 700;
	color: #111111;
}

.product-details .basic-details .text {
	margin-bottom: 25px;
	color: #848484;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.8em;
}

.product-details .basic-details .location {
	position: relative;
	color: #222222;
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 10px;
}

.product-details .basic-details .item-quantity .field-label {
	float: left;
	font-weight: 700;
	font-size: 14px;
	line-height: 32px;
	display: inline-block;
	padding-right: 20px;
}

.product-details .basic-details .quantity-spinner,
.cart-section input.quantity-spinner {
	line-height: 24px;
	padding: 10px 15px !important;
	height: 46px !important;
	box-shadow: none !important;
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical>.btn,
.cart-section .bootstrap-touchspin .input-group-btn-vertical>.btn {
	padding: 11px 10px;
	background: #f4f5f6;
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-section .bootstrap-touchspin .input-group-btn-vertical i {
	top: 6px;
}

.product-details .basic-details .item-quantity {
	position: relative;
	float: left;
	width: 85px;
	margin-bottom: 25px;
	margin-right: 20px;
}

.product-details .basic-details .item-quantity .field-label {
	float: left;
	font-weight: 700;
	font-size: 14px;
	line-height: 32px;
	display: inline-block;
	padding-right: 20px;
}

.product-details .basic-details .quantity-spinner,
.cart-table input.quantity-spinner {
	font-size: 18px;
	line-height: 24px;
	padding: 10px 15px !important;
	height: 54px !important;
	box-shadow: none !important;
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical>.btn,
.cart-table .bootstrap-touchspin .input-group-btn-vertical>.btn {
	padding: 0px 10px;
	background: none;
	border-radius: 0px;
	top: -5px;
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-table .bootstrap-touchspin .input-group-btn-vertical i {
	top: 8px;
}

.product-details .basic-details .add-to-cart {
	padding: 13px 37px;
}

.product-details .basic-details .expired {
	position: relative;
	color: #e82a6a;
	font-size: 16px;
	margin-bottom: 40px;
}

/*Pincode Form*/

.pincode-form {
	position: relative;
	margin-bottom: 15px;
}

.pincode-form .form-group {
	margin-bottom: 10px;
}

.pincode-form .form-group input[type="text"] {
	position: relative;
	line-height: 14px;
	padding: 4px 10px 4px 15px;
	border: 1px solid #f7f7f7;
	background: none;
	font-size: 16px;
	width: 170px;
	height: 40px;
	display: inline-block;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.pincode-form .form-group button {
	position: relative;
	height: 40px;
	width: 100px;
	cursor: pointer;
	font-size: 14px;
	color: #222222;
	font-weight: 500;
	line-height: 100%;
	background: #f7f7f7;
	margin-left: 8px;
	letter-spacing: 1px;
	display: inline-block;
	text-transform: uppercase;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.shop-single .product-details .prod-tabs {
	position: relative;
	margin-bottom: 60px;
}

.shop-single .product-details .prod-tabs .tab-btns {
	position: relative;
	z-index: 1;
	border-bottom: 0;
}

.shop-single .product-details .prod-tabs .tab-btns .nav-link {
	position: relative;
	top: 1px;
	display: block;
	float: left;
	margin-right: 5px;
	font-size: 16px;
	background: #f4f4f4;
	color: #222222;
	border: 1px solid #f4f4f4;
	text-transform: capitalize;
	font-weight: 400;
	line-height: 24px;
	cursor: pointer;
	padding: 12px 18px 10px;
	transition: all 500ms ease;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: -1px;
	width: 100%;
	height: 1px;
	background-color: #ffffff;
}

.shop-single .product-details .prod-tabs .tab-btns .nav-link:hover,
.shop-single .product-details .prod-tabs .tab-btns .nav-link.active {
	color: #ffffff;
	background: #e82a6a;
}

.shop-single .product-details .prod-tabs .tabs-content {
	position: relative;
	padding: 30px 30px 40px;
	border: 1px solid #f0f0f0;
	border-top: 0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab {
	position: relative;
	border-top: 0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab.active-tab {
	display: block;
}

.prod-tabs .tabs-content .tab .title {
	position: relative;
	color: #3d3d3d;
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	margin-bottom: 25px;
}

/*Comment Form*/

.shop-comment-form {
	position: relative;
}

.shop-comment-form h2 {
	position: relative;
	color: #3d3d3d;
	font-size: 24px;
	margin-bottom: 25px;
}

.shop-comment-form .group-title {
	margin-bottom: 20px;
}

.shop-comment-form .rating-box {
	position: relative;
	margin-bottom: 30px;
}

.shop-comment-form .rating-box .text {
	position: relative;
	font-size: 16px;
	color: #848484;
	margin-bottom: 10px;
}

.shop-comment-form .rating-box .rating {
	position: relative;
	display: inline-block;
	margin-right: 10px;
	padding-right: 10px;
	border-right: 1px solid #ebebeb;
}

.shop-comment-form .rating-box .rating:last-child {
	border-right: 0px;
}

.shop-comment-form .rating-box .rating .fa {
	position: relative;
	margin-right: 2px;
	display: inline-block;
}

.shop-comment-form .rating-box .rating a {
	position: relative;
	color: #d6d6d6;
	font-size: 14px;
	display: inline-block;
}

.shop-comment-form .rating-box .rating a:hover {
	color: #fa9928;
}

.shop-comment-form .form-group {
	position: relative;
	margin-bottom: 15px;
}

.shop-comment-form .form-group label {
	position: relative;
	color: #848484;
	font-size: 16px;
	font-weight: 300;
}

.shop-comment-form .form-group:last-child {
	margin-bottom: 0px;
}

.shop-comment-form .form-group input[type="text"],
.shop-comment-form .form-group input[type="password"],
.shop-comment-form .form-group input[type="tel"],
.shop-comment-form .form-group input[type="email"],
.shop-comment-form .form-group select {
	position: relative;
	display: block;
	width: 100%;
	line-height: 28px;
	padding: 10px 15px;
	border: 1px solid #efefef;
	height: 50px;
	color: #848484;
	font-weight: 300;
	background: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus {
	border-color: #eb3867;
}

.shop-comment-form .form-group textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 26px;
	padding: 10px 15px;
	color: #848484;
	border: 1px solid #efefef;
	height: 105px;
	font-weight: 300;
	background: #ffffff;
	resize: none;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.shop-comment-form button {
	position: relative;
	margin-top: 10px;
}

.shop-comment-form input:focus,
.shop-comment-form select:focus,
.shop-comment-form textarea:focus {
	border-color: #f06529;
}

/*Related Products*/

.related-products {
	position: relative;
}

.related-products h2 {
	position: relative;
	color: #222222;
	font-size: 24px;
	font-weight: 400;
	padding-bottom: 12px;
	margin-bottom: 40px;
}

.related-products h2:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 50px;
	height: 2px;
	background-color: #eb3867;
}

.bootstrap-touchspin .input-group-btn-vertical {
	width: auto;
	right: 4px;
	position: absolute;
}

.bootstrap-touchspin .glyphicon-chevron-up:before {
	content: "\f106";
	font-size: 18px;
	font-style: normal;
	font-family: 'FontAwesome';
}

.bootstrap-touchspin .glyphicon-chevron-down:before {
	content: "\f107";
	font-size: 18px;
	font-style: normal;
	font-family: 'FontAwesome';
}

/*** 

====================================================================
	Login / Register Section
====================================================================

***/

.register-section {
	position: relative;
	padding: 80px 0px 0px;
}

.register-section .form-column {
	margin-bottom: 80px;
}

.register-section .form-column .form-title {
	position: relative;
	margin-bottom: 40px;
}

.register-section .form-column .form-title h2 {
	color: #222222;
	font-size: 24px;
	padding-bottom: 12px;
}

.register-section .form-column .form-title h2:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 50px;
	height: 2px;
	background-color: #eb3867;
}

/* RTL Code */

.rtl .main-menu .navigation>li,
.rtl .main-header .header-upper .logo-box {
	float: right;
}

.rtl .main-header .main-menu {
	float: right;
}

.rtl .main-header .outer-box {
	float: left;
	margin-left: 0px;
	margin-right: 30px;
}

.rtl .main-header .nav-outer {
	float: left;
}

.rtl .main-menu .navigation>li:last-child {
	margin-right: 30px;
}

.rtl .main-menu .navigation>li:first-child {
	margin-right: 0px;
}

.rtl .main-menu .navigation>li>ul {
	left: auto;
	right: 30px;
	text-align: right;
}

.rtl .main-menu .navigation>li.dropdown:hover>ul {
	left: auto;
	right: 0px;
}

.rtl .main-menu .navigation>li>ul>li:hover>a {
	padding-left: 18px;
	padding-right: 30px;
}

.rtl .main-menu .navigation>li>ul>li>a:before {
	right: 10px;
	left: auto;
}

.rtl .main-menu .navigation>li>ul>li.dropdown>a:after {
	right: auto;
	left: 15px;
}

.rtl .main-menu .navigation>li>ul>li>ul {
	left: auto;
	right: 120%;
}

.rtl .main-menu .navigation li>ul>li.dropdown:hover>ul {
	left: auto;
	right: 100%;
}

.rtl .demo-section .counter-column,
.rtl .skill-section .counter-column,
.rtl .analysis-section .title-column,
.rtl .banner-section-three .content-column,
.rtl .faq-section .accordian-column,
.rtl .call-to-action-section .title-column,
.rtl .about-section .content-column,
.rtl .banner-section .content-column,
.rtl .newsletter-section .form-column,
.rtl .faq-form-section .form-column {
	order: 2;
	text-align: right;
}

.rtl .faq-form-section .image-column,
.rtl .newsletter-section .image-column,
.rtl .demo-section .image-column,
.rtl .skill-section .image-column,
.rtl .analysis-section .image-column,
.rtl .banner-section-three .image-column,
.rtl .faq-section .image-column,
.rtl .call-to-action-section .image-column,
.rtl .about-section .image-column,
.rtl .banner-section .image-column {
	order: 1;
}

.rtl .about-section .content-column .inner-column .text {
	padding-right: 0px;
	padding-left: 60px;
}

.rtl .about-section .content-column .bold-text {
	padding-right: 0px;
	padding-left: 20px;
}

.rtl .newsletter-form .form-group input[type="text"],
.rtl .newsletter-form .form-group input[type="email"] {
	text-align: right;
	padding-right: 25px;
}

.rtl .faq-form .form-group input[type="text"],
.rtl .faq-form .form-group input[type="email"],
.rtl .faq-form .form-group select,
.rtl .faq-form .form-group textarea,
.rtl .counter-section-three .counter-column .inner-column,
.rtl .main-footer .footer-widget,
.rtl .news-block .inner-box .lower-content,
.rtl .service-block-two .inner-box {
	text-align: right;
}

.rtl .news-block .inner-box .lower-content .post-meta li a {
	padding-left: 0px;
	padding-right: 25px;
	text-align: right;
}

.rtl .news-block .inner-box .lower-content .post-meta li a .icon {
	right: 0px;
	left: auto;
}

.rtl .news-section .sec-title .pull-left {
	float: right;
	text-align: right;
}

.rtl .news-section .sec-title .pull-right {
	float: left;
}

.rtl .list-style-two li .icon,
.rtl .main-header .header-top .info-list li .icon,
.rtl .main-footer .footer-widget h4:before {
	right: 0px;
	left: auto;
}

.rtl .list-link li a {
	padding-left: 0px;
	padding-right: 20px;
	text-align: right;
}

.rtl .list-link li a:before {
	right: 0px;
	left: auto;
	transform: rotate(180deg);
}

.rtl .list-style-two li {
	padding-left: 0px;
	padding-right: 30px;
	text-align: right;
}

.rtl .main-header .header-top {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
}

.rtl .gallery-section .sec-title .pull-left {
	float: right;
	text-align: right;
}

.rtl .gallery-section .filters li,
.rtl .main-header .header-top .top-left {
	float: right;
}

.rtl .gallery-section .sec-title .pull-right,
.rtl .main-header .header-top .top-right {
	float: left;
}

.rtl .main-header .header-top .info-list li {
	padding-left: 0px;
	text-align: right;
	padding-right: 25px;
}

.rtl .header-style-two:before {
	left: 0px;
	right: auto;
}

.rtl .main-header .header-top .inner-container {
	padding-right: 15px;
	padding-left: 15px;
}

.rtl .header-style-two .header-upper {
	background-color: #0028a1;
}

.rtl .gallery-block .inner-box .lower-content,
.rtl .banner-section-two .content-column .inner-column {
	text-align: right;
}

.rtl .banner-section-two .content-column .theme-btn {
	float: right;
}

.rtl .banner-section-two .content-column .phone-box {
	float: right;
	margin-left: 0px;
	margin-right: 30px;
}

.rtl .accordion-box .block,
.rtl .service-block-four .inner-box,
.rtl .footer-style-two .footer-widget,
.rtl .mission-section .content-column .inner-column,
.rtl .news-block-two .inner-box .lower-content,
.rtl .news-block-two .inner-box .lower-content h5,
.rtl .news-block-two .inner-box .lower-content .text,
.rtl .news-block-two .inner-box .lower-content .post-meta,
.rtl .privacy-section .privacy-content,
.rtl .call-to-action-section-two .title-column .inner-column,
.rtl .services-section-three .content-column .inner-column,
.rtl .testimonial-block-three .inner-box,
.rtl .service-block-three .inner-box {
	text-align: right;
}

.rtl .service-block-three .inner-box .icon-box {
	background-position: right center;
}

.rtl .list-style-three li {
	padding-left: 0px;
	padding-right: 30px;
	text-align: right;
}

.rtl .list-style-three li:before {
	right: 0px;
	left: auto;
}

.rtl .fact-counter-two .column .inner .content {
	padding-left: 0px;
	padding-right: 80px;
	text-align: right;
}

.rtl .fact-counter-two .column .inner .icon {
	right: 0px;
	left: auto;
}

.rtl .testimonial-block-two .inner-box .author-image-outer {
	padding-left: 0px;
	padding-right: 90px;
	text-align: right;
}

.rtl .testimonial-block-two .inner-box .author-image-outer .author-image {
	right: 0px;
	left: auto;
}

.rtl .testimonial-block-two .inner-box .author-image-outer .quote-icon {
	left: 0px;
	right: auto;
}

.rtl .accordion-box .block .icon {
	left: 10px;
	right: auto;
}

.rtl .accordion-box .block .acc-btn {
	padding-right: 30px;
}

.rtl .news-block-two .inner-box .lower-content .post-meta li a {
	padding-left: 0px;
	padding-right: 25px;
	text-align: right;
}

.rtl .news-block-two .inner-box .lower-content .post-meta li a .icon {
	right: 0px;
	left: auto;
}

.rtl .seo-form .form-group {
	margin-right: 0px;
	margin-left: 160px;
}

.rtl .seo-form .theme-btn {
	left: 0px;
	right: auto;
}

.rtl .list-link-two li a {
	padding-left: 0px;
	padding-right: 15px;
	text-align: right;
}

.rtl .list-link-two li a:before {
	right: 0px;
	left: auto;
	transform: rotate(180deg);
}

.rtl .banner-section-three .image-column .image {
	margin-right: 0px;
	margin-left: -200px;
}

.rtl .banner-section-three .social-box {
	display: none;
}

.rtl .banner-section-three .content-column .video-box {
	float: right;
}

.rtl .banner-section-three .content-column .theme-btn {
	float: right;
	margin-left: 0px;
	margin-right: 30px;
}

.rtl .mission-section .content-column .mission-list li {
	text-align: right;
	padding-left: 0px;
	padding-right: 90px;
}

.rtl .mission-section .content-column .mission-list li .icon {
	right: 0px;
	left: auto;
}

.rtl .skill-section .image-column .image {
	margin-right: 0px;
	margin-left: -150px;
}

.rtl .demo-section .image-column .image {
	margin-right: 0px;
	margin-left: -150px;
}

.rtl .demo-section .counter-column .text {
	max-width: 100%;
}

.rtl .testimonial-block-three .inner-box .author-image-outer {
	padding-left: 0px;
	padding-right: 130px;
	text-align: right;
}

.rtl .testimonial-block-three .inner-box .author-image-outer .author-image {
	right: 0px;
	left: auto;
}

.rtl .sticky-header .main-menu .navigation>li:last-child {
	margin-right: 0px;
}

.rtl .team-single-section .image-column {
	order: 2;
}

.rtl .team-single-section .content-column {
	order: 1;
	text-align: right;
}

.rtl .team-single-section .content-column .post-meta li {
	text-align: right;
	padding-left: 0px;
	padding-right: 30px;
}

.rtl .team-single-section .content-column .post-meta li .icon {
	right: 0px;
	left: auto;
	margin-right: 0px;
}

.rtl .coming-soon .emailed-form .form-group input[type="submit"],
.rtl .coming-soon .emailed-form button {
	left: 0px;
	right: auto;
}

.rtl .coming-soon .emailed-form .form-group input[type="text"],
.rtl .coming-soon .emailed-form .form-group input[type="tel"],
.rtl .coming-soon .emailed-form .form-group input[type="email"],
.rtl .coming-soon .emailed-form .form-group textarea {
	text-align: right;
	padding-right: 20px;
}

.rtl .privacy-section .privacy-list li {
	padding-left: 0px;
	padding-right: 26px;
	text-align: right;
}

.rtl .help-widget .widget-content .help-list li a .icon,
.rtl .privacy-section .privacy-list li:before {
	right: 0px;
	left: auto;
}

.rtl .help-widget .widget-content,
.rtl .service-detail .inner-box .lower-content,
.rtl .sidebar .service-list li a {
	text-align: right;
}

.rtl .sidebar .service-list li a:before {
	left: 20px;
	right: auto;
}

.rtl .help-widget .widget-content .help-list li a {
	padding-left: 0px;
	padding-right: 30px;
	text-align: right;
}

.rtl .sidebar .search-box .form-group button {
	left: 0px;
	right: auto;
	border-radius: 5px 0px 0px 5px;
}

.rtl .sidebar .search-box .form-group input[type="text"],
.rtl .sidebar .search-box .form-group input[type="search"] {
	text-align: right;
	padding-right: 20px;
}

.rtl .popular-tags,
.rtl .blog-cat li a,
.rtl .sidebar-title {
	text-align: right;
}

.rtl .blog-cat li a span {
	left: 20px;
	right: auto;
}

.rtl .sidebar .popular-posts .post {
	padding-left: 0px;
	text-align: right;
	padding-right: 130px;
}

.rtl .sidebar-page-container .group-title h4:after,
.rtl .register-section .form-column .form-title h2:after,
.rtl .checkout-page .lower-content .column h2:after,
.rtl .checkout-form .checkout-title h2:after,
.rtl .sidebar .popular-posts .post .post-thumb {
	right: 0px;
	left: auto;
}

.rtl .comment-form,
.rtl .gallery-detail-section .inner-box,
.rtl .shop-section .items-sorting .form-group,
.rtl .gallery-detail-section .upper-box .social-box,
.rtl .pincode-form .form-group input[type="text"],
.rtl .gallery-detail-section .upper-box .list,
.rtl .shop-comment-form .form-group input[type="text"],
.rtl .shop-comment-form .form-group input[type="password"],
.rtl .shop-comment-form .form-group input[type="tel"],
.rtl .shop-comment-form .form-group input[type="email"],
.rtl .shop-comment-form .form-group select,
.rtl .shop-comment-form .form-group textarea,
.rtl .cart-section .apply-coupon .form-group input[type="text"],
.rtl .shipping-form .form-group input,
.rtl .shipping-form .form-group select,
.rtl .cart-section .totals-table .col,
.rtl .checkout-page .default-links li,
.rtl .checkout-form .checkout-title h2,
.rtl .checkout-form .form-group .field-label,
.rtl .checkout-form input[type="text"],
.rtl .checkout-form input[type="email"],
.rtl .checkout-form input[type="password"],
.rtl .checkout-form input[type="tel"],
.rtl .checkout-form input[type="number"],
.rtl .checkout-form input[type="url"],
.rtl .checkout-form select,
.rtl .checkout-form textarea,
.rtl .news-block-four .inner-box,
.rtl .checkout-page .lower-content .column h2,
.rtl .checkout-page .payment-options li,
.rtl .news-detail .inner-box .lower-content,
.rtl .news-block-three .inner-box .lower-content,
.rtl .register-section .form-column .form-title h2,
.rtl .sidebar-page-container .comments-area .comment-box,
.rtl .checkout-page .lower-content .totals-table li .col,
.rtl .shop-single .product-details .prod-tabs .tabs-content .tab,
.rtl .styled-form input[type="text"],
.rtl .styled-form input[type="email"],
.rtl .styled-form input[type="password"],
.rtl .styled-form input[type="tel"],
.rtl .styled-form input[type="number"],
.rtl .styled-form input[type="url"],
.rtl .styled-form select,
.rtl .styled-form textarea,
.rtl .comment-form .form-group input[type="text"],
.rtl .comment-form .form-group input[type="password"],
.rtl .comment-form .form-group input[type="tel"],
.rtl .comment-form .form-group input[type="email"],
.rtl .comment-form .form-group select,
.rtl .comment-form .form-group textarea,
.rtl .contact-form .form-group input[type="text"],
.rtl .contact-form .form-group input[type="tel"],
.rtl .contact-form .form-group input[type="email"],
.rtl .contact-form .form-group select,
.rtl .contact-form .form-group textarea {
	text-align: right;
}

.rtl .shop-item .inner-box .lower-content .pull-left {
	float: right;
}

.rtl .shop-item .inner-box .lower-content .pull-right {
	float: left;
}

.rtl .product-details .image-column {
	order: 2;
}

.rtl .product-details .info-column {
	order: 1;
	text-align: right;
}

.rtl .product-details .basic-details .item-quantity {
	float: right;
	margin-right: 0px;
	margin-left: 20px;
}

.rtl .sidebar-page-container .comments-area .comment .comment-inner .rating {
	left: 15px;
	right: auto;
}

.rtl .contact-form .form-group .icon {
	left: 35px;
	right: auto;
}

.rtl .styled-form .form-group .adon-icon {
	left: 20px;
	right: auto;
}

.rtl .news-block-three .inner-box .lower-content .post-meta li a {
	text-align: right;
	padding-left: 0px;
	padding-right: 25px;
}

.rtl .authors-box .thumb,
.rtl .news-detail .inner-box .lower-content .post-meta li .icon,
.rtl .news-block-three .inner-box .lower-content .post-meta li a .icon {
	right: 0px;
	left: auto;
}

.rtl .news-block-three .inner-box .lower-content .btn-box .btn-box-inner {
	padding-right: 0px;
	padding-left: 15px;
}

.rtl .news-detail .inner-box .lower-content .post-meta li {
	text-align: right;
	padding-left: 0px;
	padding-right: 25px;
}

.rtl .authors-box .author-inner {
	text-align: right;
	padding-left: 0px;
	padding-right: 275px;
}

.rtl .contact-info-section .info-box .box-inner {
	padding-left: 0px;
	padding-right: 120px;
	text-align: right;
}

.rtl .related-products h2:after,
.rtl .list-style-one li .icon,
.rtl .contact-info-section .info-box .box-inner .icon {
	right: 0px;
	left: auto;
}

.rtl .related-products h2,
.rtl .contact-info-section .social-box {
	text-align: right;
}

.rtl .cart-section .sec-title h2,
.rtl .sidebar-info-contents .content-inner {
	text-align: right;
}

.rtl .list-style-one li {
	padding-left: 0px;
	padding-right: 30px;
	text-align: right;
}

.rtl .cart-table tbody .available-info {
	padding-left: 0px;
	padding-right: 50px;
	text-align: right;
}

.rtl .cart-table tbody .available-info .icon {
	right: 0px;
	left: auto;
}

.rtl .banner-section-four .content-column {
	order: 2;
	text-align: right;
}

.rtl .banner-section-four .image-column {
	order: 1;
}

.rtl .banner-section-four .image-column .inner-column {
	margin-right: 0px;
	margin-left: -60px;
}

.rtl .banner-section-four .image-column .images-icons .image-2 {
	right: -80px !important;
}

.rtl .service-block-two.style-two .inner-box {
	text-align: center;
}

.rtl .team-block-two .inner-box .lower-content {
	text-align: right;
	padding-right: 60px;
}

.rtl .main-header .cart-box .cart-panel {
	left: 0px !important;
	right: auto;
}

.rtl .main-header .cart-product .inner {
	padding-left: 0px;
	padding-right: 90px;
	text-align: right;
}

.rtl .main-header .cart-product .inner .image {
	right: 0px;
	left: auto;
}

.rtl .main-header .cart-product .inner .cross-icon {
	left: 0px;
	right: auto;
}

.rtl .banner-section-five .content-column {
	order: 2;
	text-align: right;
}

.rtl .banner-section-five .content-column .inner-column:before {
	left: 0px;
	right: auto;
}

.rtl .banner-section-five .content-column .phone-box {
	float: right;
	margin-left: 0px;
	margin-right: 25px;
}

.rtl .banner-section-five .content-column .theme-btn {
	float: right;
}

.rtl .banner-section-five .image-column {
	order: 1;
}

.rtl .seo-form-two .form-group input,
.rtl .seo-section-two .form-column .inner-column {
	text-align: right;
}

.rtl .business-block .inner-box {
	padding-left: 0px;
	text-align: right;
	padding-right: 225px;
}

.rtl .business-block .inner-box .box-number {
	right: 0px;
	left: auto;
}

.rtl .business-block .inner-box .icon-box {
	left: auto;
	right: 130px;
}

.rtl .banner-section-six .content-column {
	order: 2;
	text-align: right;
}

.rtl .banner-section-six .image-column {
	order: 1;
}

.rtl .banner-section-six .image-column .image {
	margin-right: 0px;
}

.rtl .banner-section-six .content-column h1,
.rtl .banner-section-six .content-column .text,
.rtl .banner-section-six .content-column .title {
	color: #ffffff;
}

.rtl .business-section {
	text-align: right;
}

.want-sticky-header .main-header .sticky-header {
	position: relative;
	display: none;
}

/* Dark Mode */

.dark-body,
.dark-body .clients-section.style-two,
.dark-body .main-menu .navigation>li>ul,
.dark-body .main-menu .navigation>li>ul>li>ul,
.dark-body .main-header .header-top .inner-container {
	background-color: #2d3049;
}

.dark-body .shop-item .inner-box {
	outline-color: #2d3049;
}

.dark-body .main-slider {
	background-color: #393d5c;
}

.dark-body .clients-section,
.dark-body .pricing-section,
.dark-body .team-section:before,
.dark-body .counter-section:before,
.dark-body .services-section-two:before,
.dark-body .call-to-action-section,
.dark-body .testimonial-block-two .inner-box,
.dark-body .news-block-three .inner-box .lower-content .btn-box:before,
.dark-body .services-section-four .owl-dots .owl-dot.active,
.dark-body .services-section-four .owl-dots .owl-dot:hover {
	background-color: #393d5c;
}

.dark-body .news-block-three .inner-box .lower-content .btn-box .btn-box-inner {
	background-color: #2d3049;
}

.dark-body .shop-comment-form .form-group input[type="text"],
.dark-body .shop-comment-form .form-group input[type="password"],
.dark-body .shop-comment-form .form-group input[type="tel"],
.dark-body .shop-comment-form .form-group input[type="email"],
.dark-body .shop-comment-form .form-group select,
.dark-body .shop-comment-form .form-group textarea,
.dark-body .newsletter-form .form-group input[type="text"],
.dark-body .newsletter-form .form-group input[type="email"],
.dark-body .cart-section .apply-coupon .form-group input[type="text"],
.dark-body .checkout-form input[type="text"],
.dark-body .checkout-form input[type="email"],
.dark-body .checkout-form input[type="password"],
.dark-body .checkout-form input[type="tel"],
.dark-body .checkout-form input[type="number"],
.dark-body .checkout-form input[type="url"],
.dark-body .checkout-form select,
.dark-body .checkout-form textarea,
.dark-body .styled-form input[type="text"],
.dark-body .styled-form input[type="email"],
.dark-body .styled-form input[type="password"],
.dark-body .styled-form input[type="tel"],
.dark-body .styled-form input[type="number"],
.dark-body .styled-form input[type="url"],
.dark-body .styled-form select,
.dark-body .styled-form textarea,
.dark-body .comment-form .form-group input[type="text"],
.dark-body .comment-form .form-group input[type="password"],
.dark-body .comment-form .form-group input[type="tel"],
.dark-body .comment-form .form-group input[type="email"],
.dark-body .comment-form .form-group select,
.dark-body .comment-form .form-group textarea,
.dark-body .contact-form .form-group input[type="text"],
.dark-body .contact-form .form-group input[type="tel"],
.dark-body .contact-form .form-group input[type="email"],
.dark-body .contact-form .form-group textarea {
	background-color: #393d5c;
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.10);
}

.dark-body .faq-form .form-group input[type="text"],
.dark-body .faq-form .form-group input[type="email"],
.dark-body .faq-form .form-group select,
.dark-body .faq-form .form-group textarea {
	color: #ffffff;
	background-color: #393d5c;
}

.dark-body .services-section:before {
	background: #393d5c;
	background: -webkit-linear-gradient(to bottom, #393d5c 0%, #2D3049 100%);
	background: -moz-linear-gradient(to bottom, #393d5c 0%, #2D3049 100%);
	background: linear-gradient(to bottom, #393d5c 0%, #2D3049 100%);
}

.dark-body .clients-section-two .inner-container,
.dark-body .price-block .inner-box,
.dark-body .service-block-three .inner-box,
.dark-body .accordion-box .block,
.dark-body .news-block-two .inner-box,
.dark-body .help-widget .widget-content,
.dark-body .team-block .inner-box .lower-content,
.dark-body .testimonial-block .inner-box:before,
.dark-body .service-block .inner-box,
.dark-body .testimonial-block-three .inner-box,
.dark-body .service-block-five .inner-box,
.dark-body .banner-section-five .social-box,
.dark-body .header-style-two .nav-outer:before {
	background-color: #22253c;
}

.dark-body .authors-box .name,
.dark-body .authors-box .text,
.dark-body .authors-box .social-icon li a,
.dark-body .styled-form .remember-me,
.dark-body .blog-cat li a,
.dark-body .sidebar .popular-tags a,
.dark-body .sidebar-title h4,
.dark-body .privacy-section h2,
.dark-body .privacy-section p,
.dark-body .contact-info-widget ul li,
.dark-body .contact-info-widget ul li a,
.dark-body .footer-style-two .footer-widget h4,
.dark-body .list-link-two li a,
.dark-body .list-style-three li,
.dark-body .sec-title .title,
.dark-body .sec-title h2,
.dark-body .styled-form .form-group,
.dark-body .related-products h2,
.dark-body .shop-comment-form h2,
.dark-body .privacy-section h4,
.dark-body .sec-title .text,
.dark-body .styled-form .form-group .adon-icon,
.dark-body .help-widget .widget-content h4,
.dark-body .sidebar .service-list li a,
.dark-body .privacy-section .privacy-list li,
.dark-body .accordion-box .block .acc-btn,
.dark-body .gallery-section .filters li,
.dark-body .sidebar .popular-posts .post .text a,
.dark-body .sidebar .popular-posts .post-info,
.dark-body .footer-style-two .app-widget .social-box li.follow,
.dark-body .news-block .inner-box .lower-content .post-meta li a,
.dark-body .news-block .inner-box .lower-content h5 a,
.dark-body .price-block .inner-box .price-list li,
.dark-body .price-block .inner-box .price,
.dark-body .testimonial-block .inner-box .lower-box h6,
.dark-body .testimonial-block .inner-box .lower-box .designation,
.dark-body .testimonial-block .inner-box .text,
.dark-body .call-to-action-section .title-column h2,
.dark-body .call-to-action-section .title-column .text,
.dark-body .about-section .content-column .bold-text,
.dark-body .about-section .content-column .inner-column .text,
.dark-body .service-block .inner-box .text,
.dark-body .service-block .inner-box h5 a,
.dark-body .main-header .header-top .top-left .text,
.dark-doby .main-header .header-top .info-list li,
.dark-body .main-header .header-top .info-list li,
.dark-body .main-header .header-top .info-list li a,
.dark-body .banner-section-two .content-column .title,
.dark-body .banner-section-two .content-column h1,
.dark-body .banner-section-two .content-column .text,
.dark-body .team-block .inner-box .lower-content .content h4 a,
.dark-body .main-menu .navigation>li>ul>li>a,
.dark-body .main-menu .navigation>li>ul>li>ul>li>a,
.dark-body .main-menu .navigation>li>ul>li.dropdown>a:after,
.dark-body .team-block .inner-box .lower-content .content .designation,
.dark-body .service-block .inner-box .icon-box,
.dark-body .accordion-box .block .content .text,
.dark-body .fact-counter-two .column .inner .count-outer,
.dark-body .service-block-three .inner-box:hover h5 a,
.dark-body .service-block-three .inner-box:hover .text,
.dark-body .fact-counter-two .column .inner .counter-title,
.dark-body .services-section-three .content-column .bold-text,
.dark-body .gallery-block .inner-box .lower-content h5 a,
.dark-body .testimonial-block-two .inner-box .text,
.dark-body .news-block-two .inner-box .lower-content .post-meta li a,
.dark-body .news-block-two .inner-box .lower-content h5 a,
.dark-body .news-block-two .inner-box .lower-content .text,
.dark-body .footer-style-two .logo-widget .text,
.dark-body .service-block-five .inner-box h5 a,
.dark-body .service-block-five .inner-box .text,
.dark-body .service-block-five .inner-box .learn-more,
.dark-body .analysis-section .title-column .text,
.dark-body .counter-section-three .counter-column .text,
.dark-body .fact-counter-three .column .inner .count-outer,
.dark-body .fact-counter-three .column .inner .counter-title,
.dark-body .skill-section .counter-column .text,
.dark-body .skills .skill-item .skill-header .skill-title,
.dark-body .skills .skill-item .skill-header .skill-percentage,
.dark-body .mission-section .content-column .bold-text,
.dark-body .mission-section .content-column .mission-list li,
.dark-body .testimonial-block-three .inner-box .author-image-outer h6,
.dark-body .testimonial-block-three .inner-box .text,
.dark-body .newsletter-section .form-column .text,
.dark-body .team-single-section .content-column h2,
.dark-body .team-single-section .content-column .post-meta li a,
.dark-body .team-single-section .content-column .text p,
.dark-body .service-detail .inner-box .lower-content p,
.dark-body .service-detail .inner-box .lower-content h4,
.dark-body .help-widget .widget-content .text,
.dark-body .help-widget .widget-content .help-list li a,
.dark-body .gallery-detail-section .upper-box .list li,
.dark-body .gallery-detail-section .upper-box .list li strong,
.dark-body .gallery-detail-section .inner-box h3,
.dark-body .gallery-detail-section .inner-box p,
.dark-body .gallery-detail-section .inner-box blockquote,
.dark-body .gallery-detail-section .inner-box h4,
.dark-body .gallery-detail-section .inner-box p span,
.dark-body .shop-item .inner-box .lower-content h3 a,
.dark-body .product-details .basic-details .details-header h4,
.dark-body .product-details .basic-details .text,
.dark-body .product-details .basic-details .location,
.dark-body .prod-tabs .tabs-content .tab .title,
.dark-body .cart-table tbody .available-info,
.dark-body .cart-table tbody tr td.price,
.dark-body .checkout-page .lower-content .column h2,
.dark-body .cart-section .totals-table .col-title,
.dark-body .checkout-form .checkout-title h2,
.dark-body .checkout-form .form-group .field-label,
.dark-body .checkout-page .lower-content .totals-table li .col.title,
.dark-body .checkout-page .lower-content .totals-table .col,
.dark-body .cart-table tbody tr .prod-column .column-box h4,
.dark-body .cart-table tbody tr .prod-column .column-box h3,
.dark-body .sidebar-page-container .comments-area .comment-info,
.dark-body .register-section .form-column .form-title h2,
.dark-body .news-block-three .inner-box .lower-content .post-meta li a,
.dark-body .news-block-three .inner-box .lower-content h4 a,
.dark-body .news-block-three .inner-box .lower-content .text,
.dark-body .news-detail .inner-box .lower-content .post-meta li,
.dark-body .news-detail .inner-box .lower-content h2,
.dark-body .news-detail .inner-box .lower-content p,
.dark-body .news-detail .inner-box .lower-content blockquote,
.dark-body .news-detail .inner-box .lower-content h3,
.dark-body .news-detail .post-share-options .post-title,
.dark-body .news-detail .post-share-options .tags li a,
.dark-body .sidebar-page-container .group-title h4,
.dark-body .comment-form .rating-box .text,
.dark-body .error-section h2,
.dark-body .error-section .text,
.dark-body .comment-form .form-group label,
.dark-body .contact-info-section .info-box .box-inner,
.dark-body .contact-info-section .social-box span,
.dark-body .contact-info-section .social-box span i,
.dark-body .contact-info-section .info-box .box-inner a,
.dark-body .service-block-two.style-two .inner-box h5 a,
.dark-body .service-block-two.style-two .inner-box .text,
.dark-body .services-section-two.style-two .lower-box .text,
.dark-body .sticky-header .main-menu .navigation>li>a,
.dark-body .team-block-two .inner-box .lower-content h3 a,
.dark-body .banner-section-five .content-column .title,
.dark-body .banner-section-five .content-column h1,
.dark-body .banner-section-five .content-column .text,
.dark-body .sticky-header .outer-box .nav-btn,
.dark-body .step-block .inner-box h6,
.dark-body .service-block-three .inner-box h5 a,
.dark-body .service-block-three .inner-box .text,
.dark-body .banner-section-five .social-box li a,
.dark-body .banner-section-six .content-column .title,
.dark-body .banner-section-six .content-column h1,
.dark-body .banner-section-six .content-column .text,
.dark-body .main-header .sticky-header .outer-box .cart-box,
.dark-body .header-style-two .sticky-header .outer-box .search-box-btn,
.dark-body .header-style-three .sticky-header .outer-box .search-box-btn,
.dark-body .main-header .sticky-header .outer-box .cart-box .cart-box-btn,
.dark-body .team-block-two .inner-box .lower-content .designation,
.dark-body .sidebar-page-container .comments-area .comment-box .text,
.dark-body .shop-single .product-details .prod-tabs .tabs-content .tab .content p,
.dark-body .testimonial-block-three .inner-box .author-image-outer .designation,
.dark-body .testimonial-block-two .inner-box .author-image-outer .designation,
.dark-body .testimonial-block-two .inner-box .author-image-outer h6 {
	color: #ffffff;
}

.dark-body .call-to-action-section-two .pattern-layer-one,
.dark-body .call-to-action-section-two .pattern-layer-two,
.dark-body .seo-section-two .image-layer {
	display: none;
}

.dark-body .blog-cat li a:hover {
	color: #222222;
}

.dark-body .authors-box,
.dark-body .blog-cat,
.dark-body .blog-cat li a,
.dark-body .cart-table tbody tr,
.dark-body .sidebar .popular-tags a,
.dark-body .sidebar .service-list li a,
.dark-body .cart-section .totals-table,
.dark-body .cart-section .totals-table li,
.dark-body .privacy-section .privacy-content,
.dark-body .cart-section .totals-table .col-title,
.dark-body .news-detail .post-share-options,
.dark-body .main-header .sticky-header,
.dark-body .sidebar-page-container .comments-area .comment .comment-inner,
.dark-body .shop-single .product-details .prod-tabs .tab-btns,
.dark-body .shop-single .product-details .prod-tabs .tabs-content,
.dark-body .main-menu .navigation>li>ul>li>ul>li,
.dark-body .main-menu .navigation>li>ul>li {
	border-color: rgba(255, 255, 255, 0.10);
}

.dark-body .main-footer .pattern-layer,
.dark-body .pricing-section .pattern-layer,
.dark-body .news-section-two .pattern-layer,
.dark-body .call-to-action-section-two .pattern-layer-one,
.dark-body .call-to-action-section-two .pattern-layer-two {
	opacity: 0.2;
	filter: grayscale(100%);
}

.dark-body .testimonial-block .inner-box .pattern-layer {
	opacity: 0.5;
	filter: grayscale(100%);
}

.dark-body .main-header .sticky-header {
	background-color: #22253c;
}

.dark-body .steps-section {
	background-image: none !important;
	background-color: #22253c;
}

.dark-body .banner-section-three,
.dark-body .banner-section .slide,
.dark-body .banner-section-four .slide {
	background-image: none !important;
	background-color: #2d3049;
}

.dark-body .team-section-two {
	background-color: #2d3049;
	padding-top: 110px;
}

.dark-body .call-to-action-section-two {
	padding-top: 80px;
	background: #2d3049;
	background: -webkit-linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
	background: -moz-linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
	background: linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
}

.dark-body .seo-form .form-group input {
	background-color: #363a5a;
	color: #ffffff;
}

.dark-body .news-section-two.style-two {
	background-color: #363a5a;
}

.dark-body .main-footer,
.dark-body .business-section,
.dark-body .main-footer.style-two,
.dark-body .main-footer.style-three,
.dark-body .about-section.style-two:before {
	background: #2d3049;
	background: -webkit-linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
	background: -moz-linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
	background: linear-gradient(to bottom, #2d3049 0%, #363a5a 100%);
}

.dark-body .service-block-five .inner-box .side-layer-one,
.dark-body .service-block-five .inner-box .side-layer-two,
.dark-body .footer-style-two .footer-bottom,
.dark-body .banner-section-five .content-column .inner-column:before,
.dark-body .team-block-two .inner-box:before {
	background-color: #363a5a;
}

/*Boxed Layout Style*/

.box-layout {
	position: relative;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.20);
}

.box-layout:before {
	position: fixed;
	content: '';
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: block;
	background: url(../images/background/1.jpg);
}

.box-layout .page-wrapper {
	max-width: 1170px;
	width: 100%;
	margin: 0 auto;
	padding: 15px;
	background-color: #ffffff;
}

.box-layout .header-style-two .main-menu .navigation>li {
	margin-right: 22px;
}

.box-layout .header-style-two .sticky-header .main-menu .navigation>li {
	margin-right: 0px;
}

.box-layout .call-to-action-section.style-two .circle-layer {
	display: none;
}

/*** 

====================================================================
	Banner Section Four
====================================================================

***/

.banner-section-four {
	position: relative;
	overflow: hidden;
}

.banner-section-four .slide {
	position: relative;
	overflow: hidden;
	padding-top: 250px;
	padding-bottom: 240px;
	background-size: cover;
	background-repeat: no-repeat;
	height: 950px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

@media(max-width:575px) {
	.banner-section-four .slide {
		height: 700px;
	}
}

.banner-section-four .slide .hero-inner {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.banner-section-four .slide .hero-inner .auto-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

.banner-section-four .dotted-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-position: center top;
	background-repeat: no-repeat;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.banner-section-four .content-column {
	position: relative;
	z-index: 10;
}

.banner-section-four .content-column .inner-column {
	position: relative;
	padding-top: 100px;
}

.banner-section-four .content-column .title {
	position: relative;
	color: #ffffff;
	font-size: 24px;
	line-height: 1.8em;
	margin-bottom: 16px;
	font-weight: 700;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section-four .slick-slide.slick-active.slick-current .content-column .title {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.banner-section-four .content-column h1 {
	position: relative;
	color: #ffffff;
	font-weight: 600;
	line-height: 1.3em;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section-four .slick-slide.slick-active.slick-current .content-column h1 {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.banner-section-four .content-column .btns-box {
	margin-top: 35px;
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section-four .slick-slide.slick-active.slick-current .content-column .btns-box {
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.banner-section-four .content-column .theme-btn {
	margin-right: 15px;
}

.banner-section-four .image-column {
	position: relative;
	z-index: 10;
	opacity: 0;
	transform: scaleX(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.banner-section-four .slick-slide.slick-active.slick-current .image-column {
	opacity: 1;
	transform: scaleX(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.banner-section-four .image-column .inner-column {
	position: relative;
	padding-top: 0px;
	margin-right: -60px;
}

.banner-section-four .image-column .images-icons {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.banner-section-four .image-column .images-icons .image-1 {
	position: absolute !important;
	left: 0px !important;
	top: auto !important;
	bottom: 0px !important;
	width: 192px;
	height: 314px;
	background-repeat: no-repeat;
}

.banner-section-four .image-column .images-icons .image-2 {
	position: absolute !important;
	left: auto !important;
	top: auto !important;
	bottom: -80px !important;
	right: -140px !important;
	width: 568px;
	height: 641px;
	background-repeat: no-repeat;
}

.banner-section-four .owl-dots {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 40px;
	text-align: center;
	display: none;
}

.banner-section-four .owl-dots .owl-dot {
	position: relative;
	width: 30px;
	height: 6px;
	margin-bottom: 10px;
	border-radius: 0px;
	margin: 0px 5px;
	display: inline-block;
	background-color: #ffffff;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.banner-section-four .owl-dots .owl-dot.active,
.banner-section-four .owl-dots .owl-dot:hover {
	background-color: #141d38;
}

.banner-section-four .owl-nav,
.banner-section-four .owl-nav {
	position: absolute;
	left: 0px;
	top: 50%;
	z-index: 99999;
	width: 100%;
	opacity: 0;
	margin-top: -10px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.banner-section-four:hover .owl-nav {
	opacity: 1;
}

.banner-section-four .slick-prev,
.banner-section-four .slick-next,
.banner-section .slick-prev,
.banner-section .slick-next {
	width: 60px;
	height: 60px;
	background: transparent;
	color: #ffffff;
	line-height: 70px;
	border-radius: 50px;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	border: 2px solid rgba(255, 255, 255, 0.60);
	z-index: 11;
	font-size: 0;
}

.banner-section-four .slick-prev:hover,
.banner-section-four .slick-next:hover,
.banner-section .slick-prev:hover,
.banner-section .slick-next:hover {
	background-color: #ff0300;
}

.banner-section-four .slick-prev,
.banner-section .slick-prev {
	left: 0px;
}

@media (max-width: 767px) {

	.banner-section-four .slick-prev,
	.banner-section .slick-prev {
		display: none !important;
	}
}

.banner-section-four .slick-prev:before,
.banner-section .slick-prev:before {
	font-family: Flaticon;
	content: "\f115";
	opacity: 1;
}

.banner-section-four .slick-next,
.banner-section .slick-next {
	right: 0px;
}

@media (max-width: 767px) {

	.banner-section-four .slick-next,
	.banner-section .slick-next {
		display: none !important;
	}
}

.banner-section-four .slick-next:before,
.banner-section .slick-next:before {
	font-family: Flaticon;
	content: "\f114";
	opacity: 1;
}

.banner-section-four:hover .slick-next,
.banner-section:hover .slick-next {
	right: 20px;
	opacity: 1;
	visibility: visible;
}

.banner-section-four:hover .slick-prev,
.banner-section:hover .slick-prev {
	left: 20px;
	opacity: 1;
	visibility: visible;
}

.banner-section-four .owl-nav .owl-prev {
	position: absolute;
	left: 20px;
	width: 60px;
	height: 60px;
	color: #ffffff;
	line-height: 56px;
	font-size: 24px;
	text-align: center;
	border-radius: 50px;
	border: 2px solid rgba(255, 255, 255, 0.60);
	z-index: 111;
}

.banner-section-four .owl-nav .owl-next {
	position: absolute;
	right: 20px;
	color: #ffffff;
	font-size: 24px;
	width: 60px;
	height: 60px;
	color: #ffffff;
	line-height: 56px;
	border-radius: 50px;
	text-align: center;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	border: 2px solid rgba(255, 255, 255, 0.60);
}

.banner-section-four .owl-nav .owl-prev:hover,
.banner-section-four .owl-nav .owl-next:hover {
	color: #ff0100;
	border-color: #ffffff;
	background-color: #ffffff;
}

/*** 

====================================================================
	Team Section Two
====================================================================

***/

.team-section-two {
	position: relative;
	padding: 0px 0px 80px;
	background-color: #f3f4ff;
}

.team-block-two {
	position: relative;
	margin-bottom: 30px;
}

.team-block-two .inner-box {
	position: relative;
	padding: 0px 0px 28px 70px;
}

.team-block-two .inner-box:before {
	position: absolute;
	content: '';
	right: 40px;
	top: 55px;
	left: 0px;
	bottom: 0px;
	border-radius: 10px;
	background-color: #ffffff;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.team-block-two .inner-box::after {
	position: absolute;
	content: '';
	top: 55px;
	left: 0px;
	bottom: 0px;
	width: 0%;
	border-radius: 10px;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
	background: #ff0300;
	background: -webkit-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: -moz-linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
	background: linear-gradient(to right, #ff0300 0%, #ff8f00 100%);
}

.team-block-two .inner-box:hover::after {
	width: 90%;
}

.team-block-two .inner-box:hover .lower-content h3 a,
.team-block-two .inner-box:hover .lower-content .designation {
	color: #ffffff;
}

.team-block-two .inner-box .image {
	position: relative;
	z-index: 1;
	overflow: hidden;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: inset 0px 0px 35px rgba(0, 0, 0, 0.20);
}

.team-block-two .inner-box .image img {
	position: relative;
	width: 100%;
	display: block;
}

.team-block-two .inner-box .lower-content {
	position: relative;
	text-align: left;
	z-index: 1;
	padding: 30px 15px 0px 20px;
}

.team-block-two .inner-box .lower-content h3 {
	position: relative;
	color: #222222;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3em;
	font-family: 'Poppins', sans-serif;
}

.team-block-two .inner-box .lower-content h3 a {
	position: relative;
	color: #222222;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.team-block-two .inner-box .lower-content .designation {
	position: relative;
	margin-top: 5px;
	color: #777777;
	font-size: 14px;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.team-block-two .inner-box .team-social-box {
	position: absolute;
	left: 18px;
	top: 80px;
	z-index: 10;
}

.team-block-two .inner-box .team-social-box li {
	position: relative;
	left: 0px;
	margin-bottom: 10px;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
}

.team-block-two .inner-box .team-social-box li a {
	position: relative;
	width: 36px;
	height: 36px;
	color: #1cba9f;
	z-index: 1;
	font-size: 15px;
	line-height: 36px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	background-color: #ffffff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.team-block-two .inner-box .team-social-box li .social-name {
	position: relative;
	padding: 0px 10px;
	top: 0px;
	left: 24px;
	opacity: 0;
	width: 80px;
	visibility: hidden;
	font-size: 12px;
	margin-left: -32px;
	text-transform: capitalize;
	display: inline-block;
	border-radius: 0px 50px 50px 0px;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.team-block-two .inner-box .team-social-box li:last-child {
	margin-bottom: 0px;
}

.team-block-two .inner-box .team-block .inner-box:hover .image .overlay-box .team-social-box li {
	left: 0px;
}

.team-block-two .inner-box .team-block .inner-box:hover .image .overlay-box .team-social-box li:nth-child(2) {
	left: 0px;
	transition-delay: 500ms;
}

.team-block-two .inner-box .team-block .inner-box:hover .image .overlay-box .team-social-box li:nth-child(3) {
	left: 0px;
	transition-delay: 750ms;
}

.team-block-two .inner-box .team-block .inner-box:hover .image .overlay-box .team-social-box li:nth-child(4) {
	left: 0px;
	transition-delay: 1000ms;
}

.team-block-two .inner-box .team-social-box li:hover .social-name {
	opacity: 1;
	visibility: visible;
}

.team-block-two .inner-box .team-social-box li a {
	position: relative;
	width: 36px;
	height: 36px;
	color: #1cba9f;
	z-index: 1;
	font-size: 15px;
	line-height: 36px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	background-color: #ffffff;
}

.team-block-two .inner-box .team-social-box li.youtube a {
	color: #ff0000;
}

.team-block-two .inner-box .team-social-box li.youtube .social-name {
	color: #ffffff;
	padding: 4px 14px 4px 15px;
	top: -1px;
	background-color: #ff0000;
}

.team-block-two .inner-box .team-social-box li.linkedin a {
	color: #0077b5;
}

.team-block-two .inner-box .team-social-box li.linkedin .social-name {
	color: #ffffff;
	padding: 4px 14px 4px 15px;
	top: -1px;
	background-color: #0077b5;
}

.team-block-two .inner-box .team-social-box li.facebook a {
	color: #3b5998;
}

.team-block-two .inner-box .team-social-box li.facebook .social-name {
	color: #ffffff;
	padding: 4px 14px 4px 15px;
	top: -1px;
	background-color: #3b5998;
}

.team-block-two .inner-box .team-social-box li.twitter a {
	color: #1da1f2;
}

.team-block-two .inner-box .team-social-box li.twitter .social-name {
	color: #ffffff;
	padding: 4px 14px 4px 15px;
	top: -1px;
	background-color: #1da1f2;
}

/*** 

====================================================================
	Banner Section Five
====================================================================

***/

.banner-section-five {
	position: relative;
}

.banner-section-five .social-box {
	position: absolute;
	left: 2%;
	top: 20%;
	z-index: 10;
	padding: 20px 0px;
	display: inline-block;
	background-color: #f5f5f5;
	border-radius: 50px;
}

.banner-section-five .social-box li {
	position: relative;
	margin-bottom: 0px;
}

.banner-section-five .social-box li a {
	position: relative;
	width: 50px;
	height: 50px;
	color: #222222;
	font-size: 18px;
	line-height: 50px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-five .social-box li a:hover {
	color: #ffffff;
	background-color: #ff2c00;
}

.banner-section-five .slide {
	position: relative;
	overflow: hidden;
}

.banner-section-five .owl-nav {
	display: none;
}


.banner-section-five .slick-dots {
	text-align: center;
}

.banner-section-five .slick-dots li {
	width: unset;
	height: unset;
}

.banner-section-five .slick-dots li button {
	border: none;
	margin: 0 5px;
	width: 20px;
	height: 10px;
	border-radius: 10px;
	background: #cccccc;
	padding: 0;
}

.banner-section-five .slick-dots li button::before {
	display: none;
}

.banner-section-five .slick-dots li.slick-active button {
	background: #0028a2;
	width: 35px;
}


.banner-section-five .owl-dots {
	position: relative;
	text-align: center;
	margin-top: 50px;
}



.banner-section-five .owl-dots .owl-dot {
	position: relative;
	width: 60px;
	height: 10px;
	margin: 0px 5px;
	border-radius: 50px;
	display: inline-block;
	background-color: #e1e1e1;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-five .owl-dots .owl-dot:hover,
.banner-section-five .owl-dots .owl-dot.active {
	background-color: #0028a2;
}

.banner-section-five .image-column {
	position: relative;
}

.banner-section-five .image-column .inner-column {
	position: relative;
}

.banner-section-five .image-column .image {
	position: relative;
	margin-top: -120px;
	margin-left: -490px;
	margin-right: -250px;
}

.banner-section-five .image-column .image img {
	position: relative;
	max-width: none;
	width: 100%;
}

.banner-section-five .content-column {
	position: relative;
	z-index: 1;
}

.banner-section-five .content-column .inner-column {
	position: relative;
	padding-top: 200px;
	padding-bottom: 200px;
}

.banner-section-five .content-column .inner-column:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 200%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 0px 0px 100px 0px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.20);
}

.banner-section-five .content-column .title {
	position: relative;
	color: #555555;
	font-size: 24px;
	font-weight: 600;
}

.banner-section-five .content-column h1 {
	position: relative;
	color: #222222;
	font-weight: 700;
	line-height: 1.2em;
	margin-top: 20px;
}

.banner-section-five .content-column .text {
	position: relative;
	color: #555555;
	font-size: 18px;
	line-height: 1.8em;
	margin-top: 30px;
	margin-bottom: 30px;
}

.banner-section-five .content-column .theme-btn {
	float: left;
}

.banner-section-five .content-column .phone-box {
	position: relative;
	float: left;
	color: #fe4c1c;
	font-size: 24px;
	padding-top: 19px;
	margin-left: 30px;
	padding-left: 85px;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-five .content-column .phone-box .phone-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 60px;
	height: 60px;
	color: #222222;
	font-size: 26px;
	line-height: 58px;
	border-radius: 6px;
	text-align: center;
	display: inline-block;
	border: 1px solid #f64a1b;
	background-color: #ffffff;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-five .content-column .phone-box a {
	position: relative;
	color: #fe4c1c;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-section-five .content-column .phone-box:hover .phone-icon {
	color: #ffffff;
	background-color: #f64a1b;
}

/*** 

====================================================================
	SEO Section Two
====================================================================

***/

.seo-section-two {
	position: relative;
	padding: 40px 0px 60px;
}

.seo-section-two .form-column {
	position: relative;
}

.seo-section-two .form-column .inner-column {
	position: relative;
}

.seo-section-two .image-layer {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 1543px;
	z-index: -1;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.seo-section-two .image-column {
	position: relative;
}

.seo-section-two .image-column .inner-column {
	position: relative;
}

/*** 

====================================================================
	Business Section
====================================================================

***/

.business-section {
	position: relative;
	overflow: hidden;
	padding: 100px 0px 0px;
	background: #151ab2;
	background: -webkit-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: -moz-linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
	background: linear-gradient(to right, #151ab2 0%, #4c68e2 100%);
}

.business-section .pattern-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.business-section .sec-title {
	margin-bottom: 30px;
}

.business-section .inner-container {
	position: relative;
	padding-top: 40px;
}

.business-section .image-column {
	position: relative;
}

.business-section .image-column .inner-column {
	position: relative;
}

.business-section .image-column .image {
	position: relative;
	padding-top: 80px;
	display: inline-block;
}

.business-section .image-column .arrow-image {
	position: absolute;
	right: 0px;
	top: 0px;
}

.business-section .content-column {
	position: relative;
}

.business-section .content-column .inner-column {
	position: relative;
}

.business-block {
	position: relative;
	margin-bottom: 30px;
}

.business-block .inner-box {
	position: relative;
	padding-left: 225px;
}

.business-block .inner-box .box-number {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 90px;
	height: 90px;
	color: #222222;
	font-size: 26px;
	font-weight: 600;
	line-height: 70px;
	text-align: center;
	border-radius: 50px;
	border: 10px solid #162d76;
	background-color: #ffffff;
	font-family: 'Poppins', sans-serif;
}

.business-block:nth-child(2) .inner-box .box-number {
	border-color: #0c8afa;
}

.business-block:nth-child(3) .inner-box .box-number {
	border-color: #0acdcd;
}

.business-block:nth-child(4) .inner-box .box-number {
	border-color: #ff9006;
}

.business-block:nth-child(5) .inner-box .box-number {
	border-color: #f32e2e;
}

.business-block .inner-box .icon-box {
	position: absolute;
	left: 130px;
	top: 15px;
	color: #ffffff;
	font-size: 52px;
	line-height: 1em;
}

.business-block .inner-box .icon-box::before {
	position: absolute;
	content: '';
	left: 10px;
	top: 0px;
	width: 64px;
	height: 61px;
	background: url(../images/icons/business-icon-1.png) no-repeat;
}

.business-block:nth-child(2) .inner-box .icon-box::before {
	width: 59px;
	height: 42px;
	background: url(../images/icons/business-icon-2.png) no-repeat;
}

.business-block:nth-child(3) .inner-box .icon-box::before {
	width: 55px;
	height: 56px;
	background: url(../images/icons/business-icon-3.png) no-repeat;
}

.business-block:nth-child(4) .inner-box .icon-box::before {
	width: 55px;
	height: 49px;
	background: url(../images/icons/business-icon-4.png) no-repeat;
}

.business-block:nth-child(5) .inner-box .icon-box::before {
	width: 49px;
	height: 49px;
	background: url(../images/icons/business-icon-5.png) no-repeat;
}

.business-block .inner-box h4 {
	position: relative;
	color: #ffffff;
	font-size: 22px;
	font-weight: 600;
	line-height: 1.3em;
	text-transform: capitalize;
}

.business-block .inner-box .text {
	position: relative;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.8em;
	margin-top: 10px;
}

/*** 

====================================================================
	Banner Section Six
====================================================================

***/

.banner-section-six {
	position: relative;
	overflow: hidden;
	padding: 280px 0px 280px;
}

.banner-section-six .patern-layer-one {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 1178px;
	height: 861px;
	background-position: right top;
	background-repeat: no-repeat;
}

.banner-section-six .patern-layer-two {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0.2;
	background-repeat: no-repeat;
}

.banner-section-six .content-column {
	position: relative;
}

.banner-section-six .content-column .inner-column {
	position: relative;
}

.banner-section-six .content-column .title {
	position: relative;
	color: #555555;
	font-size: 18px;
	font-weight: 500;
}

.banner-section-six .content-column h1 {
	position: relative;
	color: #222222;
	font-weight: 700;
	line-height: 1.2em;
	margin-top: 22px;
}

.banner-section-six .content-column .text {
	position: relative;
	color: #5a5a5a;
	font-size: 16px;
	line-height: 1.9em;
	margin-top: 22px;
}

.banner-section-six .content-column .btn-box {
	position: relative;
	margin-top: 35px;
}

.banner-section-six .content-column .btn-box .theme-btn {
	margin-right: 18px;
}

.banner-section-six .image-column {
	position: relative;
}

.banner-section-six .image-column .inner-column {
	position: relative;
}

.banner-section-six .image-column .image {
	position: relative;
	margin-left: -100px;
	margin-right: -100px;
}

.errorMessage {
	color: red;
	margin-top: 5px;
}